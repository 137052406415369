import React from 'react'
import './DetailsCard.css'

interface Props {
	children: React.ReactNode
}

const DetailsCard: React.FC<Props> = (props) => {
	const { children } = props

	return <div className='details-card'>{children}</div>
}

export default DetailsCard
