import { CaretDownIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './AccordionItem.css'

interface Props {
	title: string
	children: React.ReactNode
	isActive?: boolean
	isToggleText?: boolean
	onToggle?: () => void
}

const AccordionItem: React.FC<Props> = ({ title, children, isActive, isToggleText, onToggle }) => {
	const { t } = useTranslation()

	return (
		<div className='accordion-item'>
			<button
				className={`accordion-item-button${isActive ? ' active' : emptyString}`}
				onClick={onToggle}>
				{title}
				{isToggleText ? (
					<ButtonComponent variant='only-text' onClick={onToggle ? onToggle : () => null}>
						{isActive ? t('general.close') : t('general.open')}
					</ButtonComponent>
				) : (
					<CaretDownIcon />
				)}
			</button>
			{isActive && <div className='accordion-item-content'>{children}</div>}
		</div>
	)
}

export default AccordionItem
