import { CaretRight, FileCommunicatedIcon, FileConfirmedIcon } from 'assets/svgs'
import TransferInfoStatus from 'components/Transfer/TransferOptions/Fomento/TransferInfoStatus/TransferInfoStatus'
import ListItem from 'components/elements/List/ListItem/ListItem'
import TransferInfoCard from 'components/elements/TransferInfoCard/TransferInfoCard'
import {
	TRANSFER_FOMENTO_COMMUNICATION_PDF_URL,
	TRANSFER_FOMENTO_CONFIRMATION_PDF_URL
} from 'constants/routes'
import { Fomento } from 'models/Fomento'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './TransferInfo.css'

interface Props {
	fomento?: Fomento
}

const TransferInfo: FC<Props> = (props) => {
	const { fomento } = props

	const { t } = useTranslation()

	const navigate = useNavigate()

	const isCommunicatedDocumentVisible =
		fomento?.scheduledAt ?? fomento?.confirmedAt ?? fomento?.cancelledAt

	const isConfirmedDocumentVisible = fomento?.confirmedAt

	const handleComunicateButton = () => {
		if (!fomento) return
		navigate(`${TRANSFER_FOMENTO_COMMUNICATION_PDF_URL}/${fomento.bookingId}`)
	}

	const handleConfirmButton = () => {
		if (!fomento) return
		navigate(`${TRANSFER_FOMENTO_CONFIRMATION_PDF_URL}/${fomento.bookingId}`)
	}

	return (
		<div className='transfer-info'>
			<div className='transfer-info-status-container'>
				<TransferInfoCard fomento={fomento} />
				<TransferInfoStatus fomento={fomento} />
			</div>
			{isCommunicatedDocumentVisible ? (
				<ListItem
					onClick={handleComunicateButton}
					left={<FileCommunicatedIcon />}
					right={<CaretRight />}>
					{t('fomento.communicated_document')}
				</ListItem>
			) : null}
			{isConfirmedDocumentVisible ? (
				<ListItem onClick={handleConfirmButton} left={<FileConfirmedIcon />} right={<CaretRight />}>
					{t('fomento.confirmed_document')}
				</ListItem>
			) : null}
		</div>
	)
}

export default TransferInfo
