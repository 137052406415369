import colors from 'constants/colors'
import { FormikProps } from 'formik'
import { AllStepForms, AllStepFormsWithFomentoForm, StepAddressesForm } from 'models/Transfer'
import { forwardRef, RefObject, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable'
import './Input.css'

interface Option {
	label: string
	value: string
}

type FieldName = keyof StepAddressesForm | keyof AllStepForms | keyof AllStepFormsWithFomentoForm

interface Props {
	options: Option[]
	placeholder: string
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
	fieldName: FieldName
	error: string
	id: string
	label: string
}

const isStepAddressesForm = (
	formik:
		| FormikProps<StepAddressesForm>
		| FormikProps<AllStepForms>
		| FormikProps<AllStepFormsWithFomentoForm>
): formik is FormikProps<StepAddressesForm> => {
	return 'routeTypeId' in formik.values
}

const AddressInput = forwardRef<HTMLElement, Props>((props, ref) => {
	const { options, placeholder, formik, fieldName, error, id, label } = props
	const { t } = useTranslation()
	let value
	if (isStepAddressesForm(formik)) {
		value = formik.values[fieldName as keyof StepAddressesForm]
	} else {
		value = formik.values[fieldName as keyof (AllStepForms | AllStepFormsWithFomentoForm)]
	}

	/* eslint-disable @typescript-eslint/no-explicit-any */
	const selectRef = useRef<any>(null)

	const handleLabelClick = () => {
		if (selectRef.current) {
			selectRef.current.focus()
		}
	}

	return (
		<div className='input-wrapper'>
			<label className='input-label' htmlFor={id} onClick={handleLabelClick}>
				{label}
			</label>
			<CreatableSelect
				id={id}
				options={options}
				placeholder={placeholder}
				ref={selectRef}
				onChange={(selectedOption) => {
					const newValue = selectedOption ? selectedOption.value : value
					formik.setFieldValue(fieldName, newValue)
				}}
				value={value ? { label: value, value } : null}
				className={error ? 'not-selected error' : value ? 'selected' : 'not-selected'}
				components={{ DropdownIndicator: null }}
				styles={{
					control: (base, state) => ({
						...base,
						'width': '100%',
						'padding': '0.45rem 0.2rem',
						'background': state.isFocused ? colors.backgroundInputFocused : colors.backgroundInput,
						'border': error ? `1px solid ${colors.errorDark}` : `1px solid ${colors.borderInput}`,
						'fontSize': '1rem',
						'fontWeight': 500,
						'boxShadow': state.isFocused ? `0 0 0 1px ${colors.primary}` : undefined,
						'&:hover': {
							borderColor: colors.borderInput,
							background: colors.backgroundInputFocused
						},
						'&:focus': {
							borderColor: colors.primary,
							outline: 0
						}
					}),
					placeholder: (base) => ({
						...base,
						opacity: 0.7
					})
				}}
				loadingMessage={() => t('transfer.create_steps.step_addresses.address_input.loading')}
				noOptionsMessage={() => t('transfer.create_steps.step_addresses.address_input.no_options')}
				formatCreateLabel={(inputValue) =>
					`${t('transfer.create_steps.step_addresses.address_input.create_option')} "${inputValue}"`
				}
				aria-label={placeholder}
				aria-labelledby={`${id}-label`}
			/>
			{error && (
				<span className='input-error-message'>
					{error}
					{/* Invisible input used as reference for useFormikErrorFocus since this hook cant be used with the CreatableSelect*/}
					<input
						type='radio'
						ref={ref as RefObject<HTMLInputElement>}
						style={{ opacity: 0, width: 0.01, height: 0.01 }}
					/>
				</span>
			)}
		</div>
	)
})

export default AddressInput
