import { CaretRight } from 'assets/svgs'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import CardComponent from 'components/elements/Card/Card'
import Loading from 'components/elements/Loading/Loading'
import SmallTransferCard from 'components/elements/SmallTransferCard/SmallTransferCard'
import colors from 'constants/colors'
import { DAILY_URL } from 'constants/routes'
import useCharts from 'hooks/useCharts'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { StatsByYearTransfersResponse, StatsPendingTransfersResponse } from 'models/charts'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Calendar from './Calendar'
import './Dashboard.css'
import DoughnutChart from './DoughnutChart'
import LineChart from './LineChart'

const Dashboard = () => {
	const { t } = useTranslation()

	const {
		bigVehicleStats,
		smallVehicleStats,
		providerStats,
		driverStats,
		pendingTransfersStats,
		lastTransferStats,
		byYearTransferStats,
		loadingBigVehicleStats,
		loadingSmallVehicleStats,
		loadingProviderStats,
		loadingDriverStats,
		loadingCalendarStats,
		loadingPendingTransfersStats,
		loadingLastTransferStats,
		loadingByYearTransferStats
	} = useCharts()

	const [activeTag, setActiveTag] = useState<'provider' | 'driver'>('provider')

	if (
		loadingPendingTransfersStats &&
		loadingLastTransferStats &&
		loadingCalendarStats &&
		loadingByYearTransferStats &&
		loadingBigVehicleStats &&
		loadingSmallVehicleStats &&
		loadingProviderStats &&
		loadingDriverStats
	) {
		return <Loading fullscreen />
	}

	return (
		<FormLayout title={t('dashboard.title')} leftButton={<ButtonBack url={DAILY_URL} />}>
			<div className='dashboard-container'>
				<div className='dashboard-section'>
					<h3>{t('dashboard.pending')}</h3>
					<div className='pending-transfers'>
						{Object.entries(pendingTransfersStats as StatsPendingTransfersResponse).map(
							([key, value], index) => (
								<CardComponent key={index} className='white-background'>
									<span>{value}</span>
									<h4>
										{key === ('smallCars' as keyof StatsPendingTransfersResponse)
											? t('dashboard.small_vehicle')
											: t('dashboard.big_vehicle')}
									</h4>
								</CardComponent>
							)
						)}
					</div>
				</div>
				<div className='dashboard-section dashboard-section-last-transfers'>
					<div className='last-transfers-header'>
						<h3>{t('dashboard.last_bookings')}</h3>
						<Link to={DAILY_URL}>
							{t('dashboard.go_to_diary')}
							<CaretRight color={colors.violetMt} />
						</Link>
					</div>
					<div className='last-transfers margin-bottom'>
						{lastTransferStats.map((transfer) => (
							<SmallTransferCard key={transfer.id} transfer={transfer} />
						))}
					</div>
				</div>
				<div className='dashboard-section'>
					<Calendar />
				</div>
				<div className='dashboard-section'>
					<h3>{t('dashboard.transfers_done', { year: new Date().getFullYear() })}</h3>
					<div className='by-year-transfers'>
						{Object.entries(byYearTransferStats as StatsByYearTransfersResponse).map(
							([key, value], index) => (
								<CardComponent key={index} className='white-background'>
									<span>{value}</span>
									<h4>
										{key === ('smallCars' as keyof StatsByYearTransfersResponse)
											? t('dashboard.small_vehicle')
											: key === ('bigCars' as keyof StatsByYearTransfersResponse)
											? t('dashboard.big_vehicle')
											: key === ('total' as keyof StatsByYearTransfersResponse)
											? t('dashboard.total')
											: null}
									</h4>
								</CardComponent>
							)
						)}
					</div>
				</div>
				<div className='dashboard-section dashboard-section-vehicles'>
					<CardComponent className='center-content white-background'>
						<LineChart
							datasetLabel={t('dashboard.booking_number')}
							datasetData={bigVehicleStats.map((stat) => stat.total)}
							labels={bigVehicleStats.map((stat) => stat.name)}
							borderColor={colors.violetMt}
							backgroundColor={colors.primary}
							title={t('dashboard.bookings_big_vehicles', { year: new Date().getFullYear() })}
						/>
					</CardComponent>
					<CardComponent className='center-content white-background'>
						<LineChart
							datasetLabel={t('dashboard.booking_number')}
							datasetData={smallVehicleStats.map((stat) => stat.total)}
							labels={smallVehicleStats.map((stat) => stat.name)}
							borderColor={colors.hasEndBorder}
							backgroundColor={colors.hasEndBackground}
							title={t('dashboard.bookings_small_vehicles', { year: new Date().getFullYear() })}
						/>
					</CardComponent>
				</div>
				<div className='dashboard-section'>
					<h3 className='margin-bottom'>
						{t('dashboard.bookings_done', { year: new Date().getFullYear() })}
					</h3>
					<div className='tag-container'>
						<span
							className={`tag ${activeTag === 'provider' ? 'active' : ''}`}
							onClick={() => setActiveTag('provider')}>
							{t('dashboard.providers')}
						</span>
						<span
							className={`tag ${activeTag === 'driver' ? 'active' : ''}`}
							onClick={() => setActiveTag('driver')}>
							{t('dashboard.drivers')}
						</span>
					</div>
					<CardComponent className='center-content margin-bottom white-background'>
						{activeTag === 'provider' ? (
							<DoughnutChart
								datasetLabel={t('dashboard.booking_number_by_provider')}
								datasetData={providerStats.map((stat) => stat.total)}
								labels={providerStats.map((stat) => stat.name)}
							/>
						) : (
							<DoughnutChart
								datasetLabel={t('dashboard.booking_number_by_driver')}
								datasetData={driverStats.map((stat) => stat.total)}
								labels={driverStats.map((stat) => stat.name)}
							/>
						)}
					</CardComponent>
				</div>
			</div>
		</FormLayout>
	)
}

export default Dashboard
