import NoTransfers from 'components/Daily/NoTransfers'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import Pagination from 'components/elements/Pagination/Pagination'
import SmallTransferCard from 'components/elements/SmallTransferCard/SmallTransferCard'
import { SETTINGS_URL } from 'constants/routes'

import useDeletedTransfers from 'hooks/useDeletedTransfers'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useTranslation } from 'react-i18next'
import './DeletedTransfers.css'

const DeletedTransfers = () => {
	const { t } = useTranslation()
	const { transfers, pager, page, isLoading, setPage } = useDeletedTransfers(1, 10)

	if (isLoading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.deleted_transfers.title')}
			leftButton={<ButtonBack url={SETTINGS_URL} />}>
			{transfers.length > 0 ? (
				<FormSectionLayout title={t('settings.deleted_transfers.last_ten_deleted')}>
					{transfers.map((transfer) => (
						<SmallTransferCard key={transfer.id} transfer={transfer} />
					))}

					<Pagination page={page} totalPages={pager.totalPages} onPageChange={setPage} />
				</FormSectionLayout>
			) : (
				<div className='no-transfers'>
					<NoTransfers messageKey={t('settings.deleted_transfers.no_items')} />
				</div>
			)}
		</FormLayout>
	)
}

export default DeletedTransfers
