import Chart from 'chart.js/auto'
import colors from 'constants/colors'
import { StatsResponse } from 'models/charts'
import { useEffect, useRef } from 'react'
import './DoughnutChart.css'

interface Props {
	datasetLabel: string
	datasetData: StatsResponse['total'][]
	labels: StatsResponse['name'][]
	borderColor?: string
	backgroundColor?: string[]
	hoverBackgroundColor?: string
	hoverBorderColor?: string
	title?: string
}

const generateRandomColor = (): string => {
	const r = Math.floor(Math.random() * 256)
	const g = Math.floor(Math.random() * 256)
	const b = Math.floor(Math.random() * 256)
	const a = 0.5
	return `rgba(${r}, ${g}, ${b}, ${a})`
}

const DoughnutChart = ({
	datasetLabel,
	datasetData,
	labels,
	borderColor = colors.neutral00,
	backgroundColor = datasetData.map(() => generateRandomColor()),
	hoverBackgroundColor = colors.primary,
	hoverBorderColor = colors.primary,
	title
}: Props) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null)

	const chartData = {
		labels: [], // remove ":[]" to show labels inside the chart
		datasets: [
			{
				label: datasetLabel,
				data: datasetData,
				borderColor,
				backgroundColor,
				hoverBackgroundColor,
				borderWidth: 0,
				hoverBorderColor
			}
		]
	}

	const chartOptions = {
		responsive: true,
		plugins: {
			title: {
				display: !!title,
				text: title,
				font: {
					size: 18
				},
				padding: {
					top: 10,
					bottom: 20
				},
				align: 'center' as const
			},
			datalabels: {
				display: true,
				color: colors.neutral00,
				font: {
					weight: 'bold',
					size: 14
				},
				formatter: (value: number) => value
			} as const
		},
		cutout: '0%',
		animation: {
			animateScale: true,
			animateRotate: true
		}
	}

	useEffect(() => {
		if (chartRef.current) {
			const canvas = chartRef.current
			const chartInstance = new Chart(chartRef.current, {
				type: 'doughnut',
				data: chartData,
				options: chartOptions
			})
			const handleResize = () => {
				if (chartInstance && canvas) {
					canvas.width = canvas.parentElement?.offsetWidth || window.innerWidth
					chartInstance.resize()
				}
			}
			window.addEventListener('resize', handleResize)
			return () => {
				window.removeEventListener('resize', handleResize)
				chartInstance.destroy()
			}
		}
	}, [
		datasetLabel,
		datasetData,
		borderColor,
		backgroundColor,
		hoverBackgroundColor,
		hoverBorderColor
	])

	return (
		<>
			<canvas ref={chartRef} />
			<div className='label-list'>
				{labels.map((label, index) => (
					<div key={index} className='label-item'>
						<span
							className='label-color'
							style={{
								backgroundColor: backgroundColor[index]
							}}></span>
						<div className='label-info'>
							<span>{label}</span>
							<span>{datasetData[index]}</span>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default DoughnutChart
