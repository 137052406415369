import { CaretRight, CarIcon, NotePencilIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import CardComponent from 'components/elements/Card/Card'
import colors from 'constants/colors'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { previousRoute } from 'utils/common'
import './CreateBilling.css'
import ManualInvoiceModal from './ManualInvoiceModal/ManualInvoiceModal'
import WithReservationsInvoiceModal from './WithReservationsInvoiceModal/WithReservationsInvoiceModal'

const CreateInvoice = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const [isOpenManualInvoiceModal, setIsOpenManualInvoiceModal] = useState(false)
	const [isOpenNewInvoiceModal, setIsOpenNewInvoiceModal] = useState(false)

	const handleWithReservationsInvoiceModal = () => {
		setIsOpenNewInvoiceModal(!isOpenNewInvoiceModal)
	}

	const handleManualInvoiceModal = () => {
		setIsOpenManualInvoiceModal(!isOpenManualInvoiceModal)
	}

	return (
		<FormLayout
			title={t('settings.billing.create_invoice')}
			leftButton={
				<ButtonComponent onClick={() => navigate(previousRoute)} variant='only-text'>
					<strong>{t('general.return')}</strong>
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('settings.billing.create_invoice_question')}>
				<p className='description'>{t('settings.billing.create_invoice_description')}</p>
				<CardComponent
					role='article'
					className='white-background card-invoice'
					onClick={handleWithReservationsInvoiceModal}>
					<div className='card-row'>
						<span className='icon-container'>{<CarIcon color={colors.blueMt} />}</span>
						<p className='card-title'>{t('settings.billing.create_with_reservations')}</p>
						<div className='card-caret'>
							<CaretRight color={colors.blueMt} />
						</div>
					</div>
					<p>{t('settings.billing.create_with_reservations_description')}</p>
				</CardComponent>
				<CardComponent
					role='article'
					className='white-background card-invoice'
					onClick={handleManualInvoiceModal}>
					<div className='card-row'>
						<span className='icon-container'>{<NotePencilIcon color={colors.blueMt} />}</span>
						<p className='card-title'>{t('settings.billing.create_manually')}</p>
						<div className='card-caret'>
							<CaretRight color={colors.blueMt} />
						</div>
					</div>
					<p>{t('settings.billing.create_manually_description')}</p>
				</CardComponent>
			</FormSectionLayout>
			<WithReservationsInvoiceModal
				isVisible={isOpenNewInvoiceModal}
				toggleVisibility={handleWithReservationsInvoiceModal}
			/>
			<ManualInvoiceModal
				isVisible={isOpenManualInvoiceModal}
				toggleVisibility={handleManualInvoiceModal}
			/>
		</FormLayout>
	)
}

export default CreateInvoice
