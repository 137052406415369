import FileSearch from 'assets/svgs/FileSearch'
import NotePencilIcon from 'assets/svgs/NotePencilIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { BILLING_PDF_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import './ThreeDotsBillingModal.css'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	invoiceId: string
}

const ThreeDotsBillingModal: FC<Props> = (props) => {
	const { isOpen, setIsOpen, invoiceId } = props
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const { setPreviousRoute } = usePreviousRouteStore()

	const menuItems = useMemo(
		() => [
			{
				label: t('settings.billing.see_pdf'),
				href: '#',
				icon: <FileSearch />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(BILLING_PDF_URL + `/${invoiceId}`)
				}
			},
			{
				label: t('settings.billing.edit_invoice'),
				href: '#',
				icon: <NotePencilIcon />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
				}
			},
			{
				label: t('settings.billing.delete_invoice'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal
			}
		],
		[invoiceId]
	)

	const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			e.stopPropagation()
			setIsOpen(false)
		}
	}

	return (
		<>
			<div
				className={`three-dots-modal-container ${isOpen ? 'show' : ''}`}
				onClick={handleOutsideClick}>
				<div
					className={`three-dots-modal-content ${isOpen ? 'open' : ''} billing-card-modal-content`}
					onClick={(e) => e.stopPropagation()}>
					{menuItems.map((item, index) => (
						<Link to={item.href} className='menu-item' key={index} onClick={item?.onClick}>
							{item?.icon} {item.label}
						</Link>
					))}
				</div>
			</div>
			<ModalActions
				type='error'
				title={t('settings.billing.delete_invoice_modal_title')}
				description={t('settings.billing.delete_invoice_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={(e) => {
					e!.stopPropagation()
					toggleDeleteModal()
				}}
				primaryButton={{
					text: t('general.remove'),
					onClick: (e) => {
						e!.stopPropagation()
					}
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: (e) => {
						e!.stopPropagation()
						toggleDeleteModal()
					}
				}}
			/>
		</>
	)
}

export default ThreeDotsBillingModal
