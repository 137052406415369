import StepDetailsInputs from 'components/Transfer/StepInputs/StepDetailsInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useConfigurations from 'hooks/useConfigurations'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { TransferPost, type StepDetailsForm } from 'models/Transfer'
import { UserRoles } from 'models/User'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import { useUserStore } from 'stores/useUserStore'
import * as Yup from 'yup'

const StepDetails = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { updateActualTransferCreation, actualTransferCreation } = useTransfersStore()
	const { currentUser } = useUserStore()
	const { configurationTransfer } = useConfigurations()

	const formik = useFormik<StepDetailsForm>({
		initialValues: {
			externalId: actualTransferCreation.externalId ?? null,
			driverId: actualTransferCreation.driverId ?? null,
			providerId: actualTransferCreation.providerId ?? null,
			vehicleId: actualTransferCreation.vehicleId ?? null,
			passengers: actualTransferCreation.passengers ?? 1
		},
		validationSchema: Yup.object().shape({
			externalId: Yup.string().nullable(),
			driverId: Yup.number().required(t('errors.required_m', { field: t('transfer.driver') })),
			providerId: Yup.number().required(t('errors.required_m', { field: t('transfer.provider') })),
			vehicleId: Yup.number().nullable(),
			passengers: Yup.number().required(t('errors.required_m', { field: t('transfer.passengers') }))
		}),
		onSubmit: (values) => {
			if (!values.driverId || !values.providerId) return
			const transferPost: Partial<TransferPost> = {
				externalId: values.externalId,
				driverId: values.driverId,
				providerId: values.providerId,
				vehicleId: values.vehicleId ?? undefined,
				passengers: values.passengers
			}
			updateActualTransferCreation(transferPost)
			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.ADDRESSES.toLocaleLowerCase()}`)
		}
	})

	useEffect(() => {
		const config = configurationTransfer && configurationTransfer![actualTransferCreation.route!]
		formik.setValues({
			externalId: actualTransferCreation.externalId ?? formik.values.externalId,
			driverId:
				actualTransferCreation.driverId ??
				(currentUser.userTypeId === UserRoles.driver
					? null
					: config?.driverId
					? +config.driverId
					: formik.values.driverId),
			providerId:
				actualTransferCreation.providerId ??
				(config?.providerId ? +config.providerId : formik.values.providerId),
			vehicleId:
				actualTransferCreation.vehicleId ??
				(config?.vehicleId ? +config.vehicleId : formik.values.vehicleId),
			passengers:
				actualTransferCreation.passengers ?? config?.passengers ?? formik.values.passengers
		})
	}, [configurationTransfer, actualTransferCreation.route, actualTransferCreation])

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={2}>
			<FormSectionLayout title={t('transfer.create_steps.step_details.h1')} noDivider>
				<StepDetailsInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepDetails
