import { SearchTags } from 'models/Search'
import { MouseEventHandler } from 'react'
import './SearchFilterTag.css'

interface Props {
	tag: SearchTags
	label: string
	activeTag: SearchTags | undefined
	onClick: MouseEventHandler<HTMLSpanElement>
}

const FilterTag = ({ tag, label, activeTag, onClick }: Props) => {
	return (
		<span className={`filter-tag ${activeTag === tag ? 'active' : ''}`} onClick={onClick}>
			{label}
		</span>
	)
}

export default FilterTag
