import { useMutation } from '@tanstack/react-query'
import {
	BillingFilters,
	BillingResponse,
	CreateInvoiceLinePayload,
	CreateManualInvoicePayload,
	CreateWithReservationInvoicePayload
} from 'models/Billing'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createInvoice, createInvoiceLine, getBilling } from 'services/billing'
import { getDateRange } from 'utils/dateUtils'
import useToast from './useToast'

const useBilling = (initialPage = 1, perPage = 10) => {
	const [billing, setBilling] = useState<BillingResponse[]>([])
	const [pager, setPager] = useState({ totalPages: 0 })
	const [page, setPage] = useState(initialPage)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Nullable<string>>(null)
	const [isFiltering, setIsFiltering] = useState(false)
	const [filters, setFilters] = useState<Partial<BillingFilters>>(() => ({
		...getDateRange(0),
		providerId: []
	}))
	const { showSuccessToast } = useToast()
	const { t } = useTranslation()

	// GET ALL BILLING
	const fetchBilling = async (currentPage: number) => {
		setIsLoading(true)
		setError(null)
		try {
			const response = await getBilling(currentPage, perPage, filters)
			setBilling(response.data)
			setPager(response.pager)
		} catch (error) {
			setError('Error fetching billing data')
		} finally {
			setIsLoading(false)
		}
	}

	const updateFilters = (newFilters: Partial<BillingFilters>) => {
		setIsFiltering(true)
		setFilters((prev) => ({ ...prev, ...newFilters }))
	}

	const resetFilters = () => {
		setIsFiltering(false)
		setFilters({
			...getDateRange(0),
			providerId: []
		})
	}

	useEffect(() => {
		fetchBilling(page)
	}, [page, perPage, filters])

	// CREATE INVOICE
	const { mutateAsync: createNewInvoice } = useMutation({
		mutationFn: (
			createInvoicePayload: CreateManualInvoicePayload | CreateWithReservationInvoicePayload
		) => createInvoice(createInvoicePayload),
		onSuccess: () => {
			showSuccessToast({
				description: t('settings.billing.success_create_invoice'),
				duration: 2000
			})
		}
	})

	// CREATE INVOICE LINE
	const { mutateAsync: creatNewInvoiceLine } = useMutation({
		mutationFn: (createInvoiceLinePayload: CreateInvoiceLinePayload) =>
			createInvoiceLine(createInvoiceLinePayload)
	})

	return {
		billing,
		pager,
		page,
		isLoading,
		error,
		filters,
		isFiltering,
		setPage,
		updateFilters,
		resetFilters,
		createNewInvoice,
		creatNewInvoiceLine
	}
}

export default useBilling
