import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import { CURRENCY_SYMBOL } from 'constants/common'
import { RECEIPT_PDF_URL, SUBSCRIPTION_URL } from 'constants/routes'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useInvoicesStore } from 'stores/useInvoicesStore'
import { firstElement } from 'utils/common'
import { dateToSpanish } from 'utils/dateUtils'
import './MyReceipts.css'
import ReceiptCard from './ReceiptCard'

const MyReceipts: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const invoicesState = useInvoicesStore()
	const [loading, setLoading] = useState(false)
	const { showBackendErrorToast } = useToast()

	const getMyInvoices = async () => {
		setLoading(true)
		try {
			await invoicesState.fetchMyInvoices()
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		getMyInvoices()
	}, [])

	if (loading) return <Loading fullscreen />

	return (
		<FormLayout
			title={t('settings.my_account.subscription.my_receipts')}
			leftButton={<ButtonBack url={SUBSCRIPTION_URL} />}>
			<p className='my-receipts-description body2'>
				{t('settings.my_account.subscription.receipts_description')}
			</p>
			<FormSectionLayout title={t('settings.my_account.subscription.receipts')}>
				{invoicesState.invoices.allIds.map((id) => {
					const invoice = invoicesState.invoices.byId[id]
					const concept = invoice.lines.length ? invoice.lines[firstElement].concept : ''

					return (
						<ReceiptCard
							key={id}
							onClick={() => navigate(`${RECEIPT_PDF_URL}/${id}`)}
							concept={concept}
							reference={invoice.reference}
							total={`${invoice.total}${CURRENCY_SYMBOL}`}
							status={invoice.isPaid ? t('general.paid') : t('general.not_paid')}
							date={dateToSpanish(new Date(invoice.date))}
						/>
					)
				})}
				{/*TODO: Hide see more button until pagination is done in the back
				 <ButtonComponent
					className='my-receipt-see-more'
					variant='secondary'
					rightIcon={<CaretDoubleDownIcon />}
					onClick={() => null}>
					{t('general.see_more')}
				</ButtonComponent> */}
			</FormSectionLayout>
		</FormLayout>
	)
}

export default MyReceipts
