import ProgressBar from 'components/elements/ProgressBar/ProgressBar'
import { FC } from 'react'
import './Header.css'

export interface HeaderProps {
	leftButton?: React.ReactNode
	rightButton?: React.ReactNode
	title?: string
	percentile?: number
	isSticky?: boolean
}

const Header: FC<HeaderProps> = (props) => {
	const { leftButton, rightButton, title, percentile, isSticky } = props

	const classNames = () => {
		let classes = 'header'
		if (isSticky) classes += ' sticky'
		if (percentile) classes += ' has-progress-bar'

		return classes
	}

	return (
		<header className={classNames()}>
			<div className='header-main-content'>
				<div>{leftButton && leftButton}</div>
				{title && <h2 className='header-title'>{title}</h2>}
				<div className='header-main-content-right'>{rightButton && rightButton}</div>
			</div>
			{percentile && percentile >= 0 && <ProgressBar percentile={percentile} />}
		</header>
	)
}

export default Header
