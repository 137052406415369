import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import {
	checkIfDateIsOneMonthLaterFromToday,
	checkIfDateIsTomorrow,
	dateIsToday,
	dateToSpanish
} from 'utils/dateUtils'
import './DetailDate.css'

interface Props {
	date: Date
}

const DetailDate: React.FC<Props> = (props) => {
	const { date } = props
	const [message, setMessage] = useState<string>(emptyString)
	const { t } = useTranslation()

	const getMessageInfo = (date: Date) => {
		const today = new Date()
		const dateToCompare = new Date(date)
		if (dateIsToday(date)) {
			return t('general.today')
		}
		if (checkIfDateIsTomorrow(date)) {
			return t('general.tomorrow')
		}
		if (dateToCompare < today) {
			return t('general.date_expired')
		}
		if (checkIfDateIsOneMonthLaterFromToday(date)) {
			return t('general.one_month_left')
		}
		return emptyString
	}

	useEffect(() => {
		const message = getMessageInfo(date)
		setMessage(message)
	}, [date])

	if (!date) return null

	if (!message) return dateToSpanish(date)

	return (
		<div className='details-card-value-warning'>
			{dateToSpanish(date)}
			<div className='details-card-warning'>{message}</div>
		</div>
	)
}

export default DetailDate
