import BellSimpleIcon from 'assets/svgs/BellSimpleIcon'
import CopyIcon from 'assets/svgs/CopyIcon'
import CreateTransfer from 'assets/svgs/CreateTransfer'
import FileSearch from 'assets/svgs/FileSearch'
import FrameCornersIcon from 'assets/svgs/FrameCornersIcon'
import NotePencilIcon from 'assets/svgs/NotePencilIcon'
import ShareFatIcon from 'assets/svgs/ShareFatIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import TransferPoster from 'components/Transfer/TransferPoster'
import {
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_SHARE_URL,
	TRANSFER_URL
} from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useDrawerMenu from 'hooks/useDrawerMenu'
import { useModal } from 'hooks/useModal'
import { Permissions } from 'models/Configurations'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { currentUserIsAdmin } from 'utils/currentUser'
import Loading from '../Loading/Loading'
import ModalActions from '../Modal/ModalActions/ModalActions'
import './ThreeDotsMenuModal.css'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	transferId: number
}

const ThreeDotsMenuModal: FC<Props> = (props) => {
	const { isOpen, setIsOpen, transferId } = props

	const { t } = useTranslation()
	const navigate = useNavigate()
	const { isConfigurationActive } = useConfigurations()
	const [isPosterVisible, togglePosterModal] = useModal()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const isAdmin = currentUserIsAdmin()
	const { setPreviousRoute } = usePreviousRouteStore()
	const {
		copyToClipboard,
		navigateToDuplicateTransfer,
		sendToDriver,
		deleteTransferById,
		loading
	} = useDrawerMenu({
		transferId,
		onClose: () => setIsOpen(false)
	})

	const menuItems = useMemo(
		() => [
			{
				label: t('transfer.details.drawer_menu.see_document'),
				href: '#',
				icon: <FileSearch />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_PDF_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.fomento'),
				href: '#',
				icon: <BellSimpleIcon />,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_FOMENTO_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.show_poster'),
				href: '#',
				icon: <FrameCornersIcon />,
				onClick: togglePosterModal
			},
			{
				label: t('transfer.details.drawer_menu.edit_transfer'),
				href: '#',
				icon: <NotePencilIcon />,
				skip: !isConfigurationActive(Permissions.CREATE_TRANSFER),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_URL}/${transferId}/edit`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.copy_to_clipboard'),
				href: '#',
				icon: <CopyIcon />,
				onClick: copyToClipboard
			},
			{
				label: t('transfer.details.drawer_menu.send_to_friend'),
				href: `${TRANSFER_SHARE_URL}/${transferId}`,
				icon: <ShareFatIcon />,
				skip: !isAdmin,
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigate(`${TRANSFER_SHARE_URL}/${transferId}`)
				}
			},
			{
				label: t('transfer.details.drawer_menu.send_to_driver'),
				href: '#',
				icon: <ShareFatIcon />,
				onClick: sendToDriver,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.duplicate_transfer'),
				href: '#',
				icon: <CreateTransfer />,
				skip: !isConfigurationActive(Permissions.DUPLICATE_TRANSFER),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					setPreviousRoute(window.location.pathname)
					navigateToDuplicateTransfer()
				}
			},
			{
				label: t('transfer.details.drawer_menu.delete_transfer'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal,
				skip: !isAdmin
			}
		],
		[transferId]
	)

	const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			e.stopPropagation()
			setIsOpen(false)
		}
	}

	return (
		<>
			<div
				className={`three-dots-modal-container ${isOpen ? 'show' : ''}`}
				onClick={handleOutsideClick}>
				<div
					className={`three-dots-modal-content ${isOpen ? 'open' : ''}`}
					onClick={(e) => e.stopPropagation()}>
					{loading ? (
						<Loading noBorder hasText={false} />
					) : (
						menuItems
							.filter((item) => !item.skip)
							.map((item, index) => (
								<Link to={item.href} className='menu-item' key={index} onClick={item?.onClick}>
									{item?.icon} {item.label}
								</Link>
							))
					)}
				</div>
			</div>
			<TransferPoster
				isVisible={isPosterVisible}
				toggleVisibility={(e?: React.MouseEvent) => {
					e?.stopPropagation()
					togglePosterModal()
				}}
				transferId={transferId}
			/>
			<ModalActions
				type='error'
				title={t('transfer.details.drawer_menu.delete_transfer_modal_title')}
				description={t('transfer.details.drawer_menu.delete_transfer_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={(e?: React.MouseEvent) => {
					e?.stopPropagation()
					toggleDeleteModal()
				}}
				primaryButton={{
					text: t('general.remove'),
					onClick: (e?: React.MouseEvent) => {
						e?.stopPropagation()
						deleteTransferById()
					}
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: (e?: React.MouseEvent) => {
						e?.stopPropagation()
						toggleDeleteModal()
					}
				}}
			/>
		</>
	)
}

export default ThreeDotsMenuModal
