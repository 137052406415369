import {
	IdentificationBadgeIcon,
	IdentificationCardIcon,
	StoreFrontIcon,
	WhatsappIcon
} from 'assets/svgs'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import colors from 'constants/colors'
import {
	LOGOUT_URL,
	MY_COMPANY_URL,
	MY_PROFILE_URL,
	SETTINGS_URL,
	SUBSCRIPTION_URL
} from 'constants/routes'
import useSupport from 'hooks/useSupport'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import './MyAccount.css'

const actionIconBgColor = colors.lightBlueMt
const actionIconWhatsappBgColor = '#9AE5A0'

const MyAccount: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const handleSupport = useSupport()

	return (
		<FormLayout title={t('settings.account')} leftButton={<ButtonBack url={SETTINGS_URL} />}>
			<div className='my-account-options'>
				<ActionButton
					icon={<IdentificationBadgeIcon />}
					backGroundIcon={actionIconBgColor}
					onClick={() => navigate(MY_COMPANY_URL)}>
					{t('settings.my_account.my_company_profile')}
				</ActionButton>

				<ActionButton
					icon={<StoreFrontIcon />}
					backGroundIcon={actionIconBgColor}
					onClick={() => navigate(SUBSCRIPTION_URL)}>
					{t('settings.my_account.my_subscription')}
				</ActionButton>

				<ActionButton
					icon={<IdentificationCardIcon />}
					backGroundIcon={actionIconBgColor}
					onClick={() => navigate(MY_PROFILE_URL)}>
					{t('settings.my_account.my_profile')}
				</ActionButton>

				<ActionButton
					icon={<WhatsappIcon />}
					backGroundIcon={actionIconWhatsappBgColor}
					noRightArrow
					onClick={handleSupport}>
					{t('settings.my_account.support')}
				</ActionButton>
			</div>
			<div className='button-container'>
				<ButtonComponent className='logout' onClick={() => navigate(LOGOUT_URL)}>
					{t('iam.logout')}
				</ButtonComponent>
			</div>
		</FormLayout>
	)
}

export default MyAccount
