import AuthBg1 from 'assets/images/auth-bg.png'
import Logo from 'assets/images/logo-white.png'
import { ArrowLeft } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { FORGOT_PASSWORD_URL, LOGIN_URL, REGISTER_URL } from 'constants/routes'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import './Auth.css'

const Auth: React.FC = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className='auth'>
			<img src={AuthBg1} className='background-image' alt='auth background' />
			<div className='auth-content'>
				<header className='auth-header'>
					<img className='app-logo' src={Logo} alt='app-logo' />
					<h1>{t('iam.auth.title')}</h1>
					<p>{t('iam.auth.description')}</p>
				</header>
				<div className='auth-buttons'>
					<ButtonComponent
						className='auth-primary-button'
						onClick={() => navigate(REGISTER_URL)}
						fullWidth>
						{t('iam.register')}
						<ArrowLeft />
					</ButtonComponent>
					<ButtonComponent
						className='auth-secondary-button'
						variant='secondary'
						onClick={() => navigate(LOGIN_URL)}
						fullWidth>
						{t('iam.login')}
					</ButtonComponent>
					<div className='auth-forgot-password'>
						<span>{t('iam.auth.forgot_password')}</span>
						<Link to={FORGOT_PASSWORD_URL}>{t('iam.auth.forgot_password_link')}</Link>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Auth
