import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	color?: string
}

const DotsThreeVertical: FC<Props> = (props) => {
	const { width = 12, height = 27, color = colors.blueMt } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 12 27'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M5.73438 15.9375C6.69224 15.9375 7.46875 15.161 7.46875 14.2031C7.46875 13.2453 6.69224 12.4688 5.73438 12.4688C4.77651 12.4688 4 13.2453 4 14.2031C4 15.161 4.77651 15.9375 5.73438 15.9375Z'
				fill={color}
			/>
			<path
				d='M5.73438 8.46875C6.69224 8.46875 7.46875 7.69224 7.46875 6.73438C7.46875 5.77651 6.69224 5 5.73438 5C4.77651 5 4 5.77651 4 6.73438C4 7.69224 4.77651 8.46875 5.73438 8.46875Z'
				fill={color}
			/>
			<path
				d='M5.73438 23.4062C6.69224 23.4062 7.46875 22.6297 7.46875 21.6719C7.46875 20.714 6.69224 19.9375 5.73438 19.9375C4.77651 19.9375 4 20.714 4 21.6719C4 22.6297 4.77651 23.4062 5.73438 23.4062Z'
				fill={color}
			/>
		</svg>
	)
}

export default DotsThreeVertical
