import { FormikErrors } from 'formik'
import { useCallback, useRef } from 'react'

const useFormikErrorFocus = <T,>(errors: FormikErrors<T>) => {
	const fieldRefs = useRef<Record<string, HTMLElement | null>>({})

	const setFieldRef = useCallback(
		(name: string) => (element: HTMLElement | null) => {
			fieldRefs.current[name] = element
		},
		[]
	)

	const focusFirstError = useCallback(() => {
		const errorKeys = Object.keys(errors)
		if (errorKeys.length > 0) {
			const firstErrorKey = errorKeys[0]
			const errorElement = fieldRefs.current[firstErrorKey]
			if (errorElement) {
				errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
				window.scrollBy(0, -10)
				if (typeof errorElement.focus === 'function') {
					errorElement.focus()
				}
			}
		}
	}, [errors])

	return { setFieldRef, focusFirstError }
}

export default useFormikErrorFocus
