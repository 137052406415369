import { CheckSuccessIcon } from 'assets/svgs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import './SuccessMenu.css'

interface Props {
	title: string
	subtitle?: string
	children: React.ReactNode
}

const SuccessMenu: React.FC<Props> = (props) => {
	const { title, subtitle, children } = props

	const { t } = useTranslation()

	return (
		<div className='success-menu'>
			<div className='success-menu-header'>
				<CheckSuccessIcon />
				<h1 className='success-menu-header-title'>{title}</h1>
			</div>
			<div className='success-menu-body'>
				<h2 className='success-menu-body-title'>{subtitle ?? t('general.success_menu_heading')}</h2>
				<div className='success-menu-body-children'>{children}</div>
			</div>
		</div>
	)
}

export default SuccessMenu
