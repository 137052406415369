import { BabySeat, Booster, Maxicosi } from 'assets/svgs'
import { NO_VALUE } from 'constants/common'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { zeroElements } from 'utils/common'
import './CategoryTag.css'

interface Props {
	transferId: number
}

const ExtraSeatCategoryTag: React.FC<Props> = (props) => {
	const { transferId } = props
	const { transfer } = useTransferDetails(transferId)

	if (!transfer) {
		return null
	}

	const noExtras =
		transfer.babySeats <= zeroElements &&
		transfer.boosterSeats <= zeroElements &&
		transfer.maxicosiSeats <= zeroElements

	return (
		<div className='extra-seat-category-tags'>
			{transfer.babySeats > zeroElements && (
				<div className='extra-seat-category-tag' data-testid='baby-seat'>
					<BabySeat /> {transfer.babySeats}
				</div>
			)}

			{transfer.boosterSeats > zeroElements && (
				<div className='extra-seat-category-tag' data-testid='booster-seat'>
					<Booster /> {transfer.boosterSeats}
				</div>
			)}

			{transfer.maxicosiSeats > zeroElements && (
				<div className='extra-seat-category-tag' data-testid='maxicosi-seat'>
					<Maxicosi /> {transfer.maxicosiSeats}
				</div>
			)}

			{noExtras && (
				<div className='extra-seat-category-tag' data-testid='no-extras'>
					<span>{NO_VALUE}</span>
				</div>
			)}
		</div>
	)
}

export default ExtraSeatCategoryTag
