import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import './PdfViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

interface Props {
	pdf: string
}

const INITIAL_PAGE_NUMBER = 1
const INITIAL_NUM_PAGES = 0
const INITIAL_PAGE_WIDTH = 0

const PdfViewer: FC<Props> = ({ pdf }) => {
	const { t } = useTranslation()
	const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER)
	const [numPages, setNumPages] = useState(INITIAL_NUM_PAGES)
	const [pageWidth, setPageWidth] = useState(INITIAL_PAGE_WIDTH)

	const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
		setNumPages(numPages)
		setPageNumber(INITIAL_PAGE_NUMBER)
		updatePageWidth()
	}

	const updatePageWidth = () => {
		const container = document.querySelector('.pdf-container')
		if (container) {
			const scaleFactor = 1
			setPageWidth(container.clientWidth * scaleFactor)
		}
	}

	useEffect(() => {
		window.addEventListener('resize', updatePageWidth)
		return () => {
			window.removeEventListener('resize', updatePageWidth)
		}
	}, [])

	return (
		<>
			<div className='pdf-container'>
				<Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
					<Page pageNumber={pageNumber} width={pageWidth} />
				</Document>
			</div>
			<div className='pagination'>
				<span>
					{t('pdf.page')} {pageNumber} {t('pdf.of')} {numPages}
				</span>
			</div>
		</>
	)
}

export default PdfViewer
