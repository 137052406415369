import StepExtrasInputs from 'components/Transfer/StepInputs/StepExtrasInputs'
import { CREATE_URL, TRANSFER_STEPS, TRANSFER_URL } from 'constants/routes'
import { useFormik } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import FormTransferLayout from 'layouts/FormLayout/FormTransferLayout'
import { StepExtrasForm, TransferPost } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useTransfersStore } from 'stores/useTransfersStore'
import './StepExtras.css'

const StepExtras = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { updateActualTransferCreation, actualTransferCreation } = useTransfersStore()
	const formik = useFormik<StepExtrasForm>({
		initialValues: {
			babySeats: actualTransferCreation.babySeats ?? 0,
			boosterSeats: actualTransferCreation.boosterSeats ?? 0,
			maxicosiSeats: actualTransferCreation.maxicosiSeats ?? 0
		},
		onSubmit: (values) => {
			const transferPost: Partial<TransferPost> = {
				babySeats: values.babySeats,
				boosterSeats: values.boosterSeats,
				maxicosiSeats: values.maxicosiSeats
			}
			updateActualTransferCreation(transferPost)

			navigate(`${TRANSFER_URL}${CREATE_URL}/${TRANSFER_STEPS.OBSERVATIONS.toLocaleLowerCase()}`)
		}
	})

	return (
		<FormTransferLayout onSubmit={() => formik.handleSubmit()} step={7}>
			<FormSectionLayout
				title={`${t('transfer.create_steps.step_extras.h1')} `}
				helper={`(${t('general.optional')})`}>
				<StepExtrasInputs formik={formik} />
			</FormSectionLayout>
		</FormTransferLayout>
	)
}

export default StepExtras
