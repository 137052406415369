import { CalendarBlank, CaretDownIcon, ClockIcon } from 'assets/svgs'
import { ReactNode, RefObject, forwardRef, useEffect, useState } from 'react'
import { emptyString } from 'utils/common'
import Loading from '../Loading/Loading'
import './InputButton.css'

interface Props {
	id: string
	type: 'select' | 'date' | 'time'
	text?: React.ReactNode
	error?: string
	disabled?: boolean
	loading?: boolean
	placeholder?: string
	onClick: () => void
}

const InputButton = forwardRef<HTMLElement, Props>((props, ref) => {
	const {
		id,
		type,
		text,
		error = emptyString,
		disabled = false,
		loading,
		placeholder,
		onClick
	} = props
	const [icon, setIcon] = useState<ReactNode>()

	const getIcon = (type: 'select' | 'date' | 'time') => {
		switch (type) {
			case 'select':
				return <CaretDownIcon />
			case 'date':
				return <CalendarBlank />
			case 'time':
				return <ClockIcon />
			default:
				return null
		}
	}

	useEffect(() => {
		const iconSelected = getIcon(type)
		setIcon(iconSelected)
	}, [type])

	const classNames = () => {
		let className = 'input-button'
		if (text) className += ' filled'
		if (error) className += ' error'
		if (disabled || loading) className += ' disabled'
		return className
	}

	return (
		<button
			ref={ref as RefObject<HTMLButtonElement>}
			id={id}
			type='button'
			className={classNames()}
			disabled={disabled}
			onClick={onClick}>
			<span className={`input-button-text${!text && placeholder ? ' placeholder' : emptyString}`}>
				{loading ? <Loading hasText={false} isLoadingInput /> : text ?? placeholder}
			</span>
			<div className='input-button-icon'>{icon}</div>
		</button>
	)
})

export default InputButton
