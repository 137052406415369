import { API_URL } from 'constants/environment'

const BASE_URL = API_URL || 'https://apiv2develop.mytransfer.app/'

export const endpoints = {
	user: BASE_URL + 'user',
	register: BASE_URL + 'register',
	forgotPassword: BASE_URL + 'forgot-password',
	changePassword: BASE_URL + 'change-password',
	login: BASE_URL + 'login',
	me: BASE_URL + 'me',
	users: BASE_URL + 'users',
	drivers: BASE_URL + 'drivers',
	providers: BASE_URL + 'providers',
	vehicles: BASE_URL + 'vehicles',
	calendarDaily: BASE_URL + 'calendar/daily',
	fomentoDaily: BASE_URL + 'fomento/notifications/date',
	shared: BASE_URL + 'shared',
	calendarDailyShared: BASE_URL + 'shared/date',
	sharedByTransfer: BASE_URL + 'shared/transfer',
	sharedByTransferRequest: BASE_URL + 'shared/request',
	acceptTransferSharedRequest: BASE_URL + 'shared/accept-request',
	rejectTransferSharedRequest: BASE_URL + 'shared/reject-request',
	cancelTransferSharedRequest: BASE_URL + 'shared/cancel-request',
	startEnd: BASE_URL + 'start-end',
	transfers: BASE_URL + 'transfers',
	historical: BASE_URL + 'historic/transfers',
	transfersPdf: BASE_URL + 'transfer/contract',
	transfersDeleted: BASE_URL + 'transfers/deleted',
	transfersClipboard: BASE_URL + 'transfers/clipboard',
	fomento: BASE_URL + 'fomento',
	fomentoNotifications: BASE_URL + 'fomento/notifications',
	fomentoCommunicationPdf: BASE_URL + 'pdf/communicate',
	fomentoConfirmationPdf: BASE_URL + 'pdf/confirmation',
	communicate: BASE_URL + 'fomento/communicate',
	confirm: BASE_URL + 'fomento/confirm',
	cancel: BASE_URL + 'fomento/cancel',
	settings: BASE_URL + 'settings',
	paymentMethods: BASE_URL + 'settings/payment-methods',
	configurations: BASE_URL + 'configurations',
	configurationsTransfer: BASE_URL + 'configurations/transfer',
	friends: BASE_URL + 'friends',
	friendRequests: BASE_URL + 'friend-requests',
	friendSendRequest: BASE_URL + 'friends/send-request',
	friendAcceptRequest: BASE_URL + 'friends/accept-request',
	friendsCompaniesAvailable: BASE_URL + 'friends/available',
	friendRejectReceivedRequest: BASE_URL + 'friends/reject-received-request',
	friendRejectSentRequest: BASE_URL + 'friends/reject-sent-request',
	company: BASE_URL + 'my-company',
	subscriptions: BASE_URL + 'subscriptions',
	mySubscription: BASE_URL + 'my-subscriptions',
	changeMyPassword: BASE_URL + 'change-my-password',
	myInvoices: BASE_URL + 'own-invoices',
	ownInvoicePdf: BASE_URL + 'pdf/own-invoice',
	stripe: BASE_URL + 'stripe',
	notifications: BASE_URL + 'notifications',
	firebaseToken: BASE_URL + 'firebase-token',
	search: BASE_URL + 'search',
	recentSearches: BASE_URL + 'search/recent-searches',
	saveRecentSearch: BASE_URL + 'search/save-recent-search',
	clearRecentSearches: BASE_URL + 'search/clear-recent-searches',
	statisticsProvider: BASE_URL + 'statistics/provider',
	statisticsDriver: BASE_URL + 'statistics/driver',
	statisticsVehicle: BASE_URL + 'statistics/by-months',
	statisticsCalendar: BASE_URL + 'statistics/calendar',
	statisticsLastTransfers: BASE_URL + 'statistics/last-transfers',
	statisticsPendingTransfers: BASE_URL + 'statistics/number-of-pending-transfers',
	statisticsTransfersByYear: BASE_URL + 'statistics/number-of-transfers-by-year',
	billing: BASE_URL + 'billing',
	billingLine: BASE_URL + 'billing/lines',
	billingPdf: BASE_URL + 'billing/invoice',
	filterBilling: BASE_URL + 'filter-billing'
}
