import { PercentageIcon } from 'assets/svgs'
import EuroIcon from 'assets/svgs/EuroIcon'
import PlusCircleIcon from 'assets/svgs/PlusCircleIcon'
import TrashIcon from 'assets/svgs/TrashIcon'
import Button from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import { Line } from 'models/Billing'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	concepts: Line[]
	createConcept: () => void
	updateConcept: (index: number, field: keyof Line, value: string) => void
	removeConcept: (index: number) => void
}

const InvoiceConcepts = ({ concepts, createConcept, updateConcept, removeConcept }: Props) => {
	const { t } = useTranslation()

	const calculateTotal = (quantity: Line['quantity'], price: Line['price'], vat: Line['vat']) => {
		return (quantity * price * (1 + vat / 100)).toFixed(2)
	}

	return (
		<>
			{concepts.map((concept, index) => (
				<Fragment key={index}>
					<Input
						id={`concepts.${index}.concept`}
						label={t('settings.billing.concept')}
						placeholder={t('settings.billing.concept')}
						valueSelected={concept.concept}
						onChange={(e) => updateConcept(index, 'concept', e.target.value)}
					/>
					<Input
						id={`concepts.${index}.units`}
						label={t('settings.billing.quantity')}
						placeholder={t('settings.billing.quantity')}
						type='number'
						valueSelected={concept.quantity.toString()}
						onChange={(e) => updateConcept(index, 'quantity', e.target.value)}
					/>
					<Input
						id={`concepts.${index}.price`}
						label={t('settings.billing.amount')}
						placeholder={t('settings.billing.amount')}
						type='number'
						valueSelected={concept.price.toString()}
						icon={<EuroIcon />}
						onChange={(e) => updateConcept(index, 'price', e.target.value)}
					/>
					<Input
						id={`concepts.${index}.vat`}
						label={t('settings.billing.tax')}
						placeholder={t('settings.billing.tax')}
						type='number'
						valueSelected={concept.vat.toString()}
						icon={<PercentageIcon />}
						onChange={(e) => updateConcept(index, 'vat', e.target.value)}
					/>
					<Input
						id={`concepts.${index}.total`}
						label={t('settings.billing.total')}
						placeholder={t('settings.billing.total')}
						valueSelected={calculateTotal(concept.quantity, concept.price, concept.vat)}
						readOnly
						icon={<EuroIcon />}
					/>
					<Button variant='only-icon' onClick={() => removeConcept(index)}>
						<TrashIcon />
					</Button>
				</Fragment>
			))}

			<Button variant='text' onClick={createConcept} className='margin-left-auto'>
				<PlusCircleIcon />
				{t('settings.billing.add_concept')}
			</Button>
		</>
	)
}

export default InvoiceConcepts
