import { FirebaseMessaging } from '@capacitor-firebase/messaging'
import { Capacitor } from '@capacitor/core'
import { LocalNotifications } from '@capacitor/local-notifications'
import { PushNotifications } from '@capacitor/push-notifications'
import { ThemeProvider } from '@mui/material/styles'
import { WarningIcon } from 'assets/svgs'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import { LOGOUT_URL } from 'constants/routes'
import useInitializeAppData from 'hooks/useInitializeAppData'
import { useModal } from 'hooks/useModal'
import useSupport from 'hooks/useSupport'
import useToast from 'hooks/useToast'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, useLocation, useNavigate } from 'react-router-dom'
import { Toaster } from 'sonner'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { useUserStore } from 'stores/useUserStore'
import { emptyString } from 'utils/common'
import { materialTheme } from './designSystem/materialTheme'
import { UserRoles } from './models'
import { RoutesContainer } from './routes/routes'

function AppContent() {
	const { getAllData, isLoading } = useInitializeAppData()
	const userState = useUserStore()
	const currentUser = userState.currentUser
	const [isBlockedAccessModalVisible, toggleBlockedAccessModalVisibility] = useModal()
	const handleSupport = useSupport()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const location = useLocation()
	const { resetPreviousRoute, isResettable, setResettable } = usePreviousRouteStore()
	const { showSuccessToast } = useToast()

	const setupPushNotificationChannel = async () => {
		try {
			if (Capacitor.getPlatform() === 'android') {
				await PushNotifications.createChannel({
					id: 'default',
					name: 'Default Notifications',
					description: 'Canal para notificaciones generales',
					importance: 5,
					visibility: 1
				})
			}
		} catch (error) {
			console.error('Error al crear el canal de notificaciones:', error)
		}
	}

	const setupFirebaseNotifications = async () => {
		if (Capacitor.getPlatform() === 'web') {
			return
		}

		try {
			const permission = await PushNotifications.requestPermissions()
			if (permission.receive === 'granted') {
				await FirebaseMessaging.removeAllListeners()
				await PushNotifications.removeAllListeners()

				await PushNotifications.register()
				await setupPushNotificationChannel()

				if (Capacitor.getPlatform() === 'android') {
					await LocalNotifications.requestPermissions()
				}

				if (Capacitor.getPlatform() === 'ios') {
					PushNotifications.addListener('pushNotificationReceived', (notification) => {
						console.log('Notificación recibida:', notification)
					})
				}

				FirebaseMessaging.addListener('notificationReceived', async (message) => {
					console.log(
						`Título: ${message.notification?.title}\nMensaje: ${message.notification?.body}`
					)

					if (Capacitor.getPlatform() === 'android') {
						await LocalNotifications.schedule({
							notifications: [
								{
									title: message.notification?.title || emptyString,
									body: message.notification?.body || emptyString,
									id: Math.floor(Math.random() * 100000),
									sound: 'default',
									autoCancel: true
								}
							]
						})
						showSuccessToast({
							title: message.notification?.title,
							description: message.notification?.body
						})
					}
				})

				PushNotifications.addListener('pushNotificationActionPerformed', (action) => {
					console.log('Notificación interactuada:', action)
				})
			}
		} catch (error) {
			console.error('Error al configurar notificaciones:', error)
		}
	}

	useEffect(() => {
		setupFirebaseNotifications()
	}, [])

	useEffect(() => {
		if (currentUser.id) {
			getAllData()

			if (currentUser.userTypeId === UserRoles.driver) {
				if (!currentUser.allowedDrivers.length) {
					toggleBlockedAccessModalVisibility()
				}
			} else if (currentUser.userTypeId === UserRoles.provider) {
				if (!currentUser.allowedProviders.length) {
					toggleBlockedAccessModalVisibility()
				}
			}
		}
	}, [currentUser])

	useEffect(() => {
		if (isResettable) {
			resetPreviousRoute()
		} else {
			setResettable(true)
		}
	}, [location.pathname])

	if (isLoading && currentUser.id) {
		return <Loading fullscreen />
	}

	const onCloseBlockedAccessModal = () => {
		toggleBlockedAccessModalVisibility()
		navigate(LOGOUT_URL)
	}

	const onContactSupport = () => {
		onCloseBlockedAccessModal()
		handleSupport()
	}

	return (
		<div className='App'>
			<Toaster richColors />
			<RoutesContainer />
			<ModalActions
				isVisible={isBlockedAccessModalVisible}
				onClose={onCloseBlockedAccessModal}
				type='error'
				icon={<WarningIcon />}
				title={t('blocked_access_modal.title')}
				description={t('blocked_access_modal.description', {
					entity:
						currentUser.userTypeId === UserRoles.driver
							? t('general.driver').toLocaleLowerCase()
							: t('general.provider').toLocaleLowerCase()
				})}
				primaryButton={{
					text: t('blocked_access_modal.contact_support'),
					onClick: onContactSupport
				}}
				secondaryButton={{
					text: t('general.quit'),
					onClick: onCloseBlockedAccessModal
				}}
			/>
		</div>
	)
}

function App() {
	return (
		<ThemeProvider theme={materialTheme}>
			<BrowserRouter>
				<AppContent />
			</BrowserRouter>
		</ThemeProvider>
	)
}

export default App
