import './SubscriptionCardContent.css'

interface Props {
	title: string
	detail: string
}

const SubscriptionTrialCardContent: React.FC<Props> = (props) => {
	const { title, detail } = props

	return (
		<div className='subscription-card-content'>
			<h2 className='subscription-trial-card-content-title'>{title}</h2>
			<span className='subscription-trial-card-content-detail'>{detail}</span>
		</div>
	)
}

export default SubscriptionTrialCardContent
