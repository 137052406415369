import ProviderInputs from 'components/Resources/Provider/ProviderInputs'
import ButtonComponent from 'components/elements/Button/Button'
import StepNumber from 'components/elements/StepNumber/StepNumber'
import { ONBOARDING_CREATE_FIRST_DRIVER_URL, ONBOARDING_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useProvider from 'hooks/useProvider'
import useProviders from 'hooks/useProviders'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { DocumentType, ProviderForm, ProviderPost } from 'models/Provider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import * as Yup from 'yup'
import { ONBOARDING_FORM_PROGRESS, ONBOARDING_STEP } from './FirstSteps'
import './FirstSteps.css'
import FirstStepsCounter from './FirstStepsCounter'

const CreateFirstProvider: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const configStore = useConfigStore()
	const { providers } = useProviders()
	const { createProvider } = useProvider()
	const [loading, setLoading] = useState(false)

	const formik = useFormik<ProviderForm>({
		initialValues: {
			name: emptyString,
			fiscalName: emptyString,
			documentTypeId: null,
			document: emptyString,
			provinceId: null,
			cityId: null,
			address: emptyString,
			postalCode: emptyString,
			isInternational: false
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('provider.name') }))
		}),
		onSubmit: async (values) => {
			const provinceCode = values.provinceId
				? configStore.provinces.byId[values.provinceId]?.code
				: emptyString
			const cityCode = values.cityId
				? configStore.municipalities.byId[values.cityId]?.municipalityCode
				: emptyString
			const documentType = values.documentTypeId
				? configStore.documentTypes.byId[values.documentTypeId]?.name
				: DocumentType.CIF
			const provider: ProviderPost = {
				name: values.name,
				fiscalName: values.fiscalName,
				documentType: documentType,
				document: values.document,
				province: provinceCode,
				city: cityCode,
				address: values.address,
				postalCode: values.postalCode,
				isActive: true,
				isInternational: values.isInternational
			}
			try {
				setLoading(true)
				await createProvider(provider)
				toast.success(t('provider.success_create'))
				navigate(ONBOARDING_CREATE_FIRST_DRIVER_URL)
			} catch (error) {
				toast.error(t('provider.error_create'))
			} finally {
				setLoading(false)
			}
		}
	})

	const handleSubmit = async () => {
		await formik.submitForm()
		focusFirstError()
	}

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	useEffect(() => {
		const isComplete = providers.filter((provider) => provider.isManualInvoice === false).length > 0

		if (isComplete) {
			navigate(ONBOARDING_URL)
		}
	}, [providers])

	return (
		<FormLayout
			title={t('onboarding.first_steps.form_title')}
			progress={ONBOARDING_FORM_PROGRESS[ONBOARDING_STEP.SECOND]}
			leftButton={
				<ButtonComponent variant='text' onClick={() => navigate(ONBOARDING_URL)}>
					{t('general.quit')}
				</ButtonComponent>
			}
			rightButton={<FirstStepsCounter step={ONBOARDING_STEP.SECOND} />}
			footer={
				<div className='first-steps-footer'>
					<ButtonComponent onClick={handleSubmit} fullWidth loading={loading}>
						{t('general.save_and_continue')}
					</ButtonComponent>
				</div>
			}>
			<FormSectionLayout
				title={t('onboarding.first_steps.create_first_provider')}
				titleIcon={<StepNumber number={ONBOARDING_STEP.SECOND} size='large' />}
				noDivider>
				<p className='first-steps-form-description'>
					{t('onboarding.first_steps.create_first_provider_description')}
				</p>
			</FormSectionLayout>
			<ProviderInputs formik={formik} setFieldRef={setFieldRef} />
		</FormLayout>
	)
}

export default CreateFirstProvider
