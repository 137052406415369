import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './TextArea.css'

interface Props {
	label: string
	id: string
	className?: string
	styles?: React.CSSProperties
	placeholder?: string
	onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
	valueSelected?: string
	error?: string
	isLabelOptional?: boolean
	rows?: number
	cols?: number
}

const TextArea: FC<Props> = (props) => {
	const {
		label,
		id,
		className,
		styles,
		placeholder,
		valueSelected,
		error = emptyString,
		isLabelOptional = false,
		onChange,
		rows = 3,
		cols
	} = props
	const { t } = useTranslation()

	const [value, setValue] = useState(valueSelected || emptyString)

	const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(e.target.value)
		onChange?.(e)
	}

	useEffect(() => {
		setValue(valueSelected || emptyString)
	}, [valueSelected])

	return (
		<div className={`textarea-wrapper ${error ? 'error' : emptyString}`.trim()} style={styles}>
			<label htmlFor={id}>
				{label}
				{isLabelOptional && <span className='helper-text'> ({t('general.optional')})</span>}
			</label>
			<div className='textarea-container'>
				<textarea
					id={id}
					name={id}
					className={`${className}${error ? ' error' : emptyString}`}
					onChange={handleChange}
					value={value}
					placeholder={placeholder}
					rows={rows}
					cols={cols}
				/>
			</div>
			{error && <span className='input-error-message error-message'>{error}</span>}
		</div>
	)
}

export default TextArea
