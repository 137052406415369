import Tooltip, { TooltipProps } from 'components/elements/Tooltip/Tooltip'
import './FormSectionLayout.css'

interface Props {
	title?: string
	titleIcon?: React.ReactNode
	helper?: string
	tooltip?: TooltipProps
	noDivider?: boolean
	className?: string
	children: React.ReactNode
	smallGap?: boolean
}

const FormSectionLayout: React.FC<Props> = (props) => {
	const { title, titleIcon, helper, tooltip, noDivider, className, children, smallGap } = props

	const classNames = () => {
		let classes = 'form-section-layout'
		if (noDivider) classes += ` no-divider`
		if (smallGap) classes += ' small-gap'
		if (className) classes += ` ${className}`
		return classes
	}

	return (
		<section
			className={classNames()}
			onClick={(e) => {
				e.stopPropagation()
			}}>
			<div className='form-section-layout-children'>
				{title && (
					<div className='form-section-layout-heading'>
						{titleIcon && titleIcon}
						<h2>{title}</h2>
						{helper && <span className='helper-text'>{helper}</span>}
						{tooltip && <Tooltip title={tooltip.title} description={tooltip.description} />}
					</div>
				)}
				{children}
			</div>
		</section>
	)
}

export default FormSectionLayout
