import { ENVIRONMENT } from 'constants/environment'
import { Register } from 'services/user'
import { emptyString } from 'utils/common'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

const EXPIRATION_TIME = 1 * 60 * 1000 // 30 min

interface RegisterStoreState {
	currentRegister: Register
	timestamp: number | null
	resetCurrentRegister: () => void
	updateCurrentRegister: (register: Partial<Register>) => void
	isExpired: () => boolean
}

export const useRegisterStore = create<RegisterStoreState>()(
	devtools(
		(set, get) => ({
			currentRegister: {} as Register,
			timestamp: null,

			resetCurrentRegister: () => {
				set({
					currentRegister: {
						name: emptyString,
						email: emptyString,
						password: emptyString,
						taxi: false,
						vtc: false
					},
					timestamp: null
				})
			},

			updateCurrentRegister: (register: Partial<Register>) => {
				const isExpired = get().isExpired()

				if (isExpired) {
					get().resetCurrentRegister()
					return
				}

				set((state) => ({
					currentRegister: {
						...state.currentRegister,
						...register
					},
					timestamp: Date.now()
				}))
			},

			isExpired: () => {
				const timestamp = get().timestamp
				if (!timestamp) return false

				const now = Date.now()
				return now - timestamp > EXPIRATION_TIME
			}
		}),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'register store'
		}
	)
)
