import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './PaidTag.css'

interface Props {
	paid?: boolean
	paymentMethod?: string
}

const PaidTag: FC<Props> = (props) => {
	const { paid, paymentMethod } = props

	const { t } = useTranslation()

	return (
		<>
			{paymentMethod ? (
				<div className='paid-tag'>{`${paymentMethod}`}</div>
			) : (
				<div className='paid-tag'>{paid ? t('general.paid') : t('general.not_paid')}</div>
			)}
		</>
	)
}

export default PaidTag
