import CarIcon from 'assets/svgs/CarIcon'
import SteeringWheel from 'assets/svgs/SteeringWheel'
import UserList from 'assets/svgs/UserList'
import SmallTransferCard from 'components/elements/SmallTransferCard/SmallTransferCard'
import {
	DRIVERS_VIEW_URL,
	PROVIDERS_VIEW_URL,
	TRANSFER_URL,
	VEHICLES_VIEW_URL
} from 'constants/routes'
import { SearchResponse, SearchState, SearchTags } from 'models/Search'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import SearchItem from './SearchItem'
import './SearchResults.css'

interface Props {
	results: SearchResponse
	search: SearchState
	addRecentSearch: (recentSearchObject: SearchState) => void
}

const SearchResults = ({ results, search, addRecentSearch }: Props) => {
	const { setPreviousRoute } = usePreviousRouteStore()
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<div className='results-list'>
			{search.tag === SearchTags.transfers && !results.transfers?.length && (
				<p className='no-matches'>{t('search.no_transfers_found', { search: search.query })}</p>
			)}
			{search.tag === SearchTags.drivers && !results.drivers?.length && (
				<p className='no-matches'>{t('search.no_drivers_found', { search: search.query })}</p>
			)}
			{search.tag === SearchTags.providers && !results.providers?.length && (
				<p className='no-matches'>{t('search.no_providers_found', { search: search.query })}</p>
			)}
			{search.tag === SearchTags.vehicles && !results.vehicles?.length && (
				<p className='no-matches'>{t('search.no_vehicles_found', { search: search.query })}</p>
			)}
			{!search.tag &&
				!results.transfers?.length &&
				!results.drivers?.length &&
				!results.providers?.length &&
				!results.vehicles?.length && (
					<p className='no-matches'>{t('search.no_matches_found', { search: search.query })}</p>
				)}

			{results.transfers?.map((transfer, index) => (
				<div
					key={`transfer-${index}`}
					onClick={() => {
						addRecentSearch({ query: search.query, tag: SearchTags.transfers })
						navigate(`${TRANSFER_URL}/${transfer.id}`)
					}}>
					<SmallTransferCard transfer={transfer} />
				</div>
			))}
			{results.drivers?.map((driver, index) => (
				<SearchItem
					key={`driver-${index}`}
					icon={<SteeringWheel width={28} height={28} />}
					text={driver.name}
					text2={t('search.driver')}
					onClick={() => {
						setPreviousRoute(window.location.pathname)
						addRecentSearch({ query: search.query, tag: SearchTags.drivers })
						navigate(`${DRIVERS_VIEW_URL}/${driver.id}`)
					}}
				/>
			))}
			{results.providers?.map((provider, index) => (
				<SearchItem
					key={`provider-${index}`}
					icon={<UserList width={28} height={28} />}
					text={provider.name}
					text2={t('search.provider')}
					onClick={() => {
						setPreviousRoute(window.location.pathname)
						addRecentSearch({ query: search.query, tag: SearchTags.providers })
						navigate(`${PROVIDERS_VIEW_URL}/${provider.id}`)
					}}
				/>
			))}
			{results.vehicles?.map((vehicle, index) => (
				<SearchItem
					key={`vehicle-${index}`}
					icon={<CarIcon width={28} height={28} />}
					text={`${vehicle.brand} ${vehicle.model}`}
					text2={vehicle.plate}
					onClick={() => {
						setPreviousRoute(window.location.pathname)
						addRecentSearch({ query: search.query, tag: SearchTags.vehicles })
						navigate(`${VEHICLES_VIEW_URL}/${vehicle.id}`)
					}}
				/>
			))}
		</div>
	)
}

export default SearchResults
