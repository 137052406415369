import { RequestShare } from 'assets/svgs'
import DropdownSelector from 'components/elements/DropdownSelector/DropdownSelector'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import useFriends from 'hooks/useFriends'
import { useModal } from 'hooks/useModal'
import { SharedTransfer } from 'models/Transfer'
import { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import './CategoryTag.css'
import RequestShareTagTooltip from './RequestShareTagTooltip'
interface Props {
	pendings: SharedTransfer[]
	small?: boolean
}

const RequestShareTag: React.FC<Props> = (props) => {
	const { pendings, small = false } = props
	const { getFriendProviderNameById } = useFriends()

	const { t } = useTranslation()
	const [isRequestShareModalVisible, toggleRequestShareModal] = useModal()

	if (!pendings || pendings.length === 0) return null

	const onRequestShareTagClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		toggleRequestShareModal()
	}

	const requests = pendings.map((request) => ({
		id: request.id,
		name: request.receivingCompanyName,
		subtitle: getFriendProviderNameById(request.receivingCompanyId)!
	}))

	return (
		<>
			<button type='button' className='request-share-category-tag' onClick={onRequestShareTagClick}>
				<RequestShare />
				{!small && <span>{t('transfer.requestSend')}</span>}
			</button>

			<ModalFormLayout
				title={t('transfer.requests_sent.title')}
				isVisible={isRequestShareModalVisible}
				toggleVisibility={toggleRequestShareModal}
				right={<RequestShareTagTooltip />}>
				<DropdownSelector
					subtitle={t('transfer.requests_sent.friends_title')}
					description={t('transfer.requests_sent.friends_description')}
					items={requests}
					handleClick={console.log}
				/>
			</ModalFormLayout>
		</>
	)
}

export default RequestShareTag
