import { Preferences } from '@capacitor/preferences'

export const capacitorStorage = {
	getItem: async (name: string) => {
		try {
			const item = await Preferences.get({ key: name })
			return item.value ? JSON.parse(item.value) : null
		} catch (error) {
			console.error(`Error getting item ${name} from Capacitor Preferences:`, error)
			return null
		}
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	setItem: async (name: string, value: any) => {
		try {
			await Preferences.set({ key: name, value: JSON.stringify(value) })
		} catch (error) {
			console.error(`Error setting item ${name} in Capacitor Preferences:`, error)
		}
	},
	removeItem: async (name: string) => {
		try {
			await Preferences.remove({ key: name })
		} catch (error) {
			console.error(`Error removing item ${name} from Capacitor Preferences:`, error)
		}
	}
}
