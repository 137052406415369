import DotsThreeVertical from 'assets/svgs/DotsThreeVertical'
import { DAILY_URL } from 'constants/routes'
import { useModal } from 'hooks/useModal'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { Fomento } from 'models/Fomento'
import { TransferPost } from 'models/Transfer'
import { MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import ModalFormLayout from '../Modal/ModalFormLayout/ModalFormLayout'
import SelectDriverOptions from '../SelectOptions/SelectDriverOptions'
import './HeaderTransferDetails.css'
import ThreeDotsMenuModal from './ThreeDotsMenuModal'

interface Props {
	dailyTransferId?: number
	driver?: string
	driverId?: number
	isSmallTransfer?: boolean
	date?: string
	time: string
	backgroundColor: string
	showMenuDots?: boolean
	isMockTransfer?: boolean
	fomento?: Nullable<Fomento>
}

const HeaderTransferDetails: React.FC<Props> = (props) => {
	const {
		dailyTransferId,
		driver,
		driverId,
		date,
		time,
		backgroundColor,
		showMenuDots = false,
		isMockTransfer = false
	} = props

	const [isOpen, setIsOpen] = useState(false)

	const params = useParams()
	const { transferId } = useParams()
	const navigate = useNavigate()
	const [isDriverModalVisible, toggleDriverModal] = useModal()
	const { transfer, updateTransfer } = useTransferDetails(
		dailyTransferId ? dailyTransferId : +transferId!
	)

	const { t } = useTranslation()

	const onChangeDriver = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		toggleDriverModal()
	}

	const handleDriverSelect = async (selectedDriverId?: number | number[]) => {
		if (!selectedDriverId || !transfer?.id) return

		const transferPost: Partial<TransferPost> = { driverId: selectedDriverId as number }
		await updateTransfer({ transfer: transferPost, id: transfer.id })

		navigate(`${DAILY_URL}/${params.date}`)
		toggleDriverModal()
	}

	const handleThreeDotsClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation()
		setIsOpen(!isOpen)
	}

	return (
		<header className={`transfer-details-arrival-header`} style={{ backgroundColor }}>
			{driver && (
				<div className='transfer-details-arrival-header-item driver'>
					<span className='body2'>{t('transfer.driver')}</span>
					<div
						className='transfer-details-arrival-header-item-value'
						onClick={onChangeDriver}
						style={{ cursor: 'text' }}>
						{driver}
					</div>
				</div>
			)}
			{date && (
				<div className='transfer-details-arrival-header-item'>
					<span className='body2'>{t('transfer.date')}</span>
					<div className='transfer-details-arrival-header-item-value'>{date}</div>
				</div>
			)}
			<div className='transfer-details-arrival-header-item'>
				<span className='body2'>{t('transfer.time')}</span>
				<div className='transfer-details-arrival-header-item-value'>{time}</div>
			</div>
			{showMenuDots && (
				<>
					<div
						className='transfer-details-three-dots-container'
						onClick={(e) => {
							if (!isMockTransfer) {
								handleThreeDotsClick(e)
							}
						}}>
						<DotsThreeVertical />
					</div>
					<ThreeDotsMenuModal
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						transferId={dailyTransferId ? dailyTransferId : +transferId!}
					/>
				</>
			)}
			{dailyTransferId && driver && driverId && (
				<ModalFormLayout
					title={t('transfer.driver')}
					isVisible={isDriverModalVisible}
					toggleVisibility={toggleDriverModal}>
					<div className='transfer-details-select-driver-container'>
						<SelectDriverOptions
							onSelect={handleDriverSelect}
							label={t('transfer.driver')}
							selectedId={driver ? driverId : undefined}
						/>
					</div>
				</ModalFormLayout>
			)}
		</header>
	)
}

export default HeaderTransferDetails
