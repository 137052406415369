import Party from 'assets/images/party.png'
import Loading from 'components/elements/Loading/Loading'
import {
	HOME_URL,
	ONBOARDING_COMPANY_PROFILE_URL,
	ONBOARDING_CREATE_FIRST_DRIVER_URL,
	ONBOARDING_CREATE_FIRST_PROVIDER_URL,
	ONBOARDING_CREATE_FIRST_VEHICLE_URL,
	ONBOARDING_END_URL
} from 'constants/routes'
import useCompany from 'hooks/useCompany'
import useDrivers from 'hooks/useDrivers'
import useProviders from 'hooks/useProviders'
import useVehicles from 'hooks/useVehicles'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { zeroAmount } from 'utils/common'
import './FirstSteps.css'
import FirstStepsCard from './FirstStepsCard/FirstStepsCard'

export const enum ONBOARDING_STEP {
	FIRST = 1,
	SECOND = 2,
	THIRD = 3,
	FOURTH = 4
}

export const ONBOARDING_FORM_PROGRESS = {
	[ONBOARDING_STEP.FIRST]: 20,
	[ONBOARDING_STEP.SECOND]: 40,
	[ONBOARDING_STEP.THIRD]: 60,
	[ONBOARDING_STEP.FOURTH]: 80
}

const completedProgress = 100

const FirstSteps: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { company, loading: loadingCompany } = useCompany()
	const { providers } = useProviders()
	const { drivers } = useDrivers()
	const { vehicles } = useVehicles()

	const progressSections = {
		[ONBOARDING_STEP.FIRST]: company?.document?.length ? completedProgress : zeroAmount,
		[ONBOARDING_STEP.SECOND]: providers.filter((provider) => provider.isManualInvoice === false)
			.length
			? completedProgress
			: zeroAmount,
		[ONBOARDING_STEP.THIRD]: drivers.filter((driver) => driver.isPending === false).length
			? completedProgress
			: zeroAmount,
		[ONBOARDING_STEP.FOURTH]: vehicles.length ? completedProgress : zeroAmount
	}

	useEffect(() => {
		if (company?.hasTransfers) {
			navigate(HOME_URL)
		}
	}, [company])

	useEffect(() => {
		if (Object.values(progressSections).every((progress) => progress === completedProgress)) {
			if (company?.hasTransfers) {
				navigate(HOME_URL)
			} else {
				navigate(ONBOARDING_END_URL)
			}
		}
	}, [progressSections, navigate])

	if (loadingCompany) return <Loading fullscreen />

	return (
		<div className='first-steps'>
			<header className='first-steps-header'>
				<img className='first-steps-image' src={Party} alt='celebration' />
				<h1 className='first-steps-title'>{t('onboarding.first_steps.title')}</h1>
				<p className='body1 first-steps-description'>{t('onboarding.first_steps.description')}</p>
			</header>
			<div className='first-steps-body'>
				<FirstStepsCard
					step={ONBOARDING_STEP.FIRST}
					title={t('onboarding.first_steps.step1')}
					progress={progressSections[ONBOARDING_STEP.FIRST]}
					onClick={() =>
						progressSections[ONBOARDING_STEP.FIRST] !== completedProgress
							? navigate(ONBOARDING_COMPANY_PROFILE_URL)
							: null
					}
				/>
				<FirstStepsCard
					step={ONBOARDING_STEP.SECOND}
					title={t('onboarding.first_steps.step2')}
					progress={progressSections[ONBOARDING_STEP.SECOND]}
					onClick={() =>
						progressSections[ONBOARDING_STEP.SECOND] !== completedProgress
							? navigate(ONBOARDING_CREATE_FIRST_PROVIDER_URL)
							: null
					}
					isDisabled={progressSections[ONBOARDING_STEP.FIRST] !== completedProgress}
				/>
				<FirstStepsCard
					step={ONBOARDING_STEP.THIRD}
					title={t('onboarding.first_steps.step3')}
					progress={progressSections[ONBOARDING_STEP.THIRD]}
					onClick={() =>
						progressSections[ONBOARDING_STEP.THIRD] !== completedProgress
							? navigate(ONBOARDING_CREATE_FIRST_DRIVER_URL)
							: null
					}
					isDisabled={progressSections[ONBOARDING_STEP.SECOND] !== completedProgress}
				/>
				<FirstStepsCard
					step={ONBOARDING_STEP.FOURTH}
					title={t('onboarding.first_steps.step4')}
					progress={progressSections[ONBOARDING_STEP.FOURTH]}
					onClick={() =>
						progressSections[ONBOARDING_STEP.FOURTH] !== completedProgress
							? navigate(ONBOARDING_CREATE_FIRST_VEHICLE_URL)
							: null
					}
					isDisabled={progressSections[ONBOARDING_STEP.THIRD] !== completedProgress}
				/>
			</div>
		</div>
	)
}

export default FirstSteps
