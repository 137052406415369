import ArrowLeft from 'assets/svgs/ArrowLeft'
import ButtonComponent from 'components/elements/Button/Button'
import { Modal } from 'components/elements/Modal/Modal'
import FormLayout from 'layouts/FormLayout/FormLayout'
import React from 'react'
import './ModalFormLayout.css'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
	title: string
	children: React.ReactNode
	footer?: React.ReactNode
	right?: React.ReactNode
	leftButton?: React.ReactNode
	noKeyDown?: boolean
}

const ModalFormLayout: React.FC<Props> = (props) => {
	const { isVisible, toggleVisibility, title, children, footer, right, leftButton, noKeyDown } =
		props

	return (
		<Modal
			noKeyDown={noKeyDown}
			customClassNames='modal-form-layout'
			isVisible={isVisible}
			toggleVisibility={toggleVisibility}
			isFull
			showCloseButton={false}>
			<FormLayout
				title={title}
				leftButton={
					leftButton ?? (
						<ButtonComponent variant='only-icon' onClick={toggleVisibility} icon={<ArrowLeft />} />
					)
				}
				rightButton={right && right}
				footer={footer && <div className='modal-form-footer-layout'>{footer}</div>}>
				{children}
			</FormLayout>
		</Modal>
	)
}

export default ModalFormLayout
