import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Search from 'components/elements/Search/Search'
import { DAILY_URL } from 'constants/routes'
import { useTranslation } from 'react-i18next'
import './SearchHeader.css'

interface Props {
	query: string
	onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchHeader = ({ query, onInputChange }: Props) => {
	const { t } = useTranslation()

	return (
		<header className='search-header'>
			<ButtonBack url={DAILY_URL} />
			<div className='search-container'>
				<Search value={query} placeholder={t('search.placeholder')} onChange={onInputChange} />
			</div>
		</header>
	)
}

export default SearchHeader
