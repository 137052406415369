import colors from 'constants/colors'
import { FC } from 'react'

interface Props {
	width?: number
	height?: number
	color?: string
}

const NotePencilIcon: FC<Props> = (props) => {
	const { width = 32, height = 32, color = colors.neutral00 } = props

	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path opacity='0.2' d='M25 11L16 20H12V16L21 7L25 11Z' fill='white' />
			<path
				d='M28.7075 7.29329L24.7075 3.29329C24.6146 3.20031 24.5043 3.12655 24.3829 3.07623C24.2615 3.0259 24.1314 3 24 3C23.8686 3 23.7385 3.0259 23.6171 3.07623C23.4957 3.12655 23.3854 3.20031 23.2925 3.29329L11.2925 15.2933C11.1997 15.3862 11.1261 15.4965 11.0759 15.6179C11.0257 15.7393 10.9999 15.8694 11 16.0008V20.0008C11 20.266 11.1054 20.5204 11.2929 20.7079C11.4804 20.8954 11.7348 21.0008 12 21.0008H16C16.1314 21.0009 16.2615 20.9751 16.3829 20.9249C16.5042 20.8747 16.6146 20.8011 16.7075 20.7083L28.7075 8.70829C28.8005 8.61541 28.8742 8.50513 28.9246 8.38373C28.9749 8.26233 29.0008 8.1322 29.0008 8.00079C29.0008 7.86937 28.9749 7.73924 28.9246 7.61785C28.8742 7.49645 28.8005 7.38616 28.7075 7.29329ZM15.5863 19.0008H13V16.4145L21 8.41454L23.5863 11.0008L15.5863 19.0008ZM25 9.58704L22.4137 7.00079L24 5.41454L26.5863 8.00079L25 9.58704ZM28 15.0008V26.0008C28 26.5312 27.7893 27.0399 27.4142 27.415C27.0391 27.7901 26.5304 28.0008 26 28.0008H6C5.46957 28.0008 4.96086 27.7901 4.58579 27.415C4.21071 27.0399 4 26.5312 4 26.0008V6.00079C4 5.47035 4.21071 4.96165 4.58579 4.58657C4.96086 4.2115 5.46957 4.00079 6 4.00079H17C17.2652 4.00079 17.5196 4.10614 17.7071 4.29368C17.8946 4.48122 18 4.73557 18 5.00079C18 5.266 17.8946 5.52036 17.7071 5.70789C17.5196 5.89543 17.2652 6.00079 17 6.00079H6V26.0008H26V15.0008C26 14.7356 26.1054 14.4812 26.2929 14.2937C26.4804 14.1061 26.7348 14.0008 27 14.0008C27.2652 14.0008 27.5196 14.1061 27.7071 14.2937C27.8946 14.4812 28 14.7356 28 15.0008Z'
				fill={color}
			/>
		</svg>
	)
}

export default NotePencilIcon
