import { UserList } from 'assets/svgs'
import EmptyList from 'components/elements/EmptyList/EmptyList'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import SmallTransferCard from 'components/elements/SmallTransferCard/SmallTransferCard'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { TransferView } from 'models/Transfer'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
	results: TransferView[]
	setSelectedTransfers: (transferIds: TransferView['id'][]) => void
}

const WithReservationsInvoiceModalStep2 = ({ results, setSelectedTransfers }: Props) => {
	const { t } = useTranslation()
	const [selectedIds, setSelectedIds] = useState<TransferView['id'][]>([])

	const handleCheckboxChange = (transferId: TransferView['id'], checked: boolean) => {
		const newSelectedIds = checked
			? [...selectedIds, transferId]
			: selectedIds.filter((id) => id !== transferId)

		setSelectedIds(newSelectedIds)
		setSelectedTransfers(newSelectedIds)
	}

	return (
		<>
			{results.length === 0 ? (
				<EmptyList
					icon={<UserList />}
					children={
						<Trans
							i18nKey='settings.billing.no_items_filter_text'
							components={{
								span: <span />
							}}
						/>
					}
				/>
			) : (
				<FormSectionLayout>
					<p className='with-reservations-description'>
						{t('settings.billing.create_with_reservations_modal_description2')}
					</p>
					<div className='with-reservations-invoice-results'>
						{results.map((result) => (
							<div className='with-reservations-invoice-results-item' key={result.id}>
								<CheckboxInput
									id={result.id.toString()}
									onChange={(e) => handleCheckboxChange(result.id, e.target.checked)}
									checked={selectedIds.includes(result.id)}
								/>
								<SmallTransferCard transfer={result} />
							</div>
						))}
					</div>
				</FormSectionLayout>
			)}
		</>
	)
}

export default WithReservationsInvoiceModalStep2
