import React from 'react'
import './Accordion.css'
import useAccordion from './useAccordion'

interface AccordionItemProps {
	isActive: boolean
	onToggle: () => void
}

interface Props {
	children: React.ReactElement<AccordionItemProps> | React.ReactElement<AccordionItemProps>[]
}

const Accordion: React.FC<Props> = (props) => {
	const { children } = props
	const { activeIndex, toggleItem } = useAccordion()

	return (
		<div className='accordion-list'>
			{React.Children.map(children, (child, index) =>
				React.isValidElement(child)
					? React.cloneElement(child, {
							isActive: index === activeIndex,
							onToggle: () => toggleItem(index)
					  })
					: child
			)}
		</div>
	)
}

export default Accordion
