import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ENVIRONMENT } from 'constants/environment'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import App from './App'
import i18n from './i18n'
import './index.css'

if (ENVIRONMENT !== 'localhost') {
	Sentry.init({
		dsn: 'https://0c70eeab25468edb18e11036980f4482@o4507503922774016.ingest.de.sentry.io/4507503928410192',
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		// Performance Monitoring
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [
			'localhost',
			/^https:\/\/api\.mytransfer\.app\//,
			/^https:\/\/apiv2\.mytransfer\.app\//,
			/^https:\/\/apiv2develop\.mytransfer\.app\//,
			/^https:\/\/apidevelop\.mytransfer\.app\//
		],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		environment: ENVIRONMENT
	})
}

const container = document.getElementById('root')
if (!container) {
	throw new Error('Failed to find the root element')
}
const queryClient = new QueryClient()

const root = createRoot(container)
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>

			{ENVIRONMENT === 'localhost' ? <ReactQueryDevtools initialIsOpen={false} /> : null}
		</QueryClientProvider>
	</React.StrictMode>
)
