import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import { FORGOT_PASSWORD_URL, LOGIN_URL } from 'constants/routes'
import { TOAST_DURATION } from 'constants/toast'
import { useFormik } from 'formik'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { handleErrorResponse } from 'services/customFetch'
import { changePasswordService } from 'services/user'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import './ForgotPassword.css'

const ChangePassword: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { showSuccessToast, showErrorToast } = useToast()
	const { token } = useParams() as { token: string }

	const formik = useFormik({
		initialValues: {
			password: emptyString
		},
		validationSchema: yup.object().shape({
			password: yup.string().required(t('errors.required_m', { field: t('iam.password') }))
		}),
		onSubmit: async (values) => {
			await changePassword(values.password)
		}
	})

	const changePassword = async (password: string) => {
		try {
			await changePasswordService(password, token)
			showSuccessToast({
				title: t('iam.toast.updated_password.title'),
				description: t('iam.toast.updated_password.description'),
				primaryButton: {
					text: t('iam.toast.updated_password.primary_button'),
					onClick: () => {
						toast.dismiss()
						navigate(LOGIN_URL)
					}
				}
			})
		} catch (error: unknown) {
			const errorResponse = handleErrorResponse(error)
			let messagesText = t('errors.unexpected_error')

			if (errorResponse) {
				messagesText = Object.values(errorResponse.messages).join('\n')
			}

			showErrorToast({
				description: messagesText,
				duration: TOAST_DURATION,
				primaryButton: {
					text: t('iam.toast.error.back_to_forgot_password'),
					onClick: () => {
						toast.dismiss()
						navigate(FORGOT_PASSWORD_URL)
					}
				}
			})
		}
	}

	return (
		<FormLayout
			title={t('iam.change_password_description')}
			leftButton={<ButtonBack url={FORGOT_PASSWORD_URL} />}
			footer={
				<ButtonComponent onClick={formik.handleSubmit} fullWidth>
					{t('iam.update_password')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('iam.change_password_title')}>
				<p className='forgot-password-description'>{t('iam.change_password_description')}</p>
				<Input
					id='password'
					type='password'
					label={t('iam.password')}
					placeholder={t('iam.change_password_description')}
					onChange={formik.handleChange}
					valueSelected={formik.values['password']}
					error={formik.errors['password']}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default ChangePassword
