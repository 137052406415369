import {
	BellSimpleIcon,
	CopyIcon,
	CreateTransfer,
	FileSearch,
	FrameCornersIcon,
	NotePencilIcon,
	ShareFatIcon,
	TrashIcon
} from 'assets/svgs'
import DrawerMenu from 'components/elements/DrawerMenu/DrawerMenu'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import {
	CREATE_URL_NO_STEPS,
	TRANSFER_FOMENTO_URL,
	TRANSFER_PDF_URL,
	TRANSFER_SHARE_URL,
	TRANSFER_URL
} from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useDrawerMenu from 'hooks/useDrawerMenu'
import { useModal } from 'hooks/useModal'
import { useTransferDetails } from 'hooks/useTransferDetails'
import { Permissions } from 'models/Configurations'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { currentUserIsAdmin } from 'utils/currentUser'
import TransferPoster from './TransferPoster'
interface Props {
	transferId: number
	menuIsOpen: boolean
	closeMenu: () => void
}

const TransferDetailsDrawerMenu: React.FC<Props> = (props) => {
	const { transferId, menuIsOpen, closeMenu } = props

	const { transfer } = useTransferDetails(transferId)
	const { t } = useTranslation()
	const [isPosterVisible, togglePosterModal] = useModal()
	const [isDeleteModalVisible, toggleDeleteModal] = useModal()
	const { isConfigurationActive } = useConfigurations()
	const {
		copyToClipboard,
		navigateToDuplicateTransfer,
		sendToDriver,
		deleteTransferById,
		loading
	} = useDrawerMenu({
		transferId,
		onClose: () => closeMenu()
	})

	const isAdmin = currentUserIsAdmin()

	const menuItems = useMemo(
		() => [
			{
				label: t('transfer.details.drawer_menu.see_document'),
				href: `${TRANSFER_PDF_URL}/:transferId`,
				icon: <FileSearch />
			},
			{
				label: t('transfer.details.drawer_menu.fomento'),
				href: `${TRANSFER_FOMENTO_URL}/:transferId`,
				icon: <BellSimpleIcon />
			},
			{
				label: t('transfer.details.drawer_menu.show_poster'),
				href: '#',
				icon: <FrameCornersIcon />,
				onClick: togglePosterModal
			},
			{
				label: t('transfer.details.drawer_menu.edit_transfer'),
				href: `${TRANSFER_URL}/:transferId/edit`,
				icon: <NotePencilIcon />,
				skip: !isConfigurationActive(Permissions.CREATE_TRANSFER)
			},
			{
				label: t('transfer.details.drawer_menu.copy_to_clipboard'),
				href: '#',
				icon: <CopyIcon />,
				onClick: copyToClipboard
			},
			{
				label: t('transfer.details.drawer_menu.send_to_friend'),
				href: `${TRANSFER_SHARE_URL}/:transferId`,
				icon: <ShareFatIcon />,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.send_to_driver'),
				href: '#',
				icon: <ShareFatIcon />,
				onClick: sendToDriver,
				skip: !isAdmin
			},
			{
				label: t('transfer.details.drawer_menu.duplicate_transfer'),
				href: `${TRANSFER_URL}${CREATE_URL_NO_STEPS}/${transfer!.route}/${transferId}`,
				icon: <CreateTransfer />,
				skip: !isConfigurationActive(Permissions.DUPLICATE_TRANSFER),
				onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
					e.preventDefault()
					navigateToDuplicateTransfer()
				}
			},
			{
				label: t('transfer.details.drawer_menu.delete_transfer'),
				href: '#',
				icon: <TrashIcon />,
				onClick: toggleDeleteModal,
				skip: !isAdmin
			}
		],
		[transferId]
	)

	const menuItemsMapped = menuItems.map((item) => {
		return {
			...item,
			href: item.href.replace(':transferId', transferId.toString())
		}
	})

	if (loading) return <Loading fullscreen />

	return (
		<>
			<DrawerMenu isOpen={menuIsOpen} onClose={closeMenu} items={menuItemsMapped} />
			<TransferPoster
				isVisible={isPosterVisible}
				toggleVisibility={togglePosterModal}
				transferId={transferId}
			/>
			<ModalActions
				type='error'
				title={t('transfer.details.drawer_menu.delete_transfer_modal_title')}
				description={t('transfer.details.drawer_menu.delete_transfer_modal_description')}
				isVisible={isDeleteModalVisible}
				onClose={toggleDeleteModal}
				primaryButton={{
					text: t('general.remove'),
					onClick: deleteTransferById
				}}
				secondaryButton={{
					text: t('general.cancel'),
					onClick: toggleDeleteModal
				}}
			/>
		</>
	)
}

export default TransferDetailsDrawerMenu
