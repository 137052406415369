import { TrashFilledIcon, TrashIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import {
	DRIVERS_EDIT_URL,
	DRIVERS_LIST_URL,
	ERROR_URL,
	NOT_FOUND_URL,
	RESOURCES_URL
} from 'constants/routes'
import useDriver from 'hooks/useDriver'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { emptyString } from 'utils/common'
import '../ResourceView.css'

const DriverView: FC = () => {
	const { driverId } = useParams<{ driverId: string }>()
	const driverIdNumber = parseInt(driverId ?? emptyString) || undefined
	const { driver, loading, error, removeDriver } = useDriver(driverIdNumber!)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const closeDeleteModal = () => setIsDeleteModalVisible(false)

	const deleteDriver = async () => {
		if (!driverIdNumber) return
		await removeDriver(driverIdNumber)
		navigate(DRIVERS_LIST_URL)
	}

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
	if (!driver || !driver.isVisible) {
		navigate(NOT_FOUND_URL)
		return null
	}

	return (
		<>
			<FormLayout
				title={t('driver.view_title')}
				leftButton={<ButtonBack url={DRIVERS_LIST_URL} />}
				rightButton={
					<ButtonComponent
						onClick={() => navigate(`${DRIVERS_EDIT_URL}/${driverId}`)}
						variant='only-text'>
						<strong>{t('general.edit')}</strong>
					</ButtonComponent>
				}>
				<FormSectionLayout title={t('driver.identification')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('driver.name_label')}>{driver.name}</DetailsCardItem>
						<DetailsCardItem title={t('driver.email')}>{driver.email}</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout
					title={t('driver.benefits')}
					tooltip={{
						title: t('driver.tooltip.benefits.title'),
						description: t('driver.tooltip.benefits.description')
					}}>
					<DetailsCard>
						<DetailsCardItem title={t('driver.label.benefit')}>{driver.profit}%</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('driver.email_notifications')}>
					<DetailsCard>
						<DetailsCardItem title={emptyString}>
							<InputSwitch
								id='changeTransferEmail'
								label={t('driver.changeTransferEmail_label')}
								value={driver.changeTransferEmail}
								dataTest='switch-change-transfer-email'
							/>
						</DetailsCardItem>
						<DetailsCardItem title={emptyString}>
							<InputSwitch
								id='communicationEmail'
								label={t('driver.communicationEmail_label')}
								value={driver.communicationEmail}
								dataTest='switch-communication-email'
							/>
						</DetailsCardItem>
						<DetailsCardItem title={emptyString}>
							<InputSwitch
								id='confirmationEmail'
								label={t('driver.confirmationEmail')}
								value={driver.confirmationEmail}
								dataTest='switch-confirmation-email'
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('driver.driver_condition')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('driver.condition')}>
							<InputSwitch
								id='status'
								label={t('driver.condition')}
								value={driver.isActive}
								dataTest='switch-active'
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<ButtonComponent
					className='resource-view-delete-button'
					onClick={() => setIsDeleteModalVisible(true)}>
					<TrashFilledIcon />
					<span className='body1'>{t('driver.delete_driver_button')}</span>
				</ButtonComponent>
			</FormLayout>
			<ModalActions
				type='error'
				icon={<TrashIcon />}
				title={t('driver.delete_modal.title')}
				description={t('driver.delete_modal.description')}
				primaryButton={{
					text: t('driver.delete_modal.primary_button'),
					className: 'modal-delete-resource-primary-button',
					onClick: deleteDriver
				}}
				secondaryButton={{
					text: t('general.cancel'),
					className: 'modal-delete-resource-secondary-button',
					onClick: closeDeleteModal
				}}
				isVisible={isDeleteModalVisible}
				onClose={closeDeleteModal}
			/>
		</>
	)
}

export default DriverView
