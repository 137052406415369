import React from 'react'
import { emptyString } from 'utils/common'
import './Button.css'

export interface Props {
	children?: React.ReactNode
	onClick: ((e: React.MouseEvent) => void) | (() => void)
	id?: string
	type?: 'button' | 'submit' | 'reset' | undefined
	variant?: 'primary' | 'secondary' | 'cancel' | 'text' | 'only-icon' | 'only-text'
	disabled?: boolean
	loading?: boolean
	className?: string
	fullWidth?: boolean
	icon?: React.ReactElement
	leftIcon?: React.ReactElement
	rightIcon?: React.ReactElement
	role?: string
	dataTest?: string
}

const ButtonComponent: React.FC<Props> = (props) => {
	const {
		children,
		onClick,
		id,
		disabled,
		type,
		variant = 'primary',
		fullWidth,
		loading,
		className = emptyString,
		icon,
		leftIcon,
		rightIcon,
		role,
		dataTest
	} = props

	const classNames = () => {
		let classes = 'button-component'
		if (variant) classes += ` ${variant}`
		if (icon) classes += ' icon-button'
		if (disabled) classes += ' disabled'
		if (fullWidth) classes += ' full-width'
		if ((!leftIcon && !rightIcon) || loading) classes += ' no-side-icons'
		if (loading) classes += ' button-loading'
		if (className) classes += ' ' + className
		return classes
	}

	return (
		<button
			id={id}
			className={classNames()}
			onClick={(e) => !disabled && onClick && onClick(e)}
			disabled={disabled || loading}
			type={type}
			data-testid={dataTest}
			role={role || 'button'}>
			<>
				{leftIcon && !loading && <div className='button-icon left'>{leftIcon}</div>}
				{(children || icon) && (
					<div className='button-content'>
						{loading ? (
							<div
								data-testid='loading-indicator'
								className={`button-component-loading ${variant}`}
							/>
						) : (
							<>
								{children && children}
								{icon && <div className='button-icon'>{icon}</div>}
							</>
						)}
					</div>
				)}
				{rightIcon && !loading && <div className='button-icon right'>{rightIcon}</div>}
			</>
		</button>
	)
}

export default ButtonComponent
