export interface Vehicle {
	id: number
	companyId: number
	extraCompanyId: Nullable<number>
	plate: string
	brand: string
	model: string
	class: keyof typeof VehicleClass
	cardDate: Nullable<string>
	itvDate: Nullable<string>
	oilDate: Nullable<string>
	tiresDate: Nullable<string>
	isActive: boolean
	isVisible: boolean
}

export interface VehiclePost {
	plate: string
	brand: string
	model: string
	class: keyof typeof VehicleClass
	isActive: boolean
	cardDate?: string
	itvDate?: string
	oilDate?: string
	tiresDate?: string
}

export interface VehicleTechinicalPost {
	cardDate?: string
	itvDate?: string
	oilDate?: string
	tiresDate?: string
}

export interface VehicleTechnicalDetailsForm {
	cardDate?: Date
	itvDate?: Date
	oilDate?: Date
	tiresDate?: Date
}
export interface VehicleClassType {
	id: number
	name: keyof typeof VehicleClass
}

export enum VehicleClass {
	VTC = 'VTC',
	Taxi = 'Taxi',
	Limusina = 'Limusina',
	Minibus = 'Minibus',
	Autobus = 'Autobus'
}

export interface VehicleForm {
	plate: string
	brand: string
	model: string
	classId: number | null
	isActive?: boolean

	cardDate?: Date
	itvDate?: Date
	oilDate?: Date
	tiresDate?: Date
}
