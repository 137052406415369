import { CheckCircle, CloseIcon, WarningIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import './Toast.css'

interface ToastAction {
	text: string
	onClick: () => void
}

interface Props {
	type?: 'info' | 'success' | 'error'
	icon?: React.ReactNode
	title: string
	description: string
	primaryButton?: ToastAction
	secondaryButton?: ToastAction
	onClose?: () => void
}

const Toast: React.FC<Props> = (props) => {
	const { type = 'info', icon, title, description, primaryButton, secondaryButton, onClose } = props

	const defaultIcon =
		type === 'success' ? <CheckCircle /> : type === 'error' ? <WarningIcon /> : null

	return (
		<div className='toast'>
			<ButtonComponent
				className='toast-close'
				variant='only-icon'
				onClick={onClose ? onClose : () => null}>
				<CloseIcon />
			</ButtonComponent>

			<div className={`toast-icon-background ${type}`}>{icon || defaultIcon || null}</div>
			<h3>{title}</h3>
			<p className='toast-description'>{description}</p>
			<div className='toast-actions'>
				{primaryButton && (
					<ButtonComponent variant='text' onClick={primaryButton.onClick}>
						{primaryButton.text}
					</ButtonComponent>
				)}
				{secondaryButton && (
					<ButtonComponent
						className='toast-secondary-button'
						variant='only-text'
						onClick={secondaryButton.onClick}>
						{secondaryButton.text}
					</ButtonComponent>
				)}
			</div>
		</div>
	)
}

export default Toast
