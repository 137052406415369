import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import { SETTINGS_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { Langs } from 'models/Settings'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLanguageStore } from 'stores/useLanguageStore'
import './Language.css'

const Language = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const languageStore = useLanguageStore()

	const [selectedLang, setSelectedLang] = useState<keyof typeof Langs>(() => {
		const storedLang = localStorage.getItem('language')
		if (storedLang) {
			const parsedLang = JSON.parse(storedLang)
			return parsedLang?.state?.language
		}
		return Langs.ES
	})

	const handleLanguageChange = (lang: keyof typeof Langs) => {
		setSelectedLang(lang)
	}

	const handleSave = () => {
		if (selectedLang) {
			languageStore.setLanguage(selectedLang)
		}
		navigate(SETTINGS_URL)
	}

	return (
		<FormLayout
			title={t('settings.app_language.language_settings.title')}
			leftButton={<ButtonBack url={SETTINGS_URL} />}
			rightButton={
				<ButtonComponent onClick={() => handleSave()} variant='only-text'>
					<strong>{t('general.save')}</strong>
				</ButtonComponent>
			}>
			<div className='container'>
				<p className='subtitle'>{t('settings.app_language.language_settings.subtitle')}</p>
				<p className='description'>{t('settings.app_language.language_settings.description')}</p>
			</div>
			<div className='radio-container'>
				<label>
					<input
						type='radio'
						name='language'
						checked={selectedLang === Langs.ES}
						onChange={() => handleLanguageChange(Langs.ES)}
					/>
					{t('general.spanish')}{' '}
					{t('general.spanish') === t('general.spanish', { lng: 'es' }) ? (
						<span>({t('general.spanish', { lng: 'en' })})</span>
					) : (
						<span>({t('general.spanish', { lng: 'es' })})</span>
					)}
				</label>

				<label>
					<input
						type='radio'
						name='language'
						checked={selectedLang === Langs.EN}
						onChange={() => handleLanguageChange(Langs.EN)}
					/>
					{t('general.english')}{' '}
					{t('general.english') === t('general.english', { lng: 'es' }) ? (
						<span>({t('general.english', { lng: 'en' })})</span>
					) : (
						<span>({t('general.english', { lng: 'es' })})</span>
					)}
				</label>
			</div>
		</FormLayout>
	)
}

export default Language
