import ButtonComponent from 'components/elements/Button/Button'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import useDeletedTransfers from 'hooks/useDeletedTransfers'
import useToast from 'hooks/useToast'
import { WithReservationInvoiceFilters } from 'models/Billing'
import { RouteTypeName, TransferView } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getDateRange } from 'utils/dateUtils'
import './WithReservationsInvoiceModal.css'
import WithReservationsInvoiceModalStep1 from './WithReservationsInvoiceModalStep1'
import WithReservationsInvoiceModalStep2 from './WithReservationsInvoiceModalStep2'
import WithReservationsInvoiceModalStep3 from './WithReservationsInvoiceModalStep3'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
}

const WithReservationsInvoiceModal = ({ isVisible, toggleVisibility }: Props) => {
	const { transfers } = useDeletedTransfers(1, 5)
	const mockResult = transfers

	const { t } = useTranslation()
	const { showErrorToast } = useToast()

	const [currentStep, setCurrentStep] = useState(1)
	const [filter, setFilter] = useState<WithReservationInvoiceFilters>(() => ({
		driverId: undefined,
		providerId: null,
		selectedRoutes: [],
		...getDateRange(0)
	}))
	const [result, setResult] = useState<TransferView[]>([])
	const [filteredResults, setFilteredResults] = useState<TransferView[]>([])
	const [selectedTransfers, setSelectedTransfers] = useState<TransferView['id'][]>([])
	const [noProviderError, setNoProviderError] = useState(false)

	const handleApplyFilters = (e: React.MouseEvent<Element, MouseEvent>) => {
		e.stopPropagation()
		if (currentStep === 1) {
			if (!filter.providerId) {
				setNoProviderError(true)
				return
			}
			setResult(mockResult)
			setCurrentStep(2)
		} else if (currentStep === 2) {
			if (selectedTransfers.length > 0) {
				setCurrentStep(3)
			} else {
				showErrorToast({
					description: t('settings.billing.error_no_results')
				})
			}
		}
	}

	const resetForm = () => {
		setResult([])
		setFilteredResults([])
		setSelectedTransfers([])
		setFilter({
			driverId: undefined,
			providerId: null,
			selectedRoutes: [],
			...getDateRange(0)
		})
		setNoProviderError(false)
		toggleVisibility()
	}

	const handleReturn = () => {
		switch (currentStep) {
			case 2:
				setCurrentStep(1)
				break
			case 3:
				setCurrentStep(2)
				break
			default:
				resetForm()
				setCurrentStep(1)
		}
	}

	const renderStep = () => {
		switch (currentStep) {
			case 1:
				return (
					<WithReservationsInvoiceModalStep1
						filter={filter}
						setFilter={setFilter}
						noProviderError={noProviderError}
					/>
				)
			case 2:
				return (
					<WithReservationsInvoiceModalStep2
						results={filteredResults}
						setSelectedTransfers={setSelectedTransfers}
					/>
				)
			case 3:
				return (
					<WithReservationsInvoiceModalStep3
						selectedTransfers={selectedTransfers}
						providerId={filter.providerId as number}
					/>
				)
			default:
				return null
		}
	}

	useEffect(() => {
		if (result.length > 0) {
			const filterResults = result.filter((transfer) => {
				const matchesDriver = filter.driverId
					? Array.isArray(filter.driverId)
						? filter.driverId.includes(transfer.driverId)
						: filter.driverId === transfer.driverId
					: true
				const matchesProvider = filter.providerId
					? Array.isArray(filter.providerId)
						? filter.providerId.includes(transfer.providerId)
						: filter.providerId === transfer.providerId
					: true
				const matchesRoutes = filter.selectedRoutes?.length
					? filter.selectedRoutes.includes(transfer.route as RouteTypeName)
					: true
				return matchesDriver && matchesProvider && matchesRoutes
			})
			setFilteredResults(filterResults)
		}
	}, [result, filter])

	return (
		<ModalFormLayout
			isVisible={isVisible}
			toggleVisibility={handleReturn}
			title={
				currentStep === 3
					? t('settings.billing.invoice_review')
					: t('settings.billing.create_with_reservations_title')
			}
			leftButton={
				<ButtonComponent onClick={handleReturn} variant='only-text'>
					<strong>{t('general.return')}</strong>
				</ButtonComponent>
			}
			footer={
				<ButtonComponent
					fullWidth
					variant='primary'
					onClick={handleApplyFilters}
					disabled={currentStep === 2 && filteredResults.length === 0}>
					{currentStep === 3 ? t('settings.billing.create_invoice') : t('general.continue')}
				</ButtonComponent>
			}>
			{renderStep()}
		</ModalFormLayout>
	)
}

export default WithReservationsInvoiceModal
