import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import useDriver from 'hooks/useDriver'
import useDrivers from 'hooks/useDrivers'
import { DriverAssociations } from 'models/Driver'
import { User } from 'models/User'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
	user: User
	onChangeAssociations: (userIds: number[]) => void
}

const AssociationsDriver: FC<Props> = (props) => {
	const { user, onChangeAssociations } = props

	const { drivers, loading: loadingDrivers } = useDrivers()
	const { getAssociationsByUserId, loading, driverAssociations } = useDriver()
	const [selectedDrivers, setSelectedDrivers] = useState(new Set<number>())
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])

	const { t } = useTranslation()

	useEffect(() => {
		getAssociations(user.id, driverAssociations)
	}, [driverAssociations])

	const getAssociations = async (userId: number, driverAssociations: DriverAssociations[]) => {
		const associations = getAssociationsByUserId(userId, driverAssociations)
		const driverIds = associations.map((association) => association.driverId)

		setSelectedDrivers(new Set(driverIds))
	}

	const formatAssociations = (): AssociationOption[] => {
		const newAssociations: AssociationOption[] = drivers
			.filter((driver) => driver.isActive && driver.isVisible)
			.filter((driver) => !driver.isPending)
			.map((driver) => {
				const isChecked = selectedDrivers.has(driver.id)
				return {
					id: driver.id,
					name: driver.name,
					isSelected: isChecked
				}
			})
		return newAssociations
	}

	const handleSelection = (selected: AssociationOption[]) => {
		const newSelectedUsers = new Set<number>()
		selected.forEach((association) => {
			if (association.isSelected) {
				newSelectedUsers.add(association.id)
			}
		})
		setSelectedDrivers(newSelectedUsers)
		onChangeAssociations(Array.from(newSelectedUsers))
	}

	useEffect(() => {
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		onChangeAssociations(Array.from(selectedDrivers))
	}, [selectedDrivers])

	return (
		<AssociationList
			associations={associationsOptions}
			title={t('driver_association.association_list_title')}
			addButtonText={t('driver_association.association_list_button')}
			modalContent={{
				title: t('driver_association.association_list_modal_title'),
				subtitle: t('driver_association.association_list_modal_subtitle'),
				description: t('driver_association.association_list_modal_description')
			}}
			handleSelection={handleSelection}
			isLoading={loading || loadingDrivers}
		/>
	)
}

export default AssociationsDriver
