import { TransferView } from 'models/Transfer'
import { useEffect, useState } from 'react'
import { getDeletedTransfers } from 'services/transfers'

const useDeletedTransfers = (initialPage = 1, perPage = 10) => {
	const [transfers, setTransfers] = useState<TransferView[]>([])
	const [pager, setPager] = useState({ totalPages: 0 })
	const [page, setPage] = useState(initialPage)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState<Nullable<string>>(null)

	const fetchDeletedTransfers = async (page: number) => {
		setIsLoading(true)
		setError(null)
		try {
			const response = await getDeletedTransfers(page, perPage)
			setTransfers(response.data)
			setPager(response.pager)
		} catch (error) {
			setError('Error fetching deleted transfers')
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		fetchDeletedTransfers(page)
	}, [page, perPage])

	return {
		transfers,
		pager,
		page,
		isLoading,
		error,
		setPage
	}
}

export default useDeletedTransfers
