import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './Loading.css'

interface Props {
	fullscreen?: boolean
	hasText?: boolean
	isLoadingInput?: boolean
	noBorder?: boolean
}

const Loading: React.FC<Props> = (props) => {
	const { fullscreen, hasText = true, isLoadingInput = false, noBorder = false } = props

	const { t } = useTranslation()
	return (
		<div
			className={`loading ${fullscreen ? ' fullscreen' : emptyString} ${
				noBorder ? 'noBorder' : emptyString
			}`}>
			<div className={`${isLoadingInput ? 'loadingInput' : 'spinner'}  `}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			{hasText && <span>{t('general.loading')}</span>}
		</div>
	)
}

export default Loading
