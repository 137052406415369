import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import ListItem from 'components/elements/List/ListItem/ListItem'
import Search from 'components/elements/Search/Search'
import { FRIENDS_ASSOCIATION_URL, FRIENDS_LIST_URL } from 'constants/routes'
import useFriends from 'hooks/useFriends'
import useSearch from 'hooks/useSearch'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './FriendsSearch.css'

const FriendsSearch = () => {
	const [searchTerm, setSearchTerm] = useState(emptyString)
	const { friendsAvailable } = useFriends()
	const navigate = useNavigate()
	const { t } = useTranslation()

	const previousRoute = useMemo(() => {
		return FRIENDS_LIST_URL
	}, [])

	const filterFriends = useMemo(() => {
		return friendsAvailable.map((friend) => ({
			id: friend.id,
			name: friend.name
		}))
	}, [friendsAvailable])

	const searchResults = useSearch(filterFriends, ['name'], searchTerm)

	return (
		<FormLayout
			title={t('friend.friend_search_page.title')}
			leftButton={<ButtonBack url={previousRoute} />}>
			<FormSectionLayout>
				<Search
					placeholder={t('friend.friend_search_page.message_info_title')}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				{searchTerm.length && !searchResults.length ? (
					<span>
						<Trans
							i18nKey='friend.no_results_text'
							components={{
								span: <span />
							}}
							values={{ query: searchTerm }}
						/>
					</span>
				) : null}

				{!searchTerm.length ? (
					<div>
						<h2>{t('friend.friend_search_page.message_info_title')}</h2>
						<p className='friends-search-description'>
							{t('friend.friend_search_page.message_info_description')}
						</p>
					</div>
				) : null}
			</FormSectionLayout>

			{searchTerm.length && searchResults.length ? (
				<div className='friends-search-list'>
					{searchResults.map((friend) => (
						<ListItem
							key={friend.id}
							right={
								<ButtonComponent
									className='friends-search-request-button'
									onClick={() =>
										navigate(FRIENDS_ASSOCIATION_URL, {
											state: { type: 'send', companyId: friend.id }
										})
									}>
									{t('friend.friend_search_page.request_friendship')}
								</ButtonComponent>
							}>
							<span className='friends-search-name'>{friend.name}</span>
						</ListItem>
					))}
				</div>
			) : null}
		</FormLayout>
	)
}

export default FriendsSearch
