import Tooltip, { TooltipProps } from 'components/elements/Tooltip/Tooltip'
import React from 'react'
import './Input.css'

interface Props {
	label: string
	value: boolean
	id: string
	tooltip?: TooltipProps
	onChange?: (value: boolean) => void
	dataTest?: string
}

const InputSwitch: React.FC<Props> = ({ label, value, id, tooltip, onChange, dataTest }) => {
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChange?.(event.target.checked)
	}

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
		onChange?.(!value)
	}

	const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter' || event.key === ' ') {
			event.stopPropagation()
			onChange?.(!value)
		}
	}

	return (
		<div className='input-switch-container' data-testid={dataTest}>
			<label htmlFor={id} className='input-switch-label'>
				{label}
				{tooltip && <Tooltip title={tooltip.title} description={tooltip.description} />}
			</label>
			<div className='input-switch-wrapper' onClick={handleClick} onKeyDown={handleKeyPress}>
				<input id={id} type='checkbox' checked={value} onChange={handleInputChange} />
				<span className='slider'></span>
			</div>
		</div>
	)
}

export default InputSwitch
