import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> {
	width?: number | string
	height?: number | string
}

const PhoneIcon: React.FC<Props> = ({ width = 100, height = 100 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			xmlns='http://www.w3.org/2000/svg'>
			<g transform='scale(0.521)'>
				<defs>
					<linearGradient id='grad1' x1='30%' y1='0%' x2='100%' y2='0%'>
						<stop
							offset='0%'
							style={{
								stopColor: 'rgb(185,250,250)',
								stopOpacity: 1
							}}
						/>
						<stop
							offset='100%'
							style={{
								stopColor: 'rgb(208,236,236)',
								stopOpacity: 1
							}}
						/>
					</linearGradient>
				</defs>
				<path
					style={{
						fill: 'url(#grad1)'
					}}
					d='m 63,10 66,0 a 12 12 0 0 1 12,12  l 0,148 a 12,12 0 0 1 -12,12 l -66,0 a 12,12 0 0 1 -12,-12 l 0,-148 a 12,12 0 0 1 12,-12 z'
				/>
				<path
					style={{
						fill: '#545454'
					}}
					d='m 63,10 66,0 a 12 12 0 0 1 12,12  l 0,148 a 12,12 0 0 1 -12,12 l -66,0 a 12,12 0 0 1 -12,-12 l 0,-148 a 12,12 0 0 1 12,-12 z m 6,15 a 12 12 0 0 0 -12,12 l 0,118 a 12 12 0 0 0 12,12 l 54,0 a 12,12 0 0 0 12,-12 l 0,-118 a 12,12 0 0 0 -12,-12 z'
				/>
			</g>
		</svg>
	)
}

export default PhoneIcon
