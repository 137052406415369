import Party from 'assets/images/party.png'
import { ArrowRightLightIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './TourCard.css'

export const enum TOUR_STEP {
	DEFAULT = 0,
	FIRST = 1,
	SECOND = 2,
	THIRD = 3,
	FOURTH = 4,
	FIFTH = 5
}

interface Props {
	title: string
	description: string
	step: TOUR_STEP
	backgroundImage: string
	onContinue: () => void
	onFinish: () => void
}

const TourCard: React.FC<Props> = (props) => {
	const { title, description, step, backgroundImage, onContinue, onFinish } = props

	const { t } = useTranslation()

	const lastStep = TOUR_STEP.FIFTH
	const progress = `${step} / ${lastStep}`

	return (
		<div className='tour'>
			<img className='tour-background' src={backgroundImage} alt='background-tour' />
			<div className='tour-card'>
				{step === TOUR_STEP.DEFAULT ? (
					<img className='tour-card-image' src={Party} alt='celebration' />
				) : (
					<div className='tour-card-step'>
						<span>{progress}</span>
					</div>
				)}
				<div className='tour-card-content'>
					<h2 className='tour-card-title'>{title}</h2>
					<p className='tour-card-description'>{description}</p>
				</div>
				<div className={`tour-card-actions ${step !== TOUR_STEP.DEFAULT ? ' row' : emptyString}`}>
					{step === TOUR_STEP.DEFAULT && (
						<ButtonComponent className='tour-button' onClick={onContinue}>
							{t('tour.button.start')}
						</ButtonComponent>
					)}
					{step !== TOUR_STEP.DEFAULT && step !== TOUR_STEP.FIFTH && (
						<>
							<ButtonComponent className='tour-button' variant='secondary' onClick={onContinue}>
								{t('tour.button.next')} <ArrowRightLightIcon />
							</ButtonComponent>

							<ButtonComponent className='tour-button' variant='text' onClick={onFinish}>
								{t('tour.button.skip')}
							</ButtonComponent>
						</>
					)}
					{step === TOUR_STEP.FIFTH && (
						<ButtonComponent className='tour-button' onClick={onFinish}>
							{t('tour.button.finish')}
						</ButtonComponent>
					)}
				</div>
			</div>
		</div>
	)
}

export default TourCard
