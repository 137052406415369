import MagnifyingGlassIcon from 'assets/svgs/MagnifyingGlass'
import ButtonComponent from 'components/elements/Button/Button'
import Loading from 'components/elements/Loading/Loading'
import { SearchState } from 'models/Search'
import { useTranslation } from 'react-i18next'
import SearchItem from './SearchItem'
import './SearchRecentSearches.css'

interface Props {
	loading: boolean
	recentSearches: SearchState[]
	cleanRecentSearches: () => void
	handleRecentSearchClick: (searchItem: SearchState) => void
}

const SearchRecentSearches = ({
	loading,
	recentSearches,
	cleanRecentSearches,
	handleRecentSearchClick
}: Props) => {
	const { t } = useTranslation()

	if (recentSearches.length)
		return (
			<div className='result-container-recent-searches'>
				<div className='result-container-recent-searches--title'>
					{t('search.recent_searches')}{' '}
					<ButtonComponent onClick={cleanRecentSearches} variant='only-text'>
						{t('search.clean')}
					</ButtonComponent>
				</div>
				<div className='results-list-recent'>
					{loading ? (
						<Loading hasText={false} />
					) : (
						<>
							{recentSearches.map((searchItem, index) => (
								<SearchItem
									key={index}
									icon={<MagnifyingGlassIcon width={28} height={28} />}
									text={searchItem.query}
									onClick={() => handleRecentSearchClick(searchItem)}
								/>
							))}
						</>
					)}
				</div>
			</div>
		)
}
export default SearchRecentSearches
