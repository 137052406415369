import { TrashFilledIcon, TrashIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import InputSwitch from 'components/elements/Input/InputSwitch'
import Loading from 'components/elements/Loading/Loading'
import ModalActions from 'components/elements/Modal/ModalActions/ModalActions'
import {
	ERROR_URL,
	NOT_FOUND_URL,
	PROVIDERS_EDIT_URL,
	PROVIDERS_LIST_URL,
	RESOURCES_URL
} from 'constants/routes'
import useProvider from 'hooks/useProvider'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfigStore } from 'stores/useConfigStore'
import { emptyString } from 'utils/common'
import '../ResourceView.css'

const ProviderView: FC = () => {
	const { providerId } = useParams<{ providerId: string }>()
	const providerIdNumber = parseInt(providerId ?? emptyString) || undefined
	const { provider, loading, error, removeProvider } = useProvider(providerIdNumber)
	const configState = useConfigStore()

	const navigate = useNavigate()
	const { t } = useTranslation()

	const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)

	const closeDeleteModal = () => setIsDeleteModalVisible(false)

	const deleteProvider = async () => {
		if (!providerIdNumber) return
		await removeProvider(providerIdNumber)
		navigate(PROVIDERS_LIST_URL)
	}

	if (loading) return <Loading fullscreen />
	if (error) navigate(ERROR_URL, { state: { description: error, link: RESOURCES_URL } })
	if (!provider || !provider.isVisible) {
		navigate(NOT_FOUND_URL)
		return null
	}

	return (
		<>
			<FormLayout
				title={t('provider.view_title')}
				leftButton={<ButtonBack url={PROVIDERS_LIST_URL} />}
				rightButton={
					<ButtonComponent
						onClick={() => navigate(`${PROVIDERS_EDIT_URL}/${providerId}`)}
						variant='only-text'>
						<strong>{t('general.edit')}</strong>
					</ButtonComponent>
				}>
				<FormSectionLayout title={t('provider.identification')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('provider.name')}>{provider.name}</DetailsCardItem>
						<DetailsCardItem title={t('provider.fiscal_name')}>
							{provider.fiscalName}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('provider.documentation')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('provider.document_type')}>
							{provider.documentType}
						</DetailsCardItem>
						<DetailsCardItem title={t('provider.document_number')}>
							{provider.document}
						</DetailsCardItem>
						<DetailsCardItem title={t('provider.international_company')}>
							<InputSwitch
								id='international'
								label={t('provider.international_company')}
								value={provider.isInternational}
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('provider.location')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('provider.province')}>
							{configState.getProvinceByCode(provider.province)?.name}
						</DetailsCardItem>
						<DetailsCardItem title={t('provider.city')}>
							{
								configState.getMunicipalityByProvinceCodeAndMunicipalityCode(
									provider.province,
									provider.city
								)?.name
							}
						</DetailsCardItem>
						<DetailsCardItem title={t('provider.address')}>{provider.address}</DetailsCardItem>
						<DetailsCardItem title={t('provider.postal_code')}>
							{provider.postalCode}
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<FormSectionLayout title={t('provider.provider_condition')} noDivider>
					<DetailsCard>
						<DetailsCardItem title={t('provider.condition')}>
							<InputSwitch
								id='status'
								label={t('provider.condition')}
								value={provider.isActive}
								dataTest='switch-active'
							/>
						</DetailsCardItem>
					</DetailsCard>
				</FormSectionLayout>
				<ButtonComponent
					className='resource-view-delete-button'
					onClick={() => setIsDeleteModalVisible(true)}>
					<TrashFilledIcon />
					<span className='body1'>{t('provider.delete_provider_button')}</span>
				</ButtonComponent>
			</FormLayout>
			<ModalActions
				type='error'
				icon={<TrashIcon />}
				title={t('provider.delete_modal.title')}
				description={t('provider.delete_modal.description')}
				primaryButton={{
					text: t('provider.delete_modal.primary_button'),
					className: 'modal-delete-resource-primary-button',
					onClick: deleteProvider
				}}
				secondaryButton={{
					text: t('general.cancel'),
					className: 'modal-delete-resource-secondary-button',
					onClick: closeDeleteModal
				}}
				isVisible={isDeleteModalVisible}
				onClose={closeDeleteModal}
			/>
		</>
	)
}

export default ProviderView
