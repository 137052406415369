import {
	// CarIcon,
	IdentificationCardIcon,
	// ReceiptIcon,
	UserGear,
	UsersThree,
	WhatsappIcon
} from 'assets/svgs'
import ClockCounterClockwiseIcon from 'assets/svgs/ClockCounterClockwiseIcon'
// import PaletteIcon from 'assets/svgs/PaletteIcon'
import TranslateIcon from 'assets/svgs/TranslateIcon'
import ActionButton from 'components/elements/ActionButton/ActionButton'
import HeaderWithNotificationBell from 'components/elements/HeaderWithNotificationBell/HeaderWithNotificationBell'
import colors from 'constants/colors'
import {
	// APPEARANCE_URL,
	// BILLING_URL,
	DELETED_TRANSFERS_URL,
	MY_ACCOUNT_URL,
	MY_PROFILE_URL,
	// PRECONFIG_TRANSFERS_URL,
	SETTINGS_LANGUAGE_URL,
	USERS_URL
} from 'constants/routes'
// import useConfigurations from 'hooks/useConfigurations'
import useSupport from 'hooks/useSupport'
import DashboardLayout from 'layouts/DashboardLayout/DashboardLayout'
// import { Permissions } from 'models/Configurations'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { currentUserIsAdmin } from 'utils/currentUser'
import './Settings.css'

const SettingPage = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const isAdmin = currentUserIsAdmin()
	const handleSupport = useSupport()
	// const { isConfigurationActive } = useConfigurations()

	const options = useMemo(
		() => [
			{
				label: t('settings.account'),
				icon: <UserGear />,
				backGroundIcon: colors.lightBlueMt,
				onClick: () => navigate(MY_ACCOUNT_URL),
				skip: !isAdmin
			},
			{
				label: t('settings.users'),
				icon: <UsersThree />,
				backGroundIcon: colors.lightBlueMt,
				onClick: () => navigate(USERS_URL),
				skip: !isAdmin
			},
			{
				label: t('settings.my_account.my_profile'),
				icon: <IdentificationCardIcon />,
				backGroundIcon: colors.lightBlueMt,
				onClick: () => navigate(MY_PROFILE_URL),
				skip: isAdmin
			},
			{
				label: t('settings.app_language.language_settings.title'),
				icon: <TranslateIcon />,
				backGroundIcon: colors.lightBlueMt,
				onClick: () => navigate(SETTINGS_LANGUAGE_URL),
				skip: false
			},
			// {
			// 	label: t('settings.appearance.title'),
			// 	icon: <PaletteIcon />,
			// 	backGroundIcon: colors.lightBlueMt,
			// 	onClick: () => navigate(APPEARANCE_URL),
			// 	skip: !isAdmin
			// },
			{
				label: t('settings.deleted_transfers.title'),
				icon: <ClockCounterClockwiseIcon />,
				backGroundIcon: colors.lightBlueMt,
				onClick: () => navigate(DELETED_TRANSFERS_URL),
				skip: !isAdmin
			},
			// {
			// 	label: t('settings.billing.title'),
			// 	icon: <ReceiptIcon />,
			// 	backGroundIcon: colors.lightBlueMt,
			// 	onClick: () => navigate(BILLING_URL),
			// 	skip: !isAdmin || !isConfigurationActive(Permissions.BILLING)
			// },
			// {
			// 	label: t('settings.preconfig_transfers.title'),
			// 	icon: <CarIcon />,
			// 	backGroundIcon: colors.lightBlueMt,
			// 	onClick: () => navigate(PRECONFIG_TRANSFERS_URL),
			// 	skip: !isAdmin
			// },
			{
				label: t('settings.support'),
				icon: <WhatsappIcon />,
				backGroundIcon: colors.whatsappGreen,
				onClick: handleSupport,
				skip: false
			}
		],
		[t]
	)
	return (
		<DashboardLayout>
			<div className='settings-container'>
				<div className='settings-header-container'>
					<HeaderWithNotificationBell title={t('general.settings')} />
				</div>
				<div className='settings-options'>
					{options
						.filter((o) => !o.skip)
						.map((option, index) => (
							<ActionButton
								key={index}
								icon={option.icon}
								backGroundIcon={option.backGroundIcon}
								onClick={option.onClick}>
								{option.label}
							</ActionButton>
						))}
				</div>
			</div>
		</DashboardLayout>
	)
}

export default SettingPage
