import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Input from 'components/elements/Input/Input'
import { BASE_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useToast from 'hooks/useToast'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { forgotPasswordService } from 'services/user'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import * as yup from 'yup'
import './ForgotPassword.css'

const ForgotPassword: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const { showSuccessToast, showErrorToast } = useToast()

	const formik = useFormik({
		initialValues: {
			email: emptyString
		},
		validationSchema: yup.object().shape({
			email: yup
				.string()
				.email(t('errors.mail_not_valid'))
				.required(t('errors.required_m', { field: t('iam.email') }))
		}),
		onSubmit: async (values) => {
			await handleSubmit(values.email)
		}
	})

	const handleSubmit = async (email: string) => {
		try {
			await forgotPasswordService(email)
			showSuccessToast({
				title: t('iam.toast.link_sent.title'),
				description: t('iam.toast.link_sent.description'),
				primaryButton: {
					text: t('iam.toast.link_sent.primary_button'),
					onClick: () => {
						toast.dismiss()
						handleSubmit(formik.values['email'])
					}
				},
				secondaryButton: {
					text: t('iam.toast.link_sent.secondary_button'),
					onClick: () => {
						toast.dismiss()
						navigate(BASE_URL)
					}
				},
				duration: 5000,
				onAutoClose: () => {
					navigate(BASE_URL)
				}
			})
		} catch (error) {
			showErrorToast({
				description: t('errors.unexpected_error')
			})
		}
	}

	return (
		<FormLayout
			title={t('iam.forgot_password_title')}
			leftButton={<ButtonBack url={BASE_URL} />}
			footer={
				<ButtonComponent onClick={formik.handleSubmit} fullWidth>
					{t('general.send')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('iam.email_placeholder')}>
				<p className='forgot-password-description'>{t('iam.forgot_password_description')}</p>
				<Input
					id='email'
					type='email'
					label={t('iam.email')}
					placeholder={t('iam.email_placeholder')}
					onChange={formik.handleChange}
					valueSelected={formik.values['email']}
					error={formik.errors['email']}
				/>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default ForgotPassword
