import { AirplaneTakeoffIcon, MapPinIcon, MapPinLineIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import RequestShareTag from 'components/elements/CategoryTag/RequestShareTag'
import SharedTransferTag from 'components/elements/CategoryTag/SharedTransferTag'
import StartEndCategoryTag from 'components/elements/CategoryTag/StartEndCategoryTag'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import colors from 'constants/colors'
import { TRANSFER_URL } from 'constants/routes'
import useConfigurations from 'hooks/useConfigurations'
import useRouteColors from 'hooks/useRouteColors'
import { Permissions } from 'models/Configurations'
import { Fomento } from 'models/Fomento'
import {
	SharedTransfer,
	StartEndTransfer,
	TransferView,
	TypeStartEndTransfer
} from 'models/Transfer'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/useUserStore'
import { firstElement, zeroElements } from 'utils/common'
import { currentUserIsAdmin } from 'utils/currentUser'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import ExtraSeatCategoryTag from '../CategoryTag/ExtraSeatCategoryTag'
import FomentoCategoryTag from '../CategoryTag/FomentoCategoryTag'
import MapLink from '../MapLink/MapLink'
import './DailyTransferCard.css'

interface Props {
	dailyTransfer: TransferView
	fomento?: Fomento
	startEndTransfers?: StartEndTransfer[]
	sharedTransfers?: SharedTransfer[]
}

const DailyTransferCard = (props: Props) => {
	const { dailyTransfer, fomento, startEndTransfers, sharedTransfers } = props
	const navigate = useNavigate()
	const { t } = useTranslation()
	const [hasStart, setHasStart] = useState<boolean>(false)
	const [hasEnd, setHasEnd] = useState<boolean>(false)
	const [accepted, setAccepted] = useState<SharedTransfer>()
	const [pendingTransfers, setPendingTransfers] = useState<SharedTransfer[]>([])
	const { isConfigurationActive } = useConfigurations()

	const userStore = useUserStore()
	const currentUser = userStore.currentUser
	const isAdmin = currentUserIsAdmin()

	const { getRouteColor, getDegradedColor } = useRouteColors()

	useEffect(() => {
		if (!startEndTransfers) return
		const startEndByDriverId = startEndTransfers?.filter((startEndTransfer) => {
			if (isAdmin) {
				return startEndTransfer.driverId === dailyTransfer.driverId
			}
			return (
				startEndTransfer.userId === currentUser?.id &&
				dailyTransfer.driverId === startEndTransfer?.driverId
			)
		})

		if (startEndByDriverId.length) {
			const firstTransfer = startEndByDriverId[firstElement]
			setHasStart(firstTransfer.type === TypeStartEndTransfer.start)
			setHasEnd(firstTransfer.type === TypeStartEndTransfer.end)
		}
	}, [startEndTransfers])

	useEffect(() => {
		if (!sharedTransfers) return
		const acceptedTransfer = sharedTransfers.find((transfer) => transfer.acceptedTime !== null)
		if (acceptedTransfer) {
			setAccepted(acceptedTransfer)
		}

		const pendingTransfers = sharedTransfers.filter(
			(transfer) => transfer.acceptedTime === null && transfer.rejectedTime === null
		)
		setPendingTransfers(pendingTransfers)
	}, [sharedTransfers])

	if (!dailyTransfer) return null

	const redirectToTransferDetails = () => {
		navigate(`${TRANSFER_URL}/${dailyTransfer.id}`)
	}

	const footerIsVisible = hasStart || hasEnd || pendingTransfers.length || accepted

	const hasExtras =
		dailyTransfer.babySeats > zeroElements ||
		dailyTransfer.boosterSeats > zeroElements ||
		dailyTransfer.maxicosiSeats > zeroElements

	const isFailedTransfer = dailyTransfer.statusId === 3

	return (
		<Card
			role='button'
			className='daily-transfer-card'
			onClick={() => redirectToTransferDetails()}
			style={{
				borderColor: isFailedTransfer ? colors.cancelled : getRouteColor(dailyTransfer.route)
			}}>
			<HeaderTransferDetails
				dailyTransferId={dailyTransfer.id}
				driverId={dailyTransfer.driverId}
				driver={dailyTransfer.driver}
				time={timeToSpanish(dailyTransfer.time)}
				date={dateToSpanish(new Date(dailyTransfer.date))}
				backgroundColor={
					isFailedTransfer ? colors.cancelledLight : getDegradedColor(dailyTransfer.route)
				}
				showMenuDots
			/>

			<main className='daily-transfer-card-main'>
				<div className='daily-transfer-card-main-item'>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span className='daily-transfer-card-main-item-value'>
							{dailyTransfer.originAddress}
						</span>
						<MapLink address={dailyTransfer.originAddress} />
					</div>
				</div>
				<div className='daily-transfer-card-main-item'>
					<div className='daily-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span className='daily-transfer-card-main-item-value'>
							{dailyTransfer.destinationAddress}
						</span>
						<MapLink address={dailyTransfer.destinationAddress} />
					</div>
				</div>
				{dailyTransfer.flight && (
					<div className='daily-transfer-card-main-item'>
						<span className='body2'>{t('transfer.flight')}</span>
						<div className='daily-transfer-card-main-item-wrapper flight-icon'>
							<AirplaneTakeoffIcon width={18} height={18} />
							<span className='daily-transfer-card-main-item-value'>{dailyTransfer.flight}</span>
						</div>
					</div>
				)}
				{hasExtras && (
					<div className='daily-transfer-card-main-item'>
						<span className='body2'>{t('transfer.extras')}</span>
						<ExtraSeatCategoryTag transferId={dailyTransfer.id} />
					</div>
				)}
				{isConfigurationActive(Permissions.NOTIFICATION_FOMENTO) && fomento !== null && (
					<div className='daily-transfer-card-main-item'>
						<span className='body2'>{t('transfer.status')}</span>
						<FomentoCategoryTag fomento={fomento} />
					</div>
				)}
			</main>
			{footerIsVisible ? (
				<footer className='daily-transfer-card-footer'>
					<span className='body2'>{t('transfer.states')}</span>
					<div className='daily-transfer-card-status-items'>
						<StartEndCategoryTag hasStart={hasStart} hasEnd={hasEnd} small />
						<RequestShareTag pendings={pendingTransfers} small />
						<SharedTransferTag accepted={accepted} small />
					</div>
				</footer>
			) : null}
		</Card>
	)
}

export default DailyTransferCard
