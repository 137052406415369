import { InfoIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import { Modal } from 'components/elements/Modal/Modal'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './Tooltip.css'

export interface TooltipProps {
	title: string
	description?: string
	children?: React.ReactNode
}

const Tooltip: React.FC<TooltipProps> = (props) => {
	const { title, description, children } = props

	const { t } = useTranslation()
	const [isVisible, setIsVisible] = useState(false)

	const onClose = () => setIsVisible(false)

	return (
		<>
			<ButtonComponent
				className='tooltip-button'
				variant='only-icon'
				icon={<InfoIcon />}
				onClick={() => setIsVisible(true)}
			/>
			<Modal
				customClassNames='tooltip'
				isVisible={isVisible}
				toggleVisibility={onClose}
				showCloseButton={false}>
				<div className='tooltip-header'>
					<div className='tooltip-icon'>
						<InfoIcon />
					</div>
					<h3>{title}</h3>
				</div>
				{description ? <p className='body1 tooltip-description'>{description}</p> : null}
				{children}
				<ButtonComponent variant='only-text' onClick={onClose}>
					{t('general.accept')}
				</ButtonComponent>
			</Modal>
		</>
	)
}

export default Tooltip
