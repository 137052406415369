import ButtonComponent from 'components/elements/Button/Button'
import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import DetailsCard from 'components/elements/DetailsCard/DetailsCard'
import DetailsCardItem from 'components/elements/DetailsCard/DetailsCardItem'
import { MY_ACCOUNT_URL, MY_PROFILE_EDIT_URL, SETTINGS_URL } from 'constants/routes'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'stores/useUserStore'
import { currentUserIsAdmin } from 'utils/currentUser'

const MyProfile: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const userState = useUserStore()
	const isAdmin = currentUserIsAdmin()

	const detailsInfo = [
		{
			title: t('settings.my_account.profile.username_label'),
			value: userState.currentUser.username
		},
		{
			title: t('settings.my_account.profile.fullname_label'),
			value: `${userState.currentUser.name} ${userState.currentUser.lastName}`
		},
		{
			title: t('settings.my_account.profile.email_label'),
			value: userState.currentUser.email
		}
	]

	const backUrl = isAdmin ? MY_ACCOUNT_URL : SETTINGS_URL

	return (
		<FormLayout
			title={t('settings.my_account.profile.title')}
			leftButton={<ButtonBack url={backUrl} />}
			rightButton={
				<ButtonComponent onClick={() => navigate(MY_PROFILE_EDIT_URL)} variant='only-text'>
					<strong>{t('general.edit')}</strong>
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('settings.my_account.profile.subtitle')}>
				<DetailsCard>
					{detailsInfo.map((item, index) => (
						<DetailsCardItem key={index} title={item.title}>
							{item.value}
						</DetailsCardItem>
					))}
				</DetailsCard>
			</FormSectionLayout>
		</FormLayout>
	)
}

export default MyProfile
