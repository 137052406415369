import BackgroundSubscriptionCardImage from 'assets/images/background-subscription-card.png'
import BackgroundSubscriptionDarkCardImage from 'assets/images/background-subscription-dark-card.png'
import './SubscriptionCard.css'

export const enum SubscriptionCardMode {
	DEFAULT = 'default',
	LIGHT = 'light',
	DARK = 'dark'
}

interface Props {
	children: React.ReactNode
	mode?: SubscriptionCardMode
}

const SubscriptionCard: React.FC<Props> = (props) => {
	const { children, mode = 'default' } = props

	const classNames = () => {
		let classes = 'subscription-card-container'
		if (mode) classes += ` ${mode}`

		return classes
	}

	return (
		<div className={classNames()}>
			<div className='subscription-card'>
				{children}
				{mode !== 'light' && (
					<img
						className='subscription-card-background'
						src={
							mode === 'dark'
								? BackgroundSubscriptionDarkCardImage
								: BackgroundSubscriptionCardImage
						}
						alt='background-card'
					/>
				)}
			</div>
			{mode !== 'light' && <div className='subscription-card-behind' />}
		</div>
	)
}

export default SubscriptionCard
