import { useEffect, useState } from 'react'
import { emptyString, firstElement } from 'utils/common'
import './TabContainer.css'

interface Tab {
	id: number
	label: string
}
interface Props {
	tabs: Tab[]
	onTabChange: (tabId: number) => void
	selectedTab?: number
}
const TabContainer = (props: Props) => {
	const { tabs, onTabChange, selectedTab = firstElement } = props
	const [activeTabId, setActiveTabId] = useState(selectedTab)

	useEffect(() => {
		setActiveTabId(selectedTab)
	}, [selectedTab])

	const handleChange = (tabId: number) => {
		setActiveTabId(tabId)
		onTabChange(tabId)
	}

	if (!tabs.length) return null

	return (
		<div className='tab-controls'>
			{tabs.map((tab) => (
				<button
					key={tab.id}
					className={`${tab.id === activeTabId ? 'active' : emptyString} tab-control`}
					onClick={() => handleChange(tab.id)}
					onKeyDown={(e) => e.key === 'Enter' && handleChange(tab.id)}>
					{tab.label}
				</button>
			))}
		</div>
	)
}

export default TabContainer
