import { PercentageIcon } from 'assets/svgs'
import Input from 'components/elements/Input/Input'
import InputSwitch from 'components/elements/Input/InputSwitch'
import { FormikProps } from 'formik'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { DriverForm } from 'models/Driver'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { getFormikError } from 'utils/formikUtils'

interface Props {
	formik: FormikProps<DriverForm>
	setFieldRef: (field: string) => (ref: HTMLInputElement) => void
	isEditing?: boolean
}

const DriverInputs: FC<Props> = (props) => {
	const { formik, setFieldRef, isEditing = false } = props

	const { t } = useTranslation()

	return (
		<>
			<FormSectionLayout title={t('driver.identification')}>
				<Input
					ref={setFieldRef('name')}
					type='text'
					valueSelected={formik.values.name}
					id='name'
					label={t('driver.name')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'name')}
					placeholder={t('driver.placeholder.driver_name')}
				/>
				<Input
					ref={setFieldRef('email')}
					type='email'
					valueSelected={formik.values.email}
					id='email'
					label={t('driver.email_label')}
					placeholder={t('driver.placeholder.email')}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'email')}
					tooltip={{
						title: t('driver.tooltip.email.title'),
						description: t('driver.tooltip.email.description')
					}}
				/>
			</FormSectionLayout>
			<FormSectionLayout
				title={t('driver.benefits')}
				tooltip={{
					title: t('driver.tooltip.benefits.title'),
					description: t('driver.tooltip.benefits.description')
				}}>
				<Input
					ref={setFieldRef('profit')}
					type='number'
					valueSelected={formik.values.profit?.toString()}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'profit')}
					id='profit'
					label={t('driver.label.benefit')}
					placeholder={t('driver.placeholder.benefit')}
					icon={<PercentageIcon />}
				/>
			</FormSectionLayout>
			<FormSectionLayout
				title={t('driver.email_notifications')}
				tooltip={{
					title: t('driver.tooltip.email_notifications.title'),
					description: t('driver.tooltip.email_notifications.description')
				}}>
				<InputSwitch
					id='changeTransferEmail'
					label={t('driver.changeTransferEmail_label')}
					value={formik.values.changeTransferEmail}
					onChange={(value) => {
						formik.setFieldValue('changeTransferEmail', value)
					}}
					tooltip={{
						title: t('driver.tooltip.change_transfers.title'),
						description: t('driver.tooltip.change_transfers.description')
					}}
					dataTest='switch-change-transfer-email'
				/>
				<InputSwitch
					id='communicationEmail'
					label={t('driver.communicationEmail_label')}
					value={formik.values.communicationEmail}
					onChange={(value) => formik.setFieldValue('communicationEmail', value)}
					tooltip={{
						title: t('driver.tooltip.communication.title'),
						description: t('driver.tooltip.communication.description')
					}}
					dataTest='switch-communication-email'
				/>
				<InputSwitch
					id='confirmationEmail'
					label={t('driver.confirmationEmail')}
					value={formik.values.confirmationEmail}
					onChange={(value) => formik.setFieldValue('confirmationEmail', value)}
					tooltip={{
						title: t('driver.tooltip.confirmation.title'),
						description: t('driver.tooltip.confirmation.description')
					}}
					dataTest='switch-confirmation-email'
				/>
			</FormSectionLayout>
			{isEditing && (
				<FormSectionLayout title={t('driver.driver_status')}>
					<InputSwitch
						id='isActive'
						label={t('general.active')}
						value={formik.values.isActive!}
						onChange={(value) => formik.setFieldValue('isActive', value)}
						dataTest='switch-active'
					/>
				</FormSectionLayout>
			)}
		</>
	)
}

export default DriverInputs
