import { AppLauncher } from '@capacitor/app-launcher'
import { Clipboard } from '@capacitor/clipboard'
import { Capacitor } from '@capacitor/core'
import { CopyIcon, PhoneIcon, SMSIcon, WhatsappIcon } from 'assets/svgs'
import { SEND_WHATSAPP } from 'constants/routes'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { emptyString } from 'utils/common'
import './TransferDetailsPhoneModal.css'

interface Props {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	phone: string
}

const TransferDetailsPhoneModal: FC<Props> = (props) => {
	const { isOpen, setIsOpen, phone } = props
	const { t } = useTranslation()

	const formattedPhone = phone.replace(/\D/g, '')

	const handleCall = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		try {
			const isAvailable = await AppLauncher.canOpenUrl({ url: `tel://${formattedPhone}` })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `tel://${formattedPhone}` })
			} else {
				console.error('No se puede abrir la aplicación de llamadas')
			}
		} catch (error) {
			console.error('Error al intentar llamar:', error)
		}
		setIsOpen(false)
	}

	const handleSMS = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		try {
			const isAvailable = await AppLauncher.canOpenUrl({ url: `sms://${formattedPhone}` })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `sms://${formattedPhone}` })
			} else {
				console.error('No se puede abrir la aplicación de SMS')
			}
		} catch (error) {
			console.error('Error al intentar enviar SMS:', error)
		}
		setIsOpen(false)
	}

	const handleWhatsApp = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		const phoneWithCountryCode = formattedPhone.startsWith('34')
			? formattedPhone
			: `34${formattedPhone}`

		const isPlatformWeb = Capacitor.getPlatform() === 'web'

		if (isPlatformWeb) {
			window.open(`${SEND_WHATSAPP}${phoneWithCountryCode}`, '_blank')
		} else {
			const isAvailable = await AppLauncher.canOpenUrl({ url: 'whatsapp://' })
			if (isAvailable.value) {
				await AppLauncher.openUrl({ url: `whatsapp://send?phone=${phoneWithCountryCode}` })
			} else {
				window.open(`${SEND_WHATSAPP}${phoneWithCountryCode}`, '_system')
			}
		}
		setIsOpen(false)
	}

	const handleCopyToClipboard = async (e: React.MouseEvent<HTMLAnchorElement>) => {
		e.preventDefault()
		await Clipboard.write({
			string: formattedPhone
		})
		setIsOpen(false)
	}

	const menuItems = useMemo(() => {
		return [
			{
				label: t('transfer_details.phone_modal.copy_number'),
				href: '#',
				icon: <CopyIcon />,
				onClick: handleCopyToClipboard
			},
			{
				label: t('transfer_details.phone_modal.call'),
				href: `tel:${phone}`,
				icon: <PhoneIcon />,
				onClick: handleCall
			},
			{
				label: t('transfer_details.phone_modal.send_sms'),
				href: `sms:${phone}`,
				icon: <SMSIcon />,
				onClick: handleSMS
			},
			{
				label: t('transfer_details.phone_modal.send_whatsapp'),
				href: '#',
				icon: <WhatsappIcon />,
				onClick: handleWhatsApp
			}
		]
	}, [phone])

	const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.target === e.currentTarget) {
			e.stopPropagation()
			setIsOpen(false)
		}
	}

	return (
		<>
			<div
				className={`three-dots-modal-container ${isOpen ? 'show' : ''}`}
				onClick={handleOutsideClick}>
				<div
					className={`three-dots-modal-content phone-card-modal-content ${
						isOpen ? 'open' : emptyString
					}`}
					onClick={(e) => e.stopPropagation()}>
					{menuItems.map((item, index) => (
						<Link to={item.href} className='menu-item' key={index} onClick={item?.onClick}>
							{item?.icon} {item.label}
						</Link>
					))}
				</div>
			</div>
		</>
	)
}

export default TransferDetailsPhoneModal
