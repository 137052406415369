import {
	DragDropContext,
	Draggable,
	DraggableProvided,
	DropResult,
	Droppable
} from '@hello-pangea/dnd'
import { ListIcon } from 'assets/svgs'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import ListItem from 'components/elements/List/ListItem/ListItem'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './DragAndDropColumnList.css'

export interface ColumnItem {
	id: string
	key: string
	label: string
	selected: boolean
}

const defaultColumns: ColumnItem[] = [
	{ id: '0', key: 'date', label: 'Día', selected: false },
	{ id: '1', key: 'time', label: 'Hora', selected: false },
	{ id: '2', key: 'originAddress', label: 'Origen', selected: false },
	{ id: '3', key: 'destinationAddress', label: 'Destino', selected: false },
	{ id: '4', key: 'provider', label: 'Proveedor', selected: false },
	{ id: '5', key: 'driver', label: 'Conductor', selected: false },
	{ id: '6', key: 'route', label: 'Tipo de trayecto', selected: false },
	{ id: '7', key: 'plate', label: 'Matrícula', selected: false },
	{ id: '8', key: 'clientName', label: 'Nombre Cliente', selected: false },
	{ id: '9', key: 'passengers', label: 'Personas', selected: false },
	{ id: '10', key: 'price', label: 'Precio', selected: false },
	{ id: '11', key: 'isPaid', label: 'Pagado', selected: false },
	{ id: '12', key: 'toll', label: 'Peaje', selected: false },
	// { id: '13', key: 'paymentMethod', label: 'Forma de pago', selected: false },
	{ id: '14', key: 'commission', label: 'Comisión', selected: false },
	// { id: '15', key: 'otherCommission', label: 'Comisión otro', selected: false },
	// { id: '16', key: 'driverPrice', label: 'Precio chofer', selected: false },
	// { id: '17', key: 'invoiced', label: 'Facturado', selected: false },
	{ id: '18', key: 'observations', label: 'Observaciones', selected: false },
	// { id: '19', key: 'privateObservations', label: 'Observaciones privadas', selected: false },
	{ id: '20', key: 'phone', label: 'Teléfono', selected: false },
	{ id: '21', key: 'email', label: 'Email', selected: false },
	{ id: '22', key: 'flight', label: 'Vuelo', selected: false }
]

interface Props {
	onSort: (items: ColumnItem[]) => void
}

const DragAndDropColumnList: React.FC<Props> = (props) => {
	const { onSort } = props

	const [columns, setColumns] = useState<ColumnItem[]>(defaultColumns)
	const [allSelected, setAllSelected] = useState(false)

	const { t } = useTranslation()

	const onDragEnd = (result: DropResult) => {
		if (!result.destination) return

		const newColumns = Array.from(columns)
		const [removed] = newColumns.splice(result.source.index, 1)
		newColumns.splice(result.destination.index, 0, removed)
		setColumns(newColumns)
	}

	const onSelect = (id: string) => {
		const newColumns = Array.from(columns)
		const columnIndex = newColumns.findIndex((column) => column.id === id)
		newColumns[columnIndex].selected = !newColumns[columnIndex].selected
		setColumns(newColumns)
	}

	const onSelectAll = () => {
		const newColumns = Array.from(columns)
		const allSelected = newColumns.every((column) => column.selected)
		newColumns.forEach((item) => (item.selected = !allSelected))
		setColumns(newColumns)
	}

	useEffect(() => {
		onSort(columns)

		const areAllSelected = columns.every((column) => column.selected)
		if (areAllSelected) setAllSelected(true)
		else setAllSelected(false)
	}, [columns])

	return (
		<div className='drag-and-drop-list'>
			<div className='drag-and-drop-list-header-container'>
				<header className='drag-and-drop-list-header'>
					<h3>{t('historical.export_modal.columns')}</h3>
					<CheckboxInput
						id='generate-document-show-all'
						label={t('historical.export_modal.show_all')}
						onChange={onSelectAll}
						checked={allSelected}
					/>
				</header>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							{columns.map((column, index) => (
								<Draggable key={column.id} draggableId={column.id} index={index}>
									{(provided: DraggableProvided) => (
										<div
											className='drag-and-drop-list-item'
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}>
											<ListItem right={<ListIcon />}>
												<CheckboxInput
													id={column.id}
													label={column.label}
													checked={column.selected}
													onChange={() => onSelect(column.id)}
												/>
											</ListItem>
										</div>
									)}
								</Draggable>
							))}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

export default DragAndDropColumnList
