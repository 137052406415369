import { MagnifyingGlassIcon, SteeringWheel, UserList } from 'assets/svgs'
import { NonAdminRoleKeys } from 'models/User'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import './SelectRoleCard.css'

interface Props {
	type: NonAdminRoleKeys
	onClick: () => void
}

const SelectRoleCard: FC<Props> = (props) => {
	const { type, onClick } = props
	const { t } = useTranslation()

	const icon = {
		provider: <UserList width={25} height={25} />,
		driver: <SteeringWheel width={25} height={25} />,
		revisor: <MagnifyingGlassIcon width={25} height={25} />
	}

	return (
		<button className='select-role-card' onClick={onClick}>
			<header className='select-role-card-header'>
				<div className={`select-role-card-header-icon ${type}`}>{icon[type]}</div>
				<span className='select-role-card-header-title'>{t(`user_roles.${type}.name`)}</span>
			</header>
			<p className='select-role-card-description'>{t(`user_roles.${type}.description`)}</p>
		</button>
	)
}

export default SelectRoleCard
