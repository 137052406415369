export interface Provider {
	id: number
	companyId: number
	name: string
	email: string
	fiscalName: string
	document: string
	documentType: keyof typeof DocumentType
	address: string
	postalCode: string
	city: string
	province: string
	logo: string
	isActive: boolean
	isManualInvoice: boolean
	isExpense: boolean
	isCrane: boolean
	orderNumber: number
	registrationDate: Date
	isVisible: boolean
	isInternational: boolean
}

export interface DocumentTypesClass {
	id: number
	name: keyof typeof DocumentType
}

export enum DocumentType {
	DNI = 'DNI',
	NIE = 'NIE',
	CIF = 'CIF',
	NIF = 'NIF'
}

export interface ProviderForm {
	name: string
	// email: string
	fiscalName: string
	document: string
	documentTypeId: number | null
	address: string
	postalCode: string
	cityId: number | null
	provinceId: number | null
	// logo: string
	isActive?: boolean
	// isInvoice: boolean
	// isCrane: boolean
	isInternational: boolean
}

export interface ProviderPost {
	name: string
	fiscalName: string
	document: string
	documentType: keyof typeof DocumentType
	address: string
	postalCode: string
	province: string
	city: string
	isActive: boolean
	isInternational: boolean
}

export interface ProviderAssociations {
	id: number
	userId: number
	providerId: number
	userTypeId: number
	companyId: number
	name: string
	surname: string
	email: string
	isVisible: boolean
}

export interface UserTypeProviderRegisterPost {
	email: string
}
