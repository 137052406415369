import ButtonComponent from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import InputDate from 'components/elements/Input/InputDate'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import { BILLING_PDF_URL } from 'constants/routes'
import { FormikProps, useFormik } from 'formik'
import useBilling from 'hooks/useBilling'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import useToast from 'hooks/useToast'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { CreateManualInvoicePayload, Line } from 'models/Billing'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { emptyString } from 'utils/common'
import { dateToSQL } from 'utils/dateUtils'
import { getFormikError } from 'utils/formikUtils'
import * as Yup from 'yup'
import InvoiceConcepts from './InvoiceConcepts'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
}

const ManualInvoiceModal = ({ isVisible, toggleVisibility }: Props) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const [concepts, setConcepts] = useState<Line[]>([])
	const [isSubmitting, setIsSubmitting] = useState(false)
	const { createNewInvoice, creatNewInvoiceLine } = useBilling()
	const { showBackendErrorToast, showErrorToast } = useToast()

	const formik = useFormik<CreateManualInvoicePayload>({
		initialValues: {
			numberFormat: emptyString,
			date: emptyString,
			clientName: emptyString,
			clientDocument: emptyString,
			clientAddress: emptyString,
			clientPostalCode: emptyString,
			clientCity: emptyString,
			clientProvince: emptyString
		},
		validationSchema: Yup.object().shape({
			numberFormat: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.invoice_number') })
			),
			date: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.invoice_date') })
			),
			clientName: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_name') })
			),
			clientDocument: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_document') })
			),
			clientAddress: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_address') })
			),
			clientPostalCode: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_post_code') })
			),
			clientCity: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_municipality') })
			),
			clientProvince: Yup.string().required(
				t('errors.required_m', { field: t('settings.billing.client_province') })
			)
		}),
		onSubmit: async (values) => {
			try {
				if (concepts.length === 0) {
					showErrorToast({
						description: t('settings.billing.no_concepts_error'),
						duration: 2000
					})
					return
				}
				for (const concept of concepts) {
					if (!concept.concept || concept.price <= 0 || concept.quantity <= 0 || concept.vat <= 0) {
						showErrorToast({
							description: t('settings.billing.no_concepts_incomplete_error'),
							duration: 2000
						})
						return
					}
				}
				setIsSubmitting(true)
				const newInvoice = await createNewInvoice({
					...values,
					date: dateToSQL(new Date(values.date))
				})
				await creatNewInvoiceLine({
					invoiceId: newInvoice.id,
					lines: concepts
				})
				formik.resetForm()
				setConcepts([])
				navigate(BILLING_PDF_URL + `/${newInvoice.id}`)
			} catch (error) {
				showBackendErrorToast(error)
			} finally {
				setIsSubmitting(false)
			}
		}
	})

	const createConcept = () => {
		setConcepts([
			...concepts,
			{
				concept: emptyString,
				quantity: 0,
				price: 0,
				vat: 0
			}
		])
	}

	const updateConcept = (index: number, field: keyof Line, value: string | number) => {
		const newConcepts = [...concepts]
		newConcepts[index] = {
			...newConcepts[index],
			[field]: value
		}
		setConcepts(newConcepts)
	}

	const removeConcept = (index: number) => {
		setConcepts(concepts.filter((_, i) => i !== index))
	}

	const handleSubmit = async (e: React.MouseEvent<Element, MouseEvent>) => {
		e.stopPropagation()
		await formik.submitForm()
		focusFirstError()
	}

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	return (
		<ModalFormLayout
			noKeyDown={true}
			isVisible={isVisible}
			toggleVisibility={() => {
				formik.resetForm()
				toggleVisibility()
			}}
			title={t('settings.billing.create_manually_title')}
			leftButton={
				<ButtonComponent onClick={toggleVisibility} variant='only-text'>
					<strong>{t('general.return')}</strong>
				</ButtonComponent>
			}
			footer={
				<ButtonComponent fullWidth disabled={isSubmitting} variant='primary' onClick={handleSubmit}>
					{t('settings.billing.create_invoice')}
				</ButtonComponent>
			}>
			<FormSectionLayout>
				<Input
					id='numberFormat'
					label={t('settings.billing.invoice_number')}
					placeholder={t('settings.billing.invoice_number')}
					error={getFormikError(formik, 'numberFormat')}
					valueSelected={formik.values.numberFormat}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('numberFormat') : undefined}
				/>
				<InputDate
					value={undefined}
					id='date'
					label={t('settings.billing.invoice_date')}
					onSelect={(date) => formik.setFieldValue('date', date)}
					error={getFormikError(formik as FormikProps<CreateManualInvoicePayload>, 'date')}
					ref={setFieldRef ? setFieldRef('date') : undefined}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('settings.billing.client_data')}>
				<Input
					id='clientName'
					label={t('settings.billing.client_name')}
					placeholder={t('settings.billing.client_name')}
					error={getFormikError(formik as FormikProps<CreateManualInvoicePayload>, 'clientName')}
					valueSelected={formik.values['clientName']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientName') : undefined}
				/>
				<Input
					id='clientDocument'
					label={t('settings.billing.client_document')}
					placeholder={t('settings.billing.client_document')}
					error={getFormikError(
						formik as FormikProps<CreateManualInvoicePayload>,
						'clientDocument'
					)}
					valueSelected={formik.values['clientDocument']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientDocument') : undefined}
				/>
				<Input
					id='clientAddress'
					label={t('settings.billing.client_address')}
					placeholder={t('settings.billing.client_address')}
					error={getFormikError(formik as FormikProps<CreateManualInvoicePayload>, 'clientAddress')}
					valueSelected={formik.values['clientAddress']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientAddress') : undefined}
				/>
				<Input
					id='clientPostalCode'
					label={t('settings.billing.client_post_code')}
					placeholder={t('settings.billing.client_post_code')}
					error={getFormikError(
						formik as FormikProps<CreateManualInvoicePayload>,
						'clientPostalCode'
					)}
					valueSelected={formik.values['clientPostalCode']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientPostalCode') : undefined}
				/>
				<Input
					id='clientCity'
					label={t('settings.billing.client_municipality')}
					placeholder={t('settings.billing.client_municipality')}
					error={getFormikError(formik as FormikProps<CreateManualInvoicePayload>, 'clientCity')}
					valueSelected={formik.values['clientCity']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientCity') : undefined}
				/>
				<Input
					id='clientProvince'
					label={t('settings.billing.client_province')}
					placeholder={t('settings.billing.client_province')}
					error={getFormikError(
						formik as FormikProps<CreateManualInvoicePayload>,
						'clientProvince'
					)}
					valueSelected={formik.values['clientProvince']}
					onChange={formik.handleChange}
					ref={setFieldRef ? setFieldRef('clientProvince') : undefined}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('settings.billing.amounts')}>
				<InvoiceConcepts
					concepts={concepts}
					createConcept={createConcept}
					updateConcept={updateConcept}
					removeConcept={removeConcept}
				/>
			</FormSectionLayout>
		</ModalFormLayout>
	)
}

export default ManualInvoiceModal
