import { CloseIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import Input from 'components/elements/Input/Input'
import SelectRoleOptions from 'components/elements/SelectOptions/SelectRoleOptions/SelectRoleOptions'
import { USERS_URL } from 'constants/routes'
import { useFormik } from 'formik'
import useDriver from 'hooks/useDriver'
import useFormikErrorFocus from 'hooks/useFormikErrorFocus'
import { useModal } from 'hooks/useModal'
import useProvider from 'hooks/useProvider'
import useToast from 'hooks/useToast'
import useUsers from 'hooks/useUser'
import FormLayout from 'layouts/FormLayout/FormLayout'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { NonAdminRoleKeys, UserPost, UserRoles } from 'models/User'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { emptyString } from 'utils/common'
import { getFormikError } from 'utils/formikUtils'
import * as Yup from 'yup'
import UserNewAssociateDriverModal from './UserNewAssociateDriverModal'
import UserNewAssociateProviderModal from './UserNewAssociateProviderModal'

const UserNew: FC = () => {
	const { createUser } = useUsers()

	const { createAssociationsByUserId: createProviderAssociationsByUserId } = useProvider()
	const { createAssociationsByUserId: createDriverAssociationsByUserId } = useDriver()

	const { t } = useTranslation()
	const navigate = useNavigate()
	const [isAssociationProviderModalVisible, associationProviderModalVisibility] = useModal()
	const [associationsSelected, setAssociationsSelected] = useState<number[]>([])
	const [roleSelected, setRoleSelected] = useState<NonAdminRoleKeys>()
	const { showBackendErrorToast } = useToast()

	const formik = useFormik<UserPost>({
		initialValues: {
			name: emptyString,
			lastName: emptyString,
			username: emptyString,
			password: emptyString,
			email: emptyString,
			userTypeId: null
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required(t('errors.required_m', { field: t('user.name') })),
			lastName: Yup.string().required(t('errors.required_m', { field: t('user.lastName') })),
			password: Yup.string().required(t('errors.required_m', { field: t('iam.password') })),
			email: Yup.string()
				.required(t('errors.required_m', { field: t('user.email') }))
				.email(t('errors.mail_not_valid')),
			userTypeId: Yup.string().required(t('errors.required_m', { field: t('user.role') }))
		}),
		onSubmit: async (values) => {
			try {
				const userCreated = await createUser({
					...values,
					username: values.email
				})
				if (values.userTypeId === UserRoles.provider && associationsSelected.length) {
					await createProviderAssociationsByUserId(userCreated.id, associationsSelected)
				}
				if (values.userTypeId === UserRoles.driver && associationsSelected.length) {
					await createDriverAssociationsByUserId(userCreated.id, associationsSelected)
				}

				toast.success(t('user.success_create'))

				navigate(USERS_URL)
			} catch (err) {
				showBackendErrorToast(err)
			}
		}
	})

	const { setFieldRef, focusFirstError } = useFormikErrorFocus(formik.errors)

	const handleSubmit = async () => {
		const errors = await formik.validateForm()
		if (Object.keys(errors).length) {
			return await formik.submitForm()
		}

		if (roleSelected !== UserRoles.revisor) {
			associationProviderModalVisibility()
		} else {
			await formik.submitForm()
			focusFirstError()
		}
	}

	return (
		<FormLayout
			title={t('user.new_title')}
			rightButton={
				<ButtonComponent variant='only-icon' onClick={() => navigate(USERS_URL)}>
					<CloseIcon />
				</ButtonComponent>
			}
			footer={
				<ButtonComponent type='submit' variant='primary' fullWidth onClick={handleSubmit}>
					{roleSelected !== UserRoles.revisor
						? t('general.continue')
						: t('user.save_and_create_user')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('user.user_information')}>
				<Input
					ref={setFieldRef('name')}
					id='name'
					label={t('user.name')}
					placeholder={t('user.name')}
					valueSelected={formik.values['name']}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'name')}
				/>
				<Input
					ref={setFieldRef('lastName')}
					id='lastName'
					label={t('user.lastName')}
					placeholder={t('user.lastName')}
					valueSelected={formik.values['lastName']}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'lastName')}
				/>
				<Input
					ref={setFieldRef('email')}
					type='email'
					id='email'
					label={t('user.email')}
					placeholder={t('user.email')}
					valueSelected={formik.values['email']}
					onChange={formik.handleChange}
					error={getFormikError(formik, 'email')}
				/>
				<Input
					ref={setFieldRef('password')}
					id='password'
					type='password'
					label={t('iam.password')}
					placeholder={t('iam.change_password_description')}
					onChange={formik.handleChange}
					valueSelected={formik.values['password']}
					error={getFormikError(formik, 'password')}
				/>
			</FormSectionLayout>
			<FormSectionLayout title={t('user.role')}>
				<SelectRoleOptions
					ref={setFieldRef('userTypeId')}
					id='userTypeId'
					handleRoleSelected={(role) => {
						setRoleSelected(role)
						formik.setFieldValue('userTypeId', role)
					}}
					selectedId={formik.values['userTypeId']}
					error={getFormikError(formik, 'userTypeId')}
				/>
			</FormSectionLayout>
			{roleSelected === UserRoles.provider ? (
				<UserNewAssociateProviderModal
					isVisible={isAssociationProviderModalVisible}
					toggleVisibility={associationProviderModalVisibility}
					handleSelection={(selected) => setAssociationsSelected(selected.map((s) => s.id))}
					onSave={() => formik.submitForm()}
				/>
			) : roleSelected === UserRoles.driver ? (
				<UserNewAssociateDriverModal
					isVisible={isAssociationProviderModalVisible}
					toggleVisibility={associationProviderModalVisibility}
					handleSelection={(selected) => setAssociationsSelected(selected.map((s) => s.id))}
					onSave={() => formik.submitForm()}
				/>
			) : null}
		</FormLayout>
	)
}

export default UserNew
