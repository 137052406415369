import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { notificationsQueryKey, unReadNotificationsQueryKey } from 'constants/reactQueryKeys'
import { AppNotification } from 'models/Notification'
import { UserRoles } from 'models/User'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	getNotifications,
	getUnReadNotifications,
	markNotificationAsRead
} from 'services/notifications'
import { useUserStore } from 'stores/useUserStore'

const useNotifications = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const [fetchNotifications, setFetchNotifications] = useState(false)
	const { currentUser } = useUserStore()

	const {
		data: notifications,
		isLoading: isLoadingNotifications,
		error: notificationError,
		isError: isErrorNotifications
	} = useQuery({
		queryKey: [notificationsQueryKey],
		queryFn: getNotifications,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true,
		enabled: fetchNotifications
	})

	const {
		data: unReadNotifications,
		isLoading: isLoadingUnReadNotifications,
		error: unReadNotificationError,
		isError: isErrorUnReadNotifications
	} = useQuery({
		queryKey: [unReadNotificationsQueryKey],
		queryFn: getUnReadNotifications,
		staleTime: 1000 * 60 * 1,
		refetchOnWindowFocus: true,
		enabled:
			currentUser.userTypeId === UserRoles['super-admin'] ||
			currentUser.userTypeId === UserRoles.admin
	})

	useEffect(() => {
		if (isErrorNotifications && notificationError) {
			setErrorMessage(t('notifications.error_load_notifications'))
		}

		if (isErrorUnReadNotifications && unReadNotificationError) {
			setErrorMessage(t('notifications.error_load_unread_notifications'))
		}
	}, [
		isErrorNotifications,
		notificationError,
		isErrorUnReadNotifications,
		unReadNotificationError,
		t
	])

	//UPDATE NOTIFICATION
	const { mutateAsync: markAsRead } = useMutation({
		mutationFn: (notificationId: AppNotification['id']) => markNotificationAsRead(notificationId),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [notificationsQueryKey]
			})
			queryClient.invalidateQueries({
				queryKey: [unReadNotificationsQueryKey]
			})
		},
		onError: () => {
			setErrorMessage(t('notifications.error_mark_as_read'))
		}
	})

	const refetchNotifications = () => {
		setFetchNotifications(true)
	}

	return {
		notifications: notifications || [],
		unReadNotifications: unReadNotifications || [],
		loading: isLoadingNotifications || isLoadingUnReadNotifications,
		error: errorMessage,
		markAsRead,
		refetchNotifications
	}
}

export default useNotifications
