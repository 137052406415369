import AssociationList, {
	AssociationOption
} from 'components/elements/AssociationList/AssociationList'
import ButtonComponent from 'components/elements/Button/Button'
import ModalFormLayout from 'components/elements/Modal/ModalFormLayout/ModalFormLayout'
import { PROVIDERS_NEW_URL } from 'constants/routes'
import useProviders from 'hooks/useProviders'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
	isVisible: boolean
	toggleVisibility: () => void
	handleSelection: (selected: AssociationOption[]) => void
	onSave: () => void
}

const UserNewAssociateProviderModal: FC<Props> = (props) => {
	const { isVisible, toggleVisibility, handleSelection, onSave } = props
	const { providers } = useProviders()
	const [associationsOptions, setAssociationsOptions] = useState<AssociationOption[]>([])
	const [isLoading, setIsLoading] = useState(true)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const formatAssociations = (): AssociationOption[] => {
		return providers
			.filter((provider) => provider.isManualInvoice === false)
			.map((provider) => {
				return {
					id: provider.id,
					name: provider.name,
					isSelected: false
				}
			})
	}

	useEffect(() => {
		setIsLoading(true)
		const associations = formatAssociations()
		setAssociationsOptions(associations)
		setIsLoading(false)
	}, [providers])

	const handleSelectProviders = (selected: AssociationOption[]) => {
		const providersSelected = selected.filter((provider) => provider.isSelected)
		handleSelection(providersSelected)
	}

	return (
		<ModalFormLayout
			isVisible={isVisible}
			toggleVisibility={toggleVisibility}
			title={t('user.new_title')}
			footer={
				<ButtonComponent variant='primary' fullWidth onClick={onSave}>
					{t('user.save_and_create_user')}
				</ButtonComponent>
			}>
			<FormSectionLayout title={t('provider_association.association_list_title')}>
				<AssociationList
					isLoading={isLoading}
					associations={associationsOptions}
					title={t('provider_association.association_list_title')}
					addButtonText={t('provider_association.association_list_button')}
					modalContent={{
						title: t('provider_association.association_list_modal_title'),
						subtitle: t('provider_association.association_list_modal_subtitle'),
						description: t('provider_association.association_list_modal_description')
					}}
					isMultiple={true}
					handleSelection={handleSelectProviders}
				/>
				<ButtonComponent onClick={() => navigate(PROVIDERS_NEW_URL)} variant='only-text'>
					+ {t('provider_association.association_list_modal_add_provider')}
				</ButtonComponent>
			</FormSectionLayout>
		</ModalFormLayout>
	)
}

export default UserNewAssociateProviderModal
