import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
	companyActiveQueryKey,
	companyLogoQueryKey,
	companyQueryKey
} from 'constants/reactQueryKeys'
import { CompanyForm } from 'models/Company'
import { UserRoles } from 'models/User'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	checkCompanyActiveService,
	getCompanyService,
	getLogoCompanyService,
	updateCompanyService
} from 'services/company'
import { useUserStore } from 'stores/useUserStore'

const useCompany = () => {
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const { currentUser } = useUserStore()

	const {
		data: company,
		isLoading,
		error: companyError
	} = useQuery({
		queryKey: [companyQueryKey],
		queryFn: getCompanyService,
		staleTime: 1000 * 60 * 5,
		refetchOnWindowFocus: true
	})

	const { data: isActive, refetch: refetchActive } = useQuery({
		queryKey: [companyActiveQueryKey],
		queryFn: checkCompanyActiveService,
		staleTime: 1000 * 60 * 1,
		refetchOnWindowFocus: true
	})

	const { data: logo, isLoading: loadingLogo } = useQuery({
		queryKey: [companyLogoQueryKey],
		queryFn: getLogoCompanyService,
		enabled:
			currentUser.userTypeId === UserRoles['super-admin'] ||
			currentUser.userTypeId === UserRoles.admin
	})

	const { mutateAsync: updateCompany } = useMutation({
		mutationFn: (data: { company: Partial<CompanyForm> }) => updateCompanyService(data.company),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [companyQueryKey]
			})
		},
		onError: (error: unknown) => {
			setErrorMessage(t('driver.error_update'))
			console.error('Error actualizando conductor:', error)
		}
	})

	const clearQuery = () => {
		queryClient.invalidateQueries({
			queryKey: [companyQueryKey]
		})
	}

	return {
		company,
		logo: logo ?? null,
		loading: isLoading,
		loadingLogo,
		error: companyError?.message || errorMessage,
		updateCompany,
		isActive: isActive === undefined ? true : isActive,
		refetchActive,
		clearQuery
	}
}

export default useCompany
