import { EndTransfer, StartTransfer } from 'assets/svgs'
import colors from 'constants/colors'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './CategoryTag.css'

interface Props {
	hasStart: boolean
	hasEnd: boolean
	small?: boolean
}

const StartEndCategoryTag: React.FC<Props> = (props) => {
	const { hasStart, hasEnd, small = false } = props
	const { t } = useTranslation()

	useEffect(() => {
		getText()
	}, [hasStart, hasEnd])

	const getText = () => {
		if (!hasStart && !hasEnd) {
			return emptyString
		}

		if (hasStart && !hasEnd) {
			return t('transfer.started')
		} else if (hasEnd) {
			return t('transfer.finished')
		}
	}

	const getBackgroundColor = () => {
		if (!hasStart && !hasEnd) {
			return emptyString
		}

		if (hasStart && !hasEnd) {
			return colors.hasStartBackground
		} else if (hasEnd) {
			return colors.hasEndBackground
		}
	}

	const getBorderColor = () => {
		if (!hasStart && !hasEnd) {
			return emptyString
		}

		if (hasStart && !hasEnd) {
			return colors.hasStartBorder
		} else if (hasEnd) {
			return colors.hasEndBorder
		}
	}

	if (!hasStart && !hasEnd) {
		return null
	}

	return (
		<div
			className='start-end-category-tag'
			style={{ backgroundColor: getBackgroundColor(), borderColor: getBorderColor() }}>
			{hasStart && !hasEnd && <StartTransfer />}
			{hasEnd && <EndTransfer />}

			{!small && <span>{getText()}</span>}
		</div>
	)
}

export default StartEndCategoryTag
