import { ENVIRONMENT } from 'constants/environment'
import { secondaryTransferColors, transferColors } from 'constants/transfer'
import {
	ConfigurationPaymentMethodsResponse,
	DocumentType,
	DocumentTypesClass,
	Municipality,
	Province,
	Role,
	RouteType,
	RouteTypeName,
	VehicleClass,
	VehicleClassType
} from 'models/index'
import { normalize } from 'normalizr'
import {
	documentTypesSchema,
	municipalitiesSchema,
	provinceSchema,
	roleSchema,
	vehicleClassSchema
} from 'schemas/index'
import { paymentMethodsSchema } from 'schemas/paymentMethosSchema'
import { routeTypesSchema } from 'schemas/routeTypesSchema'
import {
	getAllMunicipalities,
	getAllProvinces,
	getConfigService,
	getDocumentTypes,
	getPaymentMethods,
	getRolesService,
	getRouteTypesService,
	getVehicleClasses
} from 'services/config'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'
import { capacitorStorage } from './capacitorStorage'

interface State {
	colors: {
		departure: string
		arrival: string
		transfer: string
		disposition: string
		crane: string
	}
	secondaryColors: {
		departure: string
		arrival: string
		transfer: string
		disposition: string
		crane: string
	}
	provinces: {
		byId: { [id: number]: Province }
		allIds: number[]
	}
	municipalities: {
		byId: { [id: number]: Municipality }
		allIds: number[]
	}
	vehicleClasses: {
		byId: { [id: number]: VehicleClassType }
		allIds: number[]
	}
	documentTypes: {
		byId: { [id: number]: DocumentTypesClass }
		allIds: number[]
	}
	roles: {
		byId: { [id: string]: Role }
		allIds: string[]
	}
	routeTypes: {
		byId: { [id: string]: RouteType }
		allIds: string[]
	}
	paymentMethods: {
		byId: { [id: number]: Province }
		allIds: number[]
	}
	fetchConfig: () => void
	fetchAllProvinces: () => Promise<Province[]>
	fetchAllMunicipalities: () => Promise<Municipality[]>
	fetchDocumentTypes: () => Promise<void>
	fetchAllVehicleClasses: () => Promise<void>
	fetchAllRoles: () => Promise<void>
	fetchAllRouteTypes: () => Promise<void>
	fetchPaymentMethods: () => Promise<ConfigurationPaymentMethodsResponse[]>
	getProvinceByCode: (provinceCode: string) => Province
	getMunicipalityByProvinceCode: (provinceCode: string) => Municipality[]
	getMunicipalityByProvinceCodeAndMunicipalityCode: (
		provinceCode: string,
		municipalityCode: string
	) => Municipality
	getVehicleClassByClass: (className: keyof typeof VehicleClass) => VehicleClassType
	getDocumentTypeByClass: (className: keyof typeof DocumentType) => DocumentTypesClass
	getRouteTypeByName: (name: keyof typeof RouteTypeName) => RouteType
	resetAllConfig: () => void
}

export const useConfigStore = create<State>()(
	devtools(
		persist(
			(set, get) => ({
				colors: {
					departure: transferColors.departure,
					arrival: transferColors.arrival,
					transfer: transferColors.transfer,
					disposition: transferColors.disposition,
					crane: transferColors.crane
				},
				secondaryColors: {
					departure: secondaryTransferColors.departure,
					arrival: secondaryTransferColors.arrival,
					transfer: secondaryTransferColors.transfer,
					disposition: secondaryTransferColors.disposition,
					crane: secondaryTransferColors.crane
				},
				provinces: {
					byId: {},
					allIds: []
				},
				municipalities: {
					byId: {},
					allIds: []
				},
				vehicleClasses: {
					byId: {},
					allIds: []
				},
				documentTypes: {
					byId: {},
					allIds: []
				},
				roles: {
					byId: {},
					allIds: []
				},
				routeTypes: {
					byId: {},
					allIds: []
				},
				paymentMethods: {
					byId: {},
					allIds: []
				},
				fetchConfig: async () => {
					const { data } = await getConfigService()
					set((state) => ({
						...state,
						colors: data.colors,
						secondaryColors: data.secondaryColors
					}))
				},
				fetchAllProvinces: async () => {
					const localData = await capacitorStorage.getItem('provinces')

					if (!!localData?.allIds?.length) {
						set(() => ({
							provinces: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return get().provinces.allIds.map((id) => localData.byId[id])
					}

					const provinces = await getAllProvinces()
					const normalizedData = normalize(provinces, [provinceSchema])

					set((state) => ({
						...state,
						provinces: {
							byId: {
								...state.provinces.byId,
								...normalizedData.entities.provinces
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('provinces', get().provinces)

					return provinces
				},
				fetchAllMunicipalities: async () => {
					const localData = await capacitorStorage.getItem('municipalities')

					if (!!localData?.allIds?.length) {
						set(() => ({
							municipalities: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return get().municipalities.allIds.map((id) => localData.byId[id])
					}

					const municipalities = await getAllMunicipalities()
					const normalizedData = normalize(municipalities, [municipalitiesSchema])

					set((state) => ({
						...state,
						municipalities: {
							byId: {
								...state.municipalities.byId,
								...normalizedData.entities.municipalities
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('municipalities', get().municipalities)

					return municipalities
				},
				fetchDocumentTypes: async () => {
					const localData = await capacitorStorage.getItem('documentTypes')

					if (localData && localData.allIds?.length) {
						set(() => ({
							documentTypes: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return
					}

					const documentTypes = await getDocumentTypes()
					const normalizedData = normalize(documentTypes, [documentTypesSchema])

					set((state) => ({
						...state,
						documentTypes: {
							byId: {
								...state.documentTypes.byId,
								...normalizedData.entities.documentTypes
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('documentTypes', get().documentTypes)
				},
				fetchAllVehicleClasses: async () => {
					const localData = await capacitorStorage.getItem('vehicleClasses')

					if (localData && localData.allIds?.length) {
						set(() => ({
							vehicleClasses: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return
					}

					const vehicleClasses = await getVehicleClasses()
					const normalizedData = normalize(vehicleClasses, [vehicleClassSchema])

					set((state) => ({
						...state,

						vehicleClasses: {
							byId: {
								...state.vehicleClasses.byId,
								...normalizedData.entities.vehicleClasses
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('vehicleClasses', get().vehicleClasses)
				},
				fetchAllRoles: async () => {
					const localData = await capacitorStorage.getItem('roles')

					if (localData && localData.allIds?.length) {
						set(() => ({
							roles: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return
					}

					const roles = await getRolesService()
					const normalizedData = normalize(roles, [roleSchema])

					set((state) => ({
						...state,
						roles: {
							byId: {
								...state.roles.byId,
								...normalizedData.entities.roles
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('roles', get().roles)
				},
				fetchAllRouteTypes: async () => {
					const localData = await capacitorStorage.getItem('routeTypes')

					if (localData && localData.allIds?.length) {
						set(() => ({
							routeTypes: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))
						return
					}

					const routeTypes = await getRouteTypesService()
					const normalizedData = normalize(routeTypes, [routeTypesSchema])

					set((state) => ({
						...state,
						routeTypes: {
							byId: {
								...state.routeTypes.byId,
								...normalizedData.entities.routeTypes
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('routeTypes', get().routeTypes)
				},
				fetchPaymentMethods: async () => {
					const localData = await capacitorStorage.getItem('paymentMethods')

					if (!!localData?.allIds?.length) {
						set(() => ({
							paymentMethods: {
								byId: localData.byId,
								allIds: localData.allIds
							}
						}))

						return get().paymentMethods.allIds.map((id) => localData.byId[id])
					}

					const paymentMethods = await getPaymentMethods()
					const normalizedData = normalize(paymentMethods, [paymentMethodsSchema])

					set((state) => ({
						...state,
						paymentMethods: {
							byId: {
								...state.paymentMethods.byId,
								...normalizedData.entities.paymentMethods
							},
							allIds: normalizedData.result
						}
					}))

					await capacitorStorage.setItem('paymentMethods', get().paymentMethods)
					return paymentMethods
				},
				getProvinceByCode: (provinceCode: string) => {
					const state = get()
					const provinceIdsFiltered = state.provinces.allIds.filter(
						(id) => state.provinces.byId[id].code === provinceCode
					)[0]
					return state.provinces.byId[provinceIdsFiltered]
				},
				getMunicipalityByProvinceCode: (provinceCode: string) => {
					const state = get()
					const municipalityIdsFiltered = state.municipalities.allIds.filter(
						(id) => state.municipalities.byId[id].provinceCode === provinceCode
					)
					return municipalityIdsFiltered.map((id) => state.municipalities.byId[id])
				},
				getMunicipalityByProvinceCodeAndMunicipalityCode: (
					provinceCode: string,
					municipalityCode: string
				) => {
					const state = get()
					const municipalityIdsFiltered = state.municipalities.allIds.filter(
						(id) =>
							state.municipalities.byId[id].provinceCode === provinceCode &&
							state.municipalities.byId[id].municipalityCode === municipalityCode
					)
					return state.municipalities.byId[municipalityIdsFiltered[0]]
				},
				getVehicleClassByClass: (className: keyof typeof VehicleClass) => {
					const state = get()
					const vehicleClassIdsFiltered = state.vehicleClasses.allIds.filter(
						(id) => state.vehicleClasses.byId[id].name === className
					)
					return vehicleClassIdsFiltered.map((id) => state.vehicleClasses.byId[id])[0]
				},
				getDocumentTypeByClass: (className: keyof typeof DocumentType) => {
					const state = get()
					const documentTypeIdsFiltered = state.documentTypes.allIds.filter(
						(id) => state.documentTypes.byId[id].name === className
					)
					return documentTypeIdsFiltered.map((id) => state.documentTypes.byId[id])[0]
				},
				getRouteTypeByName: (name: keyof typeof RouteTypeName) => {
					const state = get()
					const routeTypeIdsFiltered = state.routeTypes.allIds.filter(
						(id) => state.routeTypes.byId[id].name === name
					)
					return routeTypeIdsFiltered.map((id) => state.routeTypes.byId[id])[0]
				},
				resetAllConfig: async () => {
					set(
						{
							provinces: {
								byId: {},
								allIds: []
							},
							municipalities: {
								byId: {},
								allIds: []
							},
							vehicleClasses: {
								byId: {},
								allIds: []
							},
							documentTypes: {
								byId: {},
								allIds: []
							},
							roles: {
								byId: {},
								allIds: []
							},
							routeTypes: {
								byId: {},
								allIds: []
							},
							paymentMethods: {
								byId: {},
								allIds: []
							}
						},
						false,
						'RESET_ALL_CONFIG'
					)
					localStorage.removeItem('config store')
					await capacitorStorage.removeItem('config store')
					await capacitorStorage.removeItem('provinces')
					await capacitorStorage.removeItem('municipalities')
					await capacitorStorage.removeItem('vehicleClasses')
					await capacitorStorage.removeItem('documentTypes')
					await capacitorStorage.removeItem('roles')
					await capacitorStorage.removeItem('routeTypes')
					await capacitorStorage.removeItem('paymentMethods')
				}
			}),
			{
				name: 'config store',
				storage: createJSONStorage(() => capacitorStorage)
			}
		),
		{
			enabled: ENVIRONMENT === 'development',
			name: 'config store'
		}
	)
)
