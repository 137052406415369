import { CaretCircleLeft, WarningIcon } from 'assets/svgs'
import ButtonComponent from 'components/elements/Button/Button'
import StatusPage from 'components/elements/StatusPage/StatusPage'
import { DAILY_URL } from 'constants/routes'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import './ErrorPage.css'

const ErrorPage: FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { state } = useLocation()

	return (
		<StatusPage
			title={state?.title || 'Error'}
			subtitle={state?.subtitle || ''}
			description={state?.description || t('errors.unexpected_error')}
			button={
				<ButtonComponent
					variant='text'
					onClick={() => navigate(state?.link || DAILY_URL)}
					leftIcon={<CaretCircleLeft />}>
					{t('general.return')}
				</ButtonComponent>
			}
			icon={
				<div className='error-page-icon-container'>
					<WarningIcon />
				</div>
			}
		/>
	)
}

export default ErrorPage
