import { UserFilledIcon } from 'assets/svgs'
import CheckboxInput from 'components/elements/Input/CheckboxInput'
import List from 'components/elements/List/List'
import ListItem from 'components/elements/List/ListItem/ListItem'
import Search from 'components/elements/Search/Search'
import useSearch from 'hooks/useSearch'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { emptyString } from 'utils/common'
import './DropdownSelector.css'

export interface ItemDropdown {
	id: number
	name: string
	subtitle?: string
	hasUser?: boolean
	isSelected?: boolean
	isDisabled?: boolean
}

interface Props {
	items: ItemDropdown[]
	description?: string
	subtitle?: string
	isMultiple?: boolean
	handleClick: (ids: number[]) => void
	selectedItems?: number[]
	setSelectedItems?: (ids: number[]) => void
	searchInputRef?: React.RefObject<HTMLInputElement>
	selected?: number | number[] | null
}

const DropdownSelector: FC<Props> = (props) => {
	const {
		items,
		description,
		subtitle,
		isMultiple = false,
		handleClick,
		selectedItems,
		setSelectedItems,
		searchInputRef,
		selected
	} = props

	const { t } = useTranslation()

	const [search, setSearch] = useState(emptyString)
	const filteredMapInfo = useSearch(items, ['name'], search)

	const isMultipleAllow = isMultiple && selectedItems && setSelectedItems

	const selectDefaultItems = () => {
		if (isMultipleAllow) {
			setSelectedItems(items.filter((item) => !!item.isSelected).map((item) => item.id))
		}
	}

	const onSelectItem = (id: number) => {
		setSearch(emptyString)
		handleClick([id])
	}

	const onSelectItemMultiple = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
		if (!isMultipleAllow) return
		if (e.target.checked) setSelectedItems([...selectedItems, id])
		else setSelectedItems(selectedItems.filter((item) => item !== id))
	}

	const onSelectAllMultiple = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (!isMultipleAllow) return

		if (e.target.checked) return setSelectedItems(items.map((item) => item.id))

		const unselectAllExceptDisabled = items
			.filter((item) => item.isDisabled && item.isSelected)
			.map((item) => item.id)

		setSelectedItems(unselectAllExceptDisabled)
	}

	useEffect(() => {
		selectDefaultItems()
	}, [items])

	return (
		<>
			<div className='dropdown-selector-heading' onClick={(e) => e.stopPropagation()}>
				{description && <p className='dropdown-selector-description body2'>{description}</p>}
				{subtitle && <h2 className='dropdown-selector-subtitle'>{subtitle}</h2>}
				{isMultipleAllow ? (
					<div className='dropdown-selector-search-and-check-all'>
						<Search
							onChange={(event) => setSearch(event.target.value.toLowerCase())}
							inputRef={searchInputRef}
						/>
						<div className='dropdown-selector-check-all'>
							<CheckboxInput
								id='select-all'
								label={t('general.select_all')}
								onChange={onSelectAllMultiple}
								checked={selectedItems.length === items.length}
							/>
						</div>
					</div>
				) : (
					<div className='dropdown-selector-search'>
						<Search
							onChange={(event) => setSearch(event.target.value.toLowerCase())}
							inputRef={searchInputRef}
						/>
					</div>
				)}
			</div>
			<List>
				{filteredMapInfo.map((option) => {
					return (
						<ListItem
							key={option.id}
							onClick={
								!isMultipleAllow && !option.isDisabled ? () => onSelectItem(option.id) : undefined
							}
							isSelectedItem={option.id === selected && !isMultipleAllow}>
							{isMultipleAllow ? (
								<CheckboxInput
									id={option.id.toString()}
									label={option.name}
									labelHelper={option.subtitle}
									onChange={(e) => onSelectItemMultiple(e, option.id)}
									checked={selectedItems.includes(option.id)}
									disabled={option.isDisabled}
								/>
							) : (
								<div className='dropdown-selector-item-wrapper'>
									<div
										className={`dropdown-selector-item-wrapper-content${
											option.isDisabled ? ' disabled' : ''
										}`}>
										<span className='dropdown-selector-item-wrapper-content-name body1'>
											{option.name}
										</span>
										{option.subtitle ? (
											<span className='dropdown-selector-item-wrapper-content-subtitle body1'>
												{option.subtitle}
											</span>
										) : null}
									</div>
									{option?.hasUser && <UserFilledIcon />}
								</div>
							)}
						</ListItem>
					)
				})}
			</List>
		</>
	)
}

export default DropdownSelector
