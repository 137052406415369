import React from 'react'
import './StepNumber.css'

export const enum StepNumberColor {
	VIOLET = 'violet',
	GREEN = 'green',
	GRAY = 'gray'
}

interface Props {
	number: number
	color?: StepNumberColor
	size?: 'small' | 'large'
}

const StepNumber: React.FC<Props> = (props) => {
	const { number, color = StepNumberColor.VIOLET, size = 'small' } = props

	const classNames = () => {
		let classes = 'step-number'
		if (color) classes += ` ${color}`
		if (size) classes += ` ${size}`

		return classes
	}

	return <div className={classNames()}>{number}</div>
}

export default StepNumber
