import {
	BillingFilters,
	BillingResponse,
	CreateInvoiceLinePayload,
	CreateManualInvoicePayload,
	CreateWithReservationInvoicePayload,
	InvoiceDetail
} from 'models/Billing'
import { FetchResponse, FetchResponsePaginated } from 'models/CustomFetch'
import { customFetch } from './customFetch'
import { endpoints } from './endpoints'
import { getUserToken } from './utils'

export const getBilling = async (page = 1, perPage = 10, filters: Partial<BillingFilters> = {}) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponsePaginated<BillingResponse[]>>(
			`${endpoints.filterBilling}`,
			{
				method: 'POST',
				token,
				bodyReq: {
					startDate: filters.startDate,
					endDate: filters.endDate,
					providerId: filters.providerId,
					page,
					perPage,
					...(filters.isPaid ? { isPaid: true } : filters.isNotPaid ? { isPaid: false } : {})
				}
			}
		)

		if ('error' in response) throw response

		return response
	} catch (err) {
		throw err
	}
}

export const getInvoiceById = async (invoiceId: InvoiceDetail['id']) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<InvoiceDetail>>(
			`${endpoints.billing}/${invoiceId}`,
			{ token }
		)

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createInvoice = async (
	createInvoicePayload: CreateManualInvoicePayload | CreateWithReservationInvoicePayload
) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<BillingResponse>>(`${endpoints.billing}`, {
			method: 'POST',
			token,
			bodyReq: createInvoicePayload
		})

		if ('error' in response) throw response

		return response.data
	} catch (err) {
		throw err
	}
}

export const createInvoiceLine = async (createInvoicePayload: CreateInvoiceLinePayload) => {
	const token = getUserToken()

	try {
		const response = await customFetch<FetchResponse<void>>(`${endpoints.billingLine}`, {
			method: 'POST',
			token,
			bodyReq: createInvoicePayload
		})

		if ('error' in response) throw response

		return response
	} catch (err) {
		throw err
	}
}
