import './List.css'

interface Props {
	children: React.ReactNode
}

const List: React.FC<Props> = (props) => {
	const { children } = props

	return (
		<div className='list' onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	)
}

export default List
