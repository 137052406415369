import Loading from 'components/elements/Loading/Loading'
// import { CURRENCY_SYMBOL } from 'constants/common'
// import { BILLING_PDF_URL } from 'constants/routes'
// import { useFormik } from 'formik'
// import useBilling from 'hooks/useBilling'
import useProvider from 'hooks/useProvider'
// import useToast from 'hooks/useToast'
// import { useTransferDetails } from 'hooks/useTransferDetails'
import FormSectionLayout from 'layouts/FormLayout/FormSectionLayout/FormSectionLayout'
// import { CreateWithReservationInvoicePayload } from 'models/Billing'
import { TransferView } from 'models/Transfer'
import { useTranslation } from 'react-i18next'
// import { useNavigate } from 'react-router-dom'
// import { emptyString } from 'utils/common'
// import { dateToSQL } from 'utils/dateUtils'
// import ConceptCard from './ConceptCard'

interface Props {
	selectedTransfers: TransferView['id'][]
	providerId: number
}

const WithReservationsInvoiceModalStep3 = ({ selectedTransfers, providerId }: Props) => {
	const { t } = useTranslation()
	// const navigate = useNavigate()
	// const { transfers, loading } = useTransferDetails(selectedTransfers) //TODO: Fix this, do not use useTransferDetails with an array of ids
	const { provider, loading: loadingProvider } = useProvider(providerId)

	// const { createNewInvoice, creatNewInvoiceLine } = useBilling()
	// const { showBackendErrorToast } = useToast()

	// const baseCalc = (price: number, vat: number) => {
	// 	return price / (1 + vat / 100)
	// }

	// const totals = transfers.reduce(
	// 	(acc, transfer) => {
	// 		if (!transfer) return acc
	// 		const base = baseCalc(transfer.price, transfer.vat)
	// 		return {
	// 			base: acc.base + base,
	// 			tax: transfer.vat,
	// 			total: acc.total + transfer.price
	// 		}
	// 	},
	// 	{ base: 0, tax: 0, total: 0 }
	// )

	// const formik = useFormik<CreateWithReservationInvoicePayload>({
	// 	initialValues: {
	// 		numberFormat: emptyString,
	// 		date: dateToSQL(new Date()),
	// 		providerId
	// 	},
	// 		onSubmit: async (values) => {
	// 			try {
	// 				const concepts = transfers.map((transfer) => {
	// 					return {
	// 						concept: `${transfer!.originAddress} - ${transfer!.destinationAddress}`,
	// 						price: transfer!.price,
	// 						quantity: 1,
	// 						vat: transfer!.vat
	// 					}
	// 				})
	// 				const newInvoice = await createNewInvoice(values)
	// 				await creatNewInvoiceLine({
	// 					invoiceId: newInvoice.id,
	// 					lines: concepts
	// 				})
	// 				formik.resetForm()
	// 				navigate(BILLING_PDF_URL + `/${newInvoice.id}`)
	// 			} catch (error) {
	// 				showBackendErrorToast(error)
	// 			}
	// 		}
	// })

	// if (loading || loadingProvider) return <Loading fullscreen />
	if (loadingProvider) return <Loading fullscreen />
	return (
		<>
			<FormSectionLayout title={t('settings.billing.client_data')} noDivider>
				<div className='with-reservations-invoice-client-data'>
					<span>{provider?.name}</span>
					<span>{provider?.document}</span>
					<span>{provider?.address}</span>
					<span>
						{provider?.city}, {provider?.province}
					</span>
					<span>{provider?.postalCode}</span>
				</div>
			</FormSectionLayout>
			<FormSectionLayout title={t('settings.billing.concepts')} noDivider>
				{<div>Transfers seleccionados: {selectedTransfers}</div>} {/* //TODO: Delete this line */}
				{/* {transfers.map((transfer) => {
					if (!transfer) return null
					return (
						<ConceptCard
							key={transfer.id}
							concept={`${transfer.originAddress} - ${transfer.destinationAddress}`}
							base={baseCalc(transfer.price, transfer.vat).toFixed(2) + CURRENCY_SYMBOL}
							tax={`${transfer.vat}%`}
							total={transfer.price.toString() + CURRENCY_SYMBOL}
						/>
					)
				})} */}
			</FormSectionLayout>
			<FormSectionLayout title={t('settings.billing.total')}>
				<div className='with-reservations-invoice-totals'>
					<div className='with-reservations-invoice-totals-row'>
						<span>{t('settings.billing.base')}</span>
						<span>{t('settings.billing.tax')}</span>
						<span>{t('settings.billing.total_amount')}</span>
					</div>
					<div className='with-reservations-invoice-totals-row'>
						<span className='with-reservations-invoice-totals-value'>
							{/* {totals.base.toFixed(2) + CURRENCY_SYMBOL} */}
						</span>
						<span className='with-reservations-invoice-totals-value'>
							{/* {`${totals.tax}%`} */}
						</span>
						<span className='with-reservations-invoice-totals-value'>
							{/* {totals.total.toFixed(2) + CURRENCY_SYMBOL} */}
						</span>{' '}
					</div>
				</div>
			</FormSectionLayout>
		</>
	)
}

export default WithReservationsInvoiceModalStep3
