import ButtonBack from 'components/elements/ButtonBack/ButtonBack'
import Loading from 'components/elements/Loading/Loading'
import PdfViewer from 'components/elements/PdfViewer/PdfViewer'
import { ERROR_URL, RECEIPTS_URL } from 'constants/routes'
import useFetchPdf from 'hooks/useFetchPdf'
import FormLayout from 'layouts/FormLayout/FormLayout'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { endpoints } from 'services/endpoints'

interface Props {
	url?: string
	endpoint?: string
}

const MyReceiptPdf = ({ url = RECEIPTS_URL, endpoint = endpoints.ownInvoicePdf }: Props) => {
	const { invoiceId } = useParams()

	const { t } = useTranslation()
	const { pdfBase64, isLoading, error } = useFetchPdf(endpoint + '/' + invoiceId)
	const navigate = useNavigate()

	if (isLoading) return <Loading fullscreen />
	if (error)
		navigate(ERROR_URL, {
			state: { description: error, link: url }
		})
	if (!pdfBase64) return null

	return (
		<FormLayout
			title={t('settings.my_account.subscription.invoice')}
			leftButton={<ButtonBack url={url} />}>
			<PdfViewer pdf={pdfBase64} />
		</FormLayout>
	)
}

export default MyReceiptPdf
