import React from 'react'
import { useTranslation } from 'react-i18next'
import './FormInput.css'

interface Props {
	label: string
	htmlFor?: string
	children: React.ReactNode
	isLabelOptional?: boolean
}

const FormInput: React.FC<Props> = (props) => {
	const { label, htmlFor, children, isLabelOptional = false } = props
	const { t } = useTranslation()

	return (
		<div className='form-input'>
			<label htmlFor={htmlFor} className='form-input-label'>
				{label}
				{isLabelOptional && <span className='optional'> ({t('general.optional')})</span>}
			</label>
			{children}
		</div>
	)
}

export default FormInput
