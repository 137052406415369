export const BASE_URL = '/'
export const LOGIN_URL = '/login'
export const LOGOUT_URL = '/logout'
export const REGISTER_URL = '/register'
export const FORGOT_PASSWORD_URL = '/forgot-password'
export const CHANGE_PASSWORD_URL = '/change-password'
export const LEGAL_URL = '/legal'
export const TOUR_URL = '/tour'

export const ONBOARDING_URL = '/first-steps'
export const ONBOARDING_COMPANY_PROFILE_URL = '/first-steps/my-company-profile'
export const ONBOARDING_CREATE_FIRST_PROVIDER_URL = '/first-steps/create-first-provider'
export const ONBOARDING_CREATE_FIRST_DRIVER_URL = '/first-steps/create-first-driver'
export const ONBOARDING_CREATE_FIRST_VEHICLE_URL = '/first-steps/create-first-vehicle'
export const ONBOARDING_END_URL = '/first-steps/end'

export const HOME_URL = '/home'
export const TRACKING_URL = '/tracking'
export const PROFILE_URL = '/profile'
export const NEW_PROCESS_URL = '/new-process'
export const NEW_PROCESS_DATA_URL = 'data'
export const NEW_PROCESS_PARTICIPANTS_URL = 'participants'
export const NEW_PROCESS_OPTIONS_URL = 'options'
export const NEW_PROCESS_PREDEFINED_TEXTS_URL = 'texts'
export const NEW_PROCESS_SUMMARY_URL = 'summary'

export const SAMPLES_URL = '/samples'

export const ADMIN_CUSTOM_PAGE_URL = '/admin/custom-page'

export const DAILY_URL = '/daily'
export const TRANSFER_URL = '/transfer'
export const TRANSFER_PDF_URL = '/transfer/pdf'
export const TRANSFER_FOMENTO_URL = '/transfer/fomento'
export const TRANSFER_SHARE_URL = '/transfer/share'
export const TRANSFER_RECEIVE_URL = '/transfer/receive'
export const TRANSFER_FOMENTO_COMMUNICATION_PDF_URL = TRANSFER_FOMENTO_URL + '/communication'
export const TRANSFER_FOMENTO_CONFIRMATION_PDF_URL = TRANSFER_FOMENTO_URL + '/confirmation'
export const FORM_URL = '/form'
export const CREATE_URL = '/create'
export const CREATE_URL_NO_STEPS = '/create2'

export const HISTORICAL_URL = '/historical'

export const RESOURCES_URL = '/resources'

export const SETTINGS_URL = '/settings'
export const MY_ACCOUNT_URL = '/settings/my-account'
export const MY_COMPANY_URL = '/settings/my-account/company'
export const MY_COMPANY_EDIT_URL = '/settings/my-account/company-edit'
export const SUBSCRIPTION_URL = '/settings/my-account/subscription'
export const SUBSCRIPTION_PAYMENT_SUCCESS_URL = '/settings/my-account/subscription/success'
export const SUBSCRIPTIONS_URL = '/settings/my-account/subscriptions'
export const SUBSCRIPTION_TYPE_URL = '/settings/my-account/subscription-type'
export const RECEIPTS_URL = '/settings/my-account/receipts'
export const RECEIPT_PDF_URL = '/settings/my-account/receipts/pdf'
export const MY_PROFILE_URL = '/settings/my-account/profile'
export const MY_PROFILE_EDIT_URL = '/settings/my-account/profile-edit'
export const SETTINGS_LANGUAGE_URL = '/settings/language'
export const APPEARANCE_URL = '/settings/appearance'
export const DELETED_TRANSFERS_URL = '/settings/deleted-transfers'
export const PRECONFIG_TRANSFERS_URL = '/settings/preconfig-transfers'
export const USERS_URL = '/users'
export const USERS_NEW_URL = '/users/new'
export const USERS_EDIT_URL = '/users/edit'
export const BILLING_URL = '/billing'
export const BILLING_PDF_URL = '/billing/invoice'
export const CREATE_INVOICE_URL = '/create-invoice'

export const PROVIDERS_LIST_URL = '/resources/providers'
export const PROVIDERS_NEW_URL = '/resources/providers/new'
export const PROVIDERS_VIEW_URL = '/resources/providers/view'
export const PROVIDERS_EDIT_URL = '/resources/providers/edit'
export const USER_TYPE_PROVIDER_REGISTER_URL = '/resources/providers/user-type-provider-register'

export const DRIVERS_LIST_URL = '/resources/drivers'
export const DRIVERS_NEW_URL = '/resources/drivers/new'
export const DRIVERS_VIEW_URL = '/resources/drivers/view'
export const DRIVERS_EDIT_URL = '/resources/drivers/edit'
export const USER_TYPE_DRIVER_REGISTER_URL = '/resources/drivers/user-type-driver-register'

export const VEHICLES_LIST_URL = '/resources/vehicles'
export const VEHICLES_NEW_URL = '/resources/vehicles/new'
export const VEHICLES_NEW_TECHNICAL_DETAILS_URL = '/resources/vehicles/new/technical-details'
export const VEHICLES_VIEW_URL = '/resources/vehicles/view'
export const VEHICLES_EDIT_URL = '/resources/vehicles/edit'

export const FRIENDS_LIST_URL = '/resources/friends'
export const FRIENDS_SEARCH_URL = '/resources/friends/search'
export const FRIENDS_ASSOCIATION_URL = '/resources/friends/association'

export const SEARCH_URL = '/search'
export const NOTIFICATIONS_URL = '/notifications'
export const TRANSFER_REQUESTS_URL = '/transfer-requests'
export const DASHBOARD_URL = '/dashboard'

export const NOT_FOUND_URL = '/404'
export const NOT_ALLOWED_URL = '/403'
export const INACTIVE_COMPANY_URL = '/inactive-company'

export const ERROR_URL = '/error'

export const TRANSFER_STEPS = {
	ROUTE: 'ROUTE',
	DETAILS: 'DETAILS',
	ADDRESSES: 'ADDRESSES',
	FOMENTO: 'FOMENTO',
	PRICES: 'PRICES',
	CLIENT: 'CLIENT',
	EXTRAS: 'EXTRAS',
	OBSERVATIONS: 'OBSERVATIONS',
	END: 'END'
}

export const FLIGHT_RADAR = 'https://www.flightradar24.com/data/flights'
export const SEND_WHATSAPP = 'https://api.whatsapp.com/send?phone='
