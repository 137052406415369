import { useTranslation } from 'react-i18next'
import './Pagination.css'

interface Props {
	page: number
	totalPages: number
	onPageChange: (page: number) => void
}

const Pagination = ({ page, totalPages, onPageChange }: Props) => {
	const { t } = useTranslation()

	const handlePrevious = () => {
		if (page > 1) onPageChange(page - 1)
	}

	const handleNext = () => {
		if (page < totalPages) onPageChange(page + 1)
	}

	const getPageNumbers = () => {
		const range: (number | string)[] = []

		if (totalPages <= 3) {
			return Array.from({ length: totalPages }, (_, i) => i + 1)
		}
		range.push(1)

		if (page > 2) {
			range.push('...')
		}

		if (page !== 1 && page !== totalPages) {
			range.push(page)
		}

		if (page < totalPages - 1) {
			range.push('...')
		}
		range.push(totalPages)

		return range
	}

	return (
		<div className='pagination-container'>
			<button onClick={handlePrevious} disabled={page === 1} className='pagination-button'>
				{t('general.previous')}
			</button>

			{getPageNumbers().map((pageNumber, index) =>
				typeof pageNumber === 'string' ? (
					<span key={index} className='pagination-ellipsis'>
						{pageNumber}
					</span>
				) : (
					<button
						key={index}
						onClick={() => onPageChange(pageNumber)}
						className={`pagination-button ${page === pageNumber ? 'active' : ''}`}>
						{pageNumber}
					</button>
				)
			)}

			<button onClick={handleNext} disabled={page === totalPages} className='pagination-button'>
				{t('general.next')}
			</button>
		</div>
	)
}

export default Pagination
