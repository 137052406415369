import ButtonComponent from 'components/elements/Button/Button'
import DropdownSelector, {
	ItemDropdown
} from 'components/elements/DropdownSelector/DropdownSelector'
import Header from 'components/elements/Header/Header'
import { Modal } from 'components/elements/Modal/Modal'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './DropdownSelectorModal.css'

interface Props {
	visible: boolean
	toggleVisibility: () => void
	items: ItemDropdown[]
	title: string
	description?: string
	subtitle?: string
	isMultiple?: boolean
	handleClick: (ids: number[]) => void
	handleReset?: () => void
	selected?: number | number[] | null
}

const DropdownSelectorModal: FC<Props> = (props) => {
	const {
		visible,
		toggleVisibility,
		items,
		title,
		description,
		subtitle,
		isMultiple,
		handleClick,
		handleReset,
		selected
	} = props

	const orderBy = 'name' // change this to 'id' to order by id instead of name

	const { t } = useTranslation()

	const [selectedItems, setSelectedItems] = useState<number[]>([])

	const searchInputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (visible && searchInputRef.current) {
			searchInputRef.current.focus()
		}
	}, [visible])

	const onSaveMultiple = () => {
		handleClick(selectedItems)
		closeDropdownSelectorModal()
	}

	const closeDropdownSelectorModal = () => {
		toggleVisibility()
	}

	const orderItemsBy = (items: ItemDropdown[], by: keyof ItemDropdown) => {
		return items.sort((a, b) => {
			const aValue = a[by]?.toString() ?? ''
			const bValue = b[by]?.toString() ?? ''
			return aValue.localeCompare(bValue)
		})
	}

	useEffect(() => {
		if (visible) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = ''
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [visible])

	return (
		<Modal
			isVisible={visible}
			showCloseButton={false}
			toggleVisibility={toggleVisibility}
			isFull
			customClassNames='dropdown-selector-modal'>
			<Header
				leftButton={
					<ButtonComponent variant='only-text' onClick={closeDropdownSelectorModal}>
						{t('general.return')}
					</ButtonComponent>
				}
				title={title}
				rightButton={
					isMultiple ? (
						<ButtonComponent variant='only-text' onClick={onSaveMultiple}>
							{t('general.save')}
						</ButtonComponent>
					) : (
						handleReset && (
							<ButtonComponent variant='only-text' onClick={handleReset}>
								{t('general.reset')}
							</ButtonComponent>
						)
					)
				}
				isSticky
			/>
			<DropdownSelector
				items={orderItemsBy(items, orderBy)}
				description={description}
				subtitle={subtitle}
				isMultiple={isMultiple}
				handleClick={(ids) => {
					handleClick(ids)
					closeDropdownSelectorModal()
				}}
				selectedItems={selectedItems}
				setSelectedItems={setSelectedItems}
				searchInputRef={searchInputRef}
				selected={selected}
			/>
		</Modal>
	)
}

export default DropdownSelectorModal
