import { useQuery } from '@tanstack/react-query'
import {
	dailyTransfersQueryKey,
	fomentoQueryKey,
	sharedTransfersQueryKey,
	startEndTransfersQueryKey
} from 'constants/reactQueryKeys'
import { TransferView } from 'models/Transfer'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getFomentoByDateService } from 'services/fomento'
import { getSharedTransfersByDateService, getTransfersByDateService } from 'services/transfers'
import { getStartEndTransfersByDateService } from 'services/transfersStartEnd'
import { emptyString } from 'utils/common'
import { dateToSQL, dateToSpanishToTimestamp } from 'utils/dateUtils'

const useTransfers = (date: Date) => {
	const [transfersOrdered, setTransfersOrdered] = useState<TransferView[]>([])
	const [transfersExcluded, setTransfersExcluded] = useState<TransferView[]>([])
	const [allTransfers, setAllTransfers] = useState<TransferView[]>([])
	const dateString = dateToSQL(date)
	const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null)
	const { t } = useTranslation()

	// Fetch transfers by date
	const {
		data: transfers,
		isLoading: isLoadingTransfers,
		error: transfersError,
		isError: isErrorTransfers,
		refetch: refetchTransfers
	} = useQuery({
		queryKey: [dailyTransfersQueryKey, dateString],
		queryFn: () => getTransfersByDateService(dateString)
	})

	// Fetch fomento by date
	const {
		data: fomento,
		isLoading: isLoadingFomento,
		error: fomentoError,
		refetch: refetchFomento,
		isError: isErrorFomento
	} = useQuery({
		queryKey: [fomentoQueryKey, dateString],
		queryFn: () => getFomentoByDateService(dateString)
	})

	// Fetch start/end transfers by date
	const {
		data: startEndTransfers,
		isLoading: isLoadingStartEndTransfers,
		error: startEndTransfersError,
		isError: isErrorStartEndTransfers,
		refetch: refetchStartEndTransfers
	} = useQuery({
		queryKey: [startEndTransfersQueryKey, dateString],
		queryFn: () => getStartEndTransfersByDateService(dateString)
	})

	// getSharedTransfersByDateService
	const {
		data: sharedTransfers,
		isLoading: isLoadingSharedTransfers,
		error: sharedTransfersError,
		isError: isErrorSharedTransfers
	} = useQuery({
		queryKey: [sharedTransfersQueryKey, dateString],
		queryFn: () => getSharedTransfersByDateService(dateString)
	})

	useEffect(() => {
		if (isErrorTransfers && transfersError) {
			setErrorMessage(t('transfer.error_load_transfers'))
		}
		if (isErrorFomento && fomentoError) {
			setErrorMessage(t('transfer.error_load_fomento'))
		}
		if (isErrorStartEndTransfers && startEndTransfersError) {
			setErrorMessage(t('transfer.error_load_start_end_transfers'))
		}
		if (isErrorSharedTransfers && sharedTransfersError) {
			setErrorMessage(t('transfer.error_load_shared_transfers'))
		}
	}, [
		isErrorTransfers,
		transfersError,
		isErrorFomento,
		fomentoError,
		isErrorStartEndTransfers,
		startEndTransfersError,
		t
	])

	const fetchAll = () => {
		refetchTransfers()
		refetchFomento()
		// refetchSharedTransfers()
		refetchStartEndTransfers()
	}

	useEffect(() => {
		if (transfers) {
			setAllTransfers(transfers)
			filterTransfersByCurrentDateAndOrder(transfers)
		}
	}, [transfers])

	const filterTransfersByCurrentDateAndOrder = (transfers: TransferView[]) => {
		const orderedTransfers = transfers
			.filter((transfer) => transfer.date === dateString)
			.sort(
				(a, b) =>
					dateToSpanishToTimestamp(new Date(`${a.date} ${a.time}`)) -
					dateToSpanishToTimestamp(new Date(`${b.date} ${b.time}`))
			)
		setTransfersOrdered(orderedTransfers)
		setTransfersExcluded([])
	}

	const setAllTransfersTab = () => {
		if (transfers) {
			filterTransfersByCurrentDateAndOrder(transfers)
		}
	}

	const onSearch = useCallback(
		(searchTerm: string) => {
			if (searchTerm === emptyString) {
				setTransfersOrdered(allTransfers)
				return
			}
			const searchedTransfers = allTransfers.filter((transfer) => {
				return (
					transfer.driver?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					transfer.clientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					transfer.originAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					transfer.destinationAddress?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					transfer.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
					transfer.email?.toLowerCase().includes(searchTerm.toLowerCase())
				)
			})
			setTransfersOrdered(searchedTransfers)
		},
		[allTransfers]
	)

	const filterNextTransfers = () => {
		const now = new Date()
		const filteredTransfers = transfersOrdered.filter((transfer) => {
			const transferDate = new Date(`${transfer.date} ${transfer.time}`)
			return transferDate.getTime() >= now.getTime()
		})
		setTransfersOrdered(filteredTransfers)

		const excludedTransfers = transfersOrdered.filter((transfer) => {
			return !filteredTransfers.includes(transfer)
		})
		setTransfersExcluded(excludedTransfers)
	}

	return {
		transfers: transfersOrdered,
		transfersExcluded,
		allTransfers,
		onSearch,
		setAllTransfersTab,
		filterNextTransfers,
		loading:
			isLoadingTransfers ||
			isLoadingFomento ||
			isLoadingStartEndTransfers ||
			isLoadingSharedTransfers,
		error: errorMessage,
		onRefresh: fetchAll,
		fomento,
		startEndTransfers,
		sharedTransfers
	}
}

export default useTransfers
