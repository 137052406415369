import { MapPinIcon, MapPinLineIcon } from 'assets/svgs'
import Card from 'components/elements/Card/Card'
import HeaderTransferDetails from 'components/elements/HeaderTransferDetails/HeaderTransferDetails'
import { TRANSFER_URL } from 'constants/routes'
import useDriver from 'hooks/useDriver'
import useRouteColors from 'hooks/useRouteColors'
import { StatsLastTransfersResponse } from 'models/charts'
import { Transfer } from 'models/Transfer'
import { useNavigate } from 'react-router-dom'
import { usePreviousRouteStore } from 'stores/usePreviousRouteStore'
import { dateToSpanish, timeToSpanish } from 'utils/dateUtils'
import './SmallTransferCard.css'

interface Props {
	transfer: StatsLastTransfersResponse | Transfer
}

const SmallTransferCard = (props: Props) => {
	const { transfer } = props
	const navigate = useNavigate()
	const { setPreviousRoute } = usePreviousRouteStore()
	const { driver } = useDriver(transfer.driverId)

	const { getRouteColor } = useRouteColors()

	if (!transfer) return null

	const redirectToTransferDetails = () => {
		navigate(`${TRANSFER_URL}/${transfer.id}`)
	}

	const isStatsLastTransfersResponse = (
		transfer: StatsLastTransfersResponse | Transfer
	): transfer is StatsLastTransfersResponse => {
		return (transfer as StatsLastTransfersResponse).driver !== undefined
	}

	const driverName: StatsLastTransfersResponse['driver'] | undefined = isStatsLastTransfersResponse(
		transfer
	)
		? transfer.driver
		: driver?.name

	return (
		<Card
			role='button'
			className='small-transfer-card'
			onClick={() => {
				setPreviousRoute(window.location.pathname)
				redirectToTransferDetails()
			}}
			style={{
				borderLeftColor: getRouteColor(transfer.route)
			}}>
			<HeaderTransferDetails
				dailyTransferId={transfer.id}
				driverId={transfer.driverId}
				driver={driverName}
				date={dateToSpanish(new Date(transfer.date))}
				time={timeToSpanish(transfer.time)}
				backgroundColor={'transparent'}
				showMenuDots
			/>

			<main className='small-transfer-card-main'>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinIcon />
						<span className='small-transfer-card-main-item-value'>{transfer.originAddress}</span>
					</div>
				</div>
				<div className='small-transfer-card-main-item'>
					<div className='small-transfer-card-main-item-wrapper'>
						<MapPinLineIcon />
						<span className='small-transfer-card-main-item-value'>
							{transfer.destinationAddress}
						</span>
					</div>
				</div>
			</main>
		</Card>
	)
}

export default SmallTransferCard
