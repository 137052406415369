import { Clipboard } from '@capacitor/clipboard'
import PaperPlaneTiltIcon from 'assets/svgs/PaperPlaneTiltIcon'
import { CREATE_URL_NO_STEPS, DAILY_URL, TRANSFER_URL } from 'constants/routes'
import { Transfer } from 'models/Transfer'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { sendReminderDriverService } from 'services/transfers'
import useToast from './useToast'
import { useTransferDetails } from './useTransferDetails'

interface Props {
	transferId: Transfer['id']
	onClose: () => void | Dispatch<SetStateAction<boolean>>
}

const useDrawerMenu = ({ transferId, onClose }: Props) => {
	const { transfer, deleteTransfer, copyTransferToClipboard } = useTransferDetails(transferId)
	const navigate = useNavigate()
	const { showSuccessToast, showBackendErrorToast } = useToast()
	const [loading, setLoading] = useState(false)
	const { t } = useTranslation()

	const copyToClipboard = async () => {
		try {
			const result = await copyTransferToClipboard(transferId)
			const formattedResult = result.replace(/<br>/g, '\n').trim()

			await Clipboard.write({
				string: formattedResult
			})

			showSuccessToast({
				title: t('transfer.details.drawer_menu.copy_to_clipboard_success_title'),
				description: t('transfer.details.drawer_menu.copy_to_clipboard_success')
			})
			onClose()
		} catch (error) {
			console.error('Error copying to clipboard:', error)
			showBackendErrorToast(error)
		}
	}

	const navigateToDuplicateTransfer = () => {
		navigate(`${TRANSFER_URL}${CREATE_URL_NO_STEPS}/${transfer!.route}/${transferId}`, {
			state: { isDuplicate: true }
		})
	}

	const sendToDriver = async () => {
		try {
			setLoading(true)
			await sendReminderDriverService(transferId)
			showSuccessToast({
				type: 'info',
				icon: <PaperPlaneTiltIcon />,
				title: t('transfer.details.drawer_menu.send_to_driver_success_title'),
				description: t('transfer.details.drawer_menu.send_to_driver_success_description')
			})
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			onClose()
		}
	}

	const deleteTransferById = async () => {
		try {
			setLoading(true)
			await deleteTransfer(transferId)
			showSuccessToast({ description: t('transfer.details.drawer_menu.delete_transfer_success') })
		} catch (error) {
			showBackendErrorToast(error)
		} finally {
			setLoading(false)
			navigate(DAILY_URL)
		}
	}

	return {
		copyToClipboard,
		navigateToDuplicateTransfer,
		sendToDriver,
		deleteTransferById,
		loading
	}
}

export default useDrawerMenu
